import { useNavigate, useParams } from 'react-router-dom'
import { Button, Flex, Input, Spinner, Text } from '../../../../Components'
import { saveUser, useUser } from './Functions'

const defaultStyle = { width: '31%', marginRight: '1%', marginLeft: '1%', marginBottom: 15 }
const fields = {
  username: { label: 'Username' },
  name: { label: 'Nome' },
  surname: { label: 'Cognome' },
  password: { label: 'Password' },
  role: {
    label: 'Ruolo',
    select: true,
    optionsList: [
      { label: 'Operatore H24', value: 'operatoreH24' },
      { label: 'Operatore Servizi', value: 'operatoreServizi' },
      { label: 'Amministratore', value: 'admin' },
      { label: 'Gestore Piattaforma H24', value: 'gestoreH24' },
      { label: 'Gestore Operatori', value: 'gestoreOperatori' }
    ]
  },
  blackUsername: { label: 'Black Username' },
  operatore: { label: 'Operatore' },
  eniUsername: { label: 'Eni Username' },
  gattinoniUsername: { label: 'Gattinoni Username' },
  bcdUsername: { label: 'Bcd Username' },
  fieschiTravelUsername: { label: 'Fieschi Travel Username' },
  robinturProprietarioUsername: { label: 'Robintur Proprietario Username' },
  robinturOnyxUsername: { label: 'Robintur Onyx Username' },
  blueteamEdokUsername: { label: 'Blueteam Edok Username' },
  blueteamSiapUsername: { label: 'Blueteam Siap Username' }
}

export function UserEditor () {
  const { userId } = useParams()
  const navigate = useNavigate()
  const [user, setUser, isSuccess] = useUser(userId)
  const { id = '' } = user || {}

  const save = async () => {
    const res = await saveUser(user)
    res?.id && navigate('/admin/users/list')
    return res
  }

  if (!isSuccess || !user) return <Spinner />

  const isNew = id === 'new'

  const elaborateInput = () => {
    return Object.keys(fields).map((key) => {
      const { select, optionsList, label } = fields[key] || {}
      if (select) {
        return (
          <Input
            id={key} dropdown placeholder="Ruolo dell'utente"
            options={optionsList}
            value={user[key]}
            key={key}
            onChange={(data) => setUser(key, data[key])}
            style={{ ...defaultStyle }}
          />
        )
      }
      return <Input key={key} value={user[key]} onChange={({ [key]: value }) => setUser(key, value)} label={label} id={key} style={{ ...defaultStyle }} />
    })
  }
  return (
    <Flex fw>
      <Text value={`${isNew ? 'Crea' : 'Modifica'} Utente`} title bold size={30} />
      <Flex fw row wrap style={{ marginTop: 20 }}>
        {elaborateInput()}
        <Flex fw row style={{ marginTop: 20 }}>
          <Button label='Salva' icon='fa-duotone fa-floppy-disk' style={{ marginRight: 10 }} onClick={save} />
          <Button label='Torna indietro' icon='fa-duotone fa-arrow-left' onClick={() => navigate('/admin/users/list')} />
        </Flex>
      </Flex>
    </Flex>
  )
}
