import { useNavigate, useParams } from 'react-router-dom'
import { saveAgency, useAgency } from './Functions'
import { Button, Flex, Input, Spinner, Text } from '../../../../Components'

const defaultStyle = { width: '31%', marginRight: '1%', marginLeft: '1%', marginBottom: 15 }
const fields = {
  codClienteNerone: { label: 'Codice Cliente Nerone' },
  agencyName: { label: 'Nome' },
  sabreOfficeId: { label: 'Sabre Office ID' },
  galileoOfficeId: { label: 'Galileo Office ID' },
  amadeusOfficeId: { label: 'Amadeus Office ID' }
}

export function AgencyEditor () {
  const { agencyId } = useParams()
  const navigate = useNavigate()
  const [agency, setAgency, isSuccess] = useAgency(agencyId)
  const { id = '' } = agency || {}

  const save = async () => {
    const res = await saveAgency(agency)
    res?.id && navigate('/admin/agency/list')
    return res
  }

  if (!isSuccess || !agency) return <Spinner />

  const isNew = id === 'new'

  return (
    <Flex fw>
      <Text value={`${isNew ? 'Crea' : 'Modifica'} Agenzia`} title bold size={30} />
      <Flex fw row wrap style={{ marginTop: 20 }}>
        {Object.keys(fields).map((key) =>
          <Input key={key} value={agency[key]} onChange={({ [key]: value }) => setAgency(key, value)} label={fields[key]?.label} id={key} style={{ ...defaultStyle }} />
        )}
        <Flex fw row style={{ marginTop: 20 }}>
          <Button label='Salva' icon='fa-duotone fa-floppy-disk' style={{ marginRight: 10 }} onClick={save} />
          <Button label='Torna indietro' icon='fa-duotone fa-arrow-left' onClick={() => navigate('/admin/agency/list')} />
        </Flex>
      </Flex>
    </Flex>
  )
}
