import React, { useRef, useState } from 'react'
import { checkAgainForDuplicates, readAgencies, uploadAgencies } from './Functions'
import { Flex, Text } from '../../../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ImportTutorial, TextEditor } from './Components'
import { Button } from 'primereact/button'

export const AgencyImport = () => {
  const refReadAgencies = useRef(null)
  const [importedAgencies, setImportedAgencies] = useState([])
  const [tempAgencies, setTempAgencies] = useState([])
  const [thereAreDuplicated, setThereAreDuplicated] = useState(false)
  const [notImportedRecord, setNotImportedRecord] = useState(0)
  const [importedRecord, setImportedRecord] = useState(0)
  const [tabTitle, setTabTitle] = useState('Agenzie da importare')
  const [error, setError] = useState(false)

  const tableLables = { codClienteNerone: 'Codice Cliente Nerone', agencyName: 'Nome Agenzia' }

  /**
   * Importa e gestisce il risultato dell'analisi dei file delle agenzie.
   *
   * @async
   * @param {Event} e - L'evento che innesca l'importazione del file.
   * @returns {Promise<void>} Un oggetto promise che si risolve quando il processo di importazione è completato.
   *                          Non ritorna dati.
   * @description Questa funzione asincrona tenta di leggere e importare i file delle agenzie utilizzando `readAgencies`.
   *              In caso di fallimento, viene mostrato un messaggio di errore all'utente.
   *              Se durante la lettura vengono rilevati errori quali duplicati o campi mancanti,
   *              vengono visualizzati avvisi di errore e le informazioni pertinenti ai record problematici vengono gestite.
   *              Se non ci sono errori, i record sono correttamente importati e l'utente ne viene informato
   *              con un messaggio di successo. Gestisce anche l'aggiornamento dello stato dell'applicazione in base
   *              al risultato dell'importazione.
   */
  const importAgencies = async (e) => {
    const res = await readAgencies(e)
    if (!res) return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore durante l\'importazione delle agenzie' })
    if (res.hasErrors) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Sono stati trovati duplicati e/o campi vuoti' })
      setTabTitle('Agenzie duplicate e/o con campi vuoti')
      setError(true)
      setTempAgencies(res.data)
      setImportedRecord(res.data.length)
      setNotImportedRecord(res.errors.length)
      setImportedAgencies(res.errors)
    } else {
      setTabTitle('Agenzie importate')
      setError(false)
      window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Trovate ' + res.data.length + ' agenzie' })
      setImportedRecord(res.data.length)
      setImportedAgencies(res.data)
    }
    setThereAreDuplicated(res.hasErrors)
  }

  /**
   * Gestisce il completamento della modifica di una riga, aggiornando l'array di agenzie importate.
   *
   * Questa funzione viene invocata al termine della modifica di un elemento all'interno di una tabella.
   * Prende i dati modificati (`newData`) e l'indice della riga modificata (`index`), poi aggiorna
   * l'elenco delle agenzie importate con i nuovi dati.
   *
   * @param {Object} params - L'oggetto che contiene i dati della nuova riga e l'indice della riga modificata.
   * @param {Object} params.newData - L'oggetto contenente i dati aggiornati della riga.
   * @param {number} params.index - L'indice della riga all'interno dell'array delle agenzie importate da aggiornare.
   */
  const onRowEditComplete = ({ newData, index }) => {
    const _products = [...importedAgencies]
    _products[index] = newData
    setImportedAgencies(_products)
  }

  /**
   * Conferma l'importazione delle agenzie e gestisce il risultato dell'operazione di caricamento.
   *
   * @async
   * @returns {Promise<void>} Un oggetto promise che si risolve quando il processo di conferma è completato.
   *                          Non ritorna dati.
   * @description Questa funzione asincrona esegue il caricamento delle agenzie importate tramite `uploadAgencies`.
   *              Se non ci sono incongruenze, viene impostato lo stato di errore a `false` e
   *              mostrato un messaggio di successo indicando il completamento dell'importazione.
   *              Vengono anche aggiornati i contatori dei record importati e non importati,
   *              e svuotate le liste temporanee degli importi.
   *              In caso di incongruenze nei dati caricati, viene impostato lo stato di errore a `true`
   *              e viene effettuato l'aggiornamento dello stato dell'applicazione per riflettere i dettagli
   *              dei record non correttamente importati, compreso l'aggiornamento del titolo della scheda.
   */
  const confirmAgencies = async () => {
    const result = await uploadAgencies(importedAgencies)
    if (!result.inconsistencies.length) {
      setError(false)
      window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Importazione completata' })
      const successImport = result.importedRecord
      setImportedRecord(successImport)
      setNotImportedRecord(0)
      setImportedAgencies([])
      setTempAgencies([])
    } else {
      setError(true)
      const inconsistencies = result.inconsistencies
      const successImport = result.importedRecord
      setImportedRecord(successImport)
      setNotImportedRecord(inconsistencies.length)
      setImportedAgencies(inconsistencies)
      setTabTitle('Agenzie non importate')
    }
  }

  /**
   * Salva le modifiche all'elenco delle agenzie combinando le liste di agenzie importate e temporanee.
   *
   * @returns {void} Non ritorna alcun valore.
   * @description Questa funzione esegue il salvataggio della configurazione attuale delle agenzie.
   *              Inizia combinando le liste di agenzie importate e quelle temporanee.
   *              Successivamente, verifica la presenza di duplicati e/o campi vuoti.
   *              Se vengono rilevati errori, viene visualizzato un messaggio di errore, impostato lo stato errore a `true`,
   *              e aggiornati i contatori dei record importati e non importati con le rispettive lunghezze dei dati validi e degli errori.
   *              Se non ci sono errori, viene impostato il titolo della scheda per indicare
   *              che le agenzie sono state importate, si pulisce lo stato di errore,
   *              e si aggiornano gli stati dell'applicazione con le nuove liste di agenzie e contatori.
   */
  const saveMod = () => {
    const listAgencies = importedAgencies.concat(tempAgencies)
    const checkForDup = checkAgainForDuplicates(listAgencies)
    if (checkForDup.hasErrors) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Sono stati duplicati e/o campi vuoti' })
      setError(true)
      setTempAgencies(checkForDup.data)
      setImportedAgencies(checkForDup.errors)
      setImportedRecord(checkForDup.data.length)
      setNotImportedRecord(checkForDup.errors.length)
      return
    }
    setTabTitle('Agenzie importate')
    setError(false)
    setImportedAgencies(listAgencies)
    setImportedRecord(listAgencies.length)
    setNotImportedRecord(0)
    setThereAreDuplicated(false)
  }

  /**
   * Rimuove un'agenzia specifica dall'elenco delle agenzie importate.
   *
   * @param {Object} rowData - I dati dell'agenzia da rimuovere, contenenti almeno `codClienteNerone` e `agencyName`.
   * @returns {void} Non ritorna alcun valore.
   * @description Questa funzione elimina un'agenzia dall'elenco di quelle importate in base ai campi `codClienteNerone` e `agencyName`.
   *              Dopo aver trovato e rimosso l'agenzia corrispondente, aggiorna lo stato delle agenzie importate
   *              e decrementa il contatore delle agenzie non importate.
   */
  const removeAgency = (rowData) => {
    const { codClienteNerone = '', agencyName = '' } = rowData || {}
    const newImportedAgencies = importedAgencies.filter((agency) =>
      !(agency.codClienteNerone === codClienteNerone && agency.agencyName === agencyName))
    setImportedAgencies(newImportedAgencies)
    setNotImportedRecord(notImportedRecord - 1)
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <Flex>
        <Button icon='fa-duotone fa-trash' rounded text onClick={() => removeAgency(rowData)} />
      </Flex>
    )
  }

  const Resume = () => (
    <Flex fw>
      <Text value={tabTitle} size={25} bold style={{ marginBottom: 5 }} />
      <Text value={`Agenzie pronte per essere importate: ${importedRecord}`} size={17} bold style={{ marginBottom: 5 }} />
      <Text value={`Agenzie duplicate e/o con campi vuoti: ${notImportedRecord}`} size={17} bold style={{ marginBottom: 20 }} />
    </Flex>
  )

  return (
    <Flex fw>
      <input type='file' accept='.csv' ref={refReadAgencies} style={{ display: 'none' }} onChange={(e) => importAgencies(e)} />
      {importedAgencies?.length
        ? (
          <Flex fw as>
            <Resume />
            <DataTable
              style={{ width: '100%', boxShadow: error ? '0 0 10px red' : '0 0 10px rgb(80, 80, 80)', borderRadius: 20, padding: 10 }} sortField='createdAt' sortOrder={-1}
              scrollHeight='90vh' value={importedAgencies} emptyMessage='Niente da visualizzare' responsiveLayout='scroll' paginator rows={10}
              className='TableSignatures' filterDisplay='row' onRowEditComplete={onRowEditComplete} editMode='row'
            >
              {Object.keys(importedAgencies[0]).map((key, ind) => (
                <Column
                  key={ind} header={tableLables[key]} alignHeader='center' showClearButton={false} filter
                  filterMatchMode='contains' style={{ textAlign: 'center' }} sortable
                  filterPlaceholder={'🔍   ' + tableLables[key]} showFilterMenu={false} field={key}
                  body={({ [key]: value }) => <Text center value={value} />}
                  editor={(options) => TextEditor(options)}
                />))}
              <Column rowEditor headerStyle={{ width: '10%', minWidth: '1rem', maxWidth: '4rem' }} bodyStyle={{ textAlign: 'center' }} />
              <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '1rem', maxWidth: '4rem' }} />
            </DataTable>
          </Flex>
          )
        : tempAgencies.length ? <Resume /> : <ImportTutorial />}
      <Flex row>
        <Flex>
          <Button icon='fa-duotone fa-cloud-arrow-up' label='Carica CSV' style={{ marginTop: 10 }} onClick={() => refReadAgencies.current.click()} />
        </Flex>
        {thereAreDuplicated &&
          (!!importedAgencies?.length || !!tempAgencies.length) &&
            <Button label='Salva Modifiche' icon='fa-duotone fa-check' style={{ marginTop: 10, marginLeft: 10 }} onClick={saveMod} />}
        {(!!importedAgencies?.length || !!tempAgencies.length) &&
          !thereAreDuplicated &&
            <Button label='Carica su DB' icon='fa-duotone fa-check' style={{ marginTop: 10, marginLeft: 10 }} onClick={confirmAgencies} />}
      </Flex>
    </Flex>
  )
}
