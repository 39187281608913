import React, { useEffect, useState } from 'react'
import { Card, Flex, Text } from '../../../Components'
import { useCQuery } from '../../../Services'
import { ScatterChartComponent } from '../../../Components/Charts/ScatterChart'
import { useNavigate } from 'react-router-dom'
import { formatTime, getDate } from './Functions'

const timeDataKeys = [{ dataKey: 'executionTime', color: 'red' }]

export const ProcessTimeLine = ({ dates }) => {
  const { data: { processes = [] } = {}, isSuccess } = useCQuery(['getStats', ...dates])
  const [executionTimes, setExecutionTimes] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if (isSuccess) {
      setExecutionTimes([
        { createdAt: getDate(dates[0]).getTime() },
        ...processes
          .filter(({ executionTime }) => executionTime)
          .map((process) => ({
            createdAt: new Date(process.createdAt).getTime(),
            executionTime: (process.executionTime / 1000),
            processId: process.id
          })),
        { createdAt: getDate(dates[1]).getTime() + 86400000 }
      ])
    }
  }, [processes])

  if (!isSuccess) return null
  return (
    <Card style={{ width: '100%' }}>
      <Text title bold value='TEMPI PROCESSI' style={{ marginRight: 20, marginBottom: 10, width: '100%' }} size={20} center />
      <Flex fw style={{ height: '50vh', marginTop: 15 }}>
        <ScatterChartComponent
          data={executionTimes} dataKeys={timeDataKeys} xaxis='createdAt' yaxis cartesian
          xAxisProps={{
            dataKey: 'createdAt',
            type: 'number',
            tickCount: 3,
            domain: ['dataMin', 'dataMax'],
            tickFormatter: (unixTime) => new Date(unixTime).toLocaleString('it-IT'),
            interval: 'preserveStartEnd'
          }}
          yAxisProps={{
            type: 'number',
            dataKey: 'executionTime',
            domain: [0, 'dataMax + 20'],
            tickFormatter: (value) => formatTime(value, true)
          }}
          tooltipProps={{
            content: ({ payload: [{ payload: { processId = '', executionTime } = {} } = {}] = [] }) => (
              <div>
                <Text value={`Id Processo: ${processId}`} />
                <Text value={`Tempo di esecuzione: ${formatTime(executionTime)}`} />
              </div>)
          }}
          scatterProps={{
            onClick: ({ payload: { processId = '' } = {} }) => navigate(`/process/${processId}`)
          }}
        />
      </Flex>
    </Card>
  )
}
