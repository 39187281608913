import React, { useState, useEffect } from 'react'
import { Flex } from '../../Components/Common/Flex'
import { DataTable } from 'primereact/datatable'
import { useCQuery } from '../../Services/QueryCache'
import { columnProps } from '../../Services/Functions'
import { Column } from 'primereact/column'
import { Calendar } from 'primereact/calendar'
import { FloatInput } from '../Gigi/GigiForm/TransferField/InputForm'
import { formatMilliseconds } from '../StatisticheOperatori/Functions'
import { Button } from '../../Components/Common/Button'
import { Tooltip } from 'primereact/tooltip'
import { Text } from '../../Components/Common/Text'
import { nanoid } from 'nanoid'

const RandomColors = ['#23d8dd', '#42f2c4', '#3df783', '#4e77d7', '#bfb782', '#e81537', '#c9e965', '#775247', '#009080', '#ceada2', '#89b835', '#8ae8ea', '#6758d6', '#839ea3', '#394726', '#fb507b', '#5d226d', '#db3afa', '#a1a8e2', '#de557f', '#b38af8', '#d290eb', '#c9e744', '#1159f9', '#f40273', '#a19ef7', '#fad99b', '#cdd99d', '#ccf80a', '#1d675a', '#ef8ff1', '#78562e', '#451800', '#918de4', '#1108bc', '#c931e8', '#4c7d14', '#c2525c', '#1a066d', '#9ad4ac', '#44be24', '#83c042', '#574e37', '#c29545', '#7c09d6', '#aa2faf', '#3ef048', '#0edbf6', '#115f3e', '#1f8dd5', '#2acabd', '#4bf1dd', '#e7d6ce', '#bdac4f', '#fd9e00', '#d5ec6a', '#9820e4', '#2f8a3b', '#52d642', '#3debc5'].sort(() => Math.random() - Math.random())

export const TimeSearch = () => {
  const [range, setRange] = useState([new Date(new Date().setDate(new Date().getDate() - 2)), new Date()])
  const [datesSearch, setDatesSearch] = useState(range)
  const [selectedUser, setSelectedUser] = useState(false)
  const { data: time = [] } = useCQuery(['timeSearch', selectedUser, datesSearch])
  const { data: users = [] } = useCQuery(['users'])
  const [timeList, setTimeList] = useState([])
  const [activitiesGroupedByConversation, setActivitiesGroupedByConversation] = useState([])
  const [usersIds, setUsersIds] = useState([])
  const [maxTime, setMaxTime] = useState(0)

  useEffect(() => {
    setTimeList(time?.map((time) => ({
      ...time,
      user: users.find(({ id }) => id === time.userId)?.username,
      time: formatMilliseconds(time?.time),
      createdAt: new Date(time.createdAt).toLocaleString('it-IT'),
      endAt: new Date(time.endAt).toLocaleString('it-IT')
    })))
  }, [time?.length])

  useEffect(() => {
    const difference = range[1].getTime() - range[0].getTime()
    setMaxTime(difference)
    if ((difference / 1000 / 60 / 60 / 24) > 7 || !time?.length) {
      setActivitiesGroupedByConversation([])
    } else {
      const groupByConversation = time?.reduce((acc, { conversationId, ...rest }) => {
        if (!acc?.find(({ conversationId: c }) => c === conversationId)) acc.push({ conversationId, activities: [] })
        const index = acc.findIndex(({ conversationId: c }) => c === conversationId)
        acc[index].activities.push(rest)
        return acc
      }, [])

      setUsersIds([...new Set(groupByConversation?.map(({ activities }) => activities?.map(act => act.userId))?.flat())])

      // create fake activities to fill the gaps beetwen the activities and the range
      const activitiesGroupedByConversation = {}
      groupByConversation.forEach(({ conversationId, activities }) => {
        activitiesGroupedByConversation[conversationId] = []
        activities?.forEach((activity, ind) => {
          if (ind === 0) {
            activitiesGroupedByConversation[conversationId]?.push({ fake: true, time: new Date(activity.createdAt).getTime() - range[0].getTime() })
          }
          activitiesGroupedByConversation[conversationId]?.push(activity)
          if (activities[ind + 1]) {
            activitiesGroupedByConversation[conversationId]?.push({ fake: true, time: new Date(activities[ind + 1]?.createdAt).getTime() - new Date(activity.endAt).getTime() })
          }
          if (ind === activities.length - 1) {
            activitiesGroupedByConversation[conversationId]?.push({ fake: true, time: range[1].getTime() - new Date(activity.endAt).getTime() })
          }
        })
      })
      setActivitiesGroupedByConversation(activitiesGroupedByConversation)
    }
  }, [range[0], range[1], time?.length])

  return (
    <Flex fw>
      <Flex fw row style={{ marginBottom: 20 }}>
        <Flex style={{ width: 350, marginRight: 20 }}>
          <FloatInput
            id='user' value={[selectedUser, setSelectedUser]}
            type='dropdown'
            options={[{ label: 'Tutti', value: false }, ...users.map(({ id, username }) => ({ label: username, value: id }))]}
            label='Seleziona utente'
            noMarginTop
          />
        </Flex>
        <Calendar
          showTime
          value={datesSearch} selectionMode='range' onChange={(e) => {
            setDatesSearch(e.value)
            e.value?.filter(Boolean).length === 2 && setRange(e.value)
          }}
          locale='it' dateFormat='dd/mm/yy' style={{ width: 350 }}
        />
      </Flex>
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' className='TableSignatures'
        value={timeList} emptyMessage='Nessuna attività presente' responsiveLayout='scroll' paginator rows={10}
      >
        <Column field='user' {...columnProps('Utente')} />
        <Column field='createdAt' {...columnProps('Inizio')} />
        <Column field='endAt' {...columnProps('Fine')} />
        <Column field='time' {...columnProps('Tempo')} />
        <Column
          style={{ width: 150 }}
          field='conversationId' body={(rowData) =>
            <Button
              icon='fa-duotone fa-external-link' label={'Id: ' + rowData.conversationId} style={{ width: 150 }}
              onClick={() => window.open(window.location.origin + '/operatore/' + rowData.conversationId, '_blank')}
            />}
        />
      </DataTable>
      {!!Object.keys(activitiesGroupedByConversation)?.length &&
        <>
          <Flex as fw js row style={{ marginTop: 20, marginBottom: 10 }}>
            <Text value='Legenda Operatori' bold style={{ width: 220 }} />
            <Flex wrap js row style={{ marginLeft: 20, gap: '0px 10px' }}>
              {usersIds?.map(userId => (
                <Flex key={userId} row>
                  <Text value={users?.find(user => user.id === userId)?.username} />
                  <div style={{ backgroundColor: RandomColors[userId % 50], height: 15, width: 15, marginLeft: 5 }} />
                </Flex>
              ))}
            </Flex>
          </Flex>
          <div style={{ maxHeight: '80vh', overflow: 'scroll', width: '100%', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
            {Object.keys(activitiesGroupedByConversation).map((conversationId) => {
              const activities = activitiesGroupedByConversation[conversationId]
              return (
                <Flex fw row key={conversationId} js style={{ height: 26 }}>
                  <Button
                    icon='fa-duotone fa-external-link' label={'Id: ' + conversationId} style={{ width: 150, height: 25 }}
                    onClick={() => window.open(window.location.origin + '/operatore/' + conversationId, '_blank')}
                  />
                  <Flex fw row style={{ borderTop: '1px solid black' }} as js>
                    {activities.map(({ id, userId, time, createdAt, endAt, fake }, ind) => {
                      const targetId = nanoid()
                      return (
                        <React.Fragment key={id}>
                          <a
                            style={{
                              backgroundColor: fake ? 'transparent' : RandomColors[userId % 50],
                              width: time / maxTime * 100 + '%',
                              height: 25
                            }}
                            {...!fake
                              ? {
                                  className: 'custom-target-icon' + targetId,
                                  'data-pr-tooltip': users?.find(user => user.id === userId)?.username + ' - ' + new Date(createdAt)?.toLocaleString() + ' - ' + new Date(endAt)?.toLocaleString(),
                                  'data-pr-position': 'right',
                                  'data-pr-at': 'right-180 top-5',
                                  'data-pr-my': 'left center-2'
                                }
                              : {}}
                          />
                          <Tooltip target={'.custom-target-icon' + targetId} />
                        </React.Fragment>
                      )
                    })}
                  </Flex>
                </Flex>
              )
            })}
          </div>
          <Flex fw jb row style={{ paddingLeft: 150 }}>
            <Text value={range[0]?.toLocaleString()} />
            <Text value={range[1]?.toLocaleString()} />
          </Flex>
        </>}
    </Flex>
  )
}
