import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { invalidateQuery, prefetchQuery, useCInfiniteQuery, useCQuery } from '../../../Services/QueryCache'
import { useAuth } from '../../../Provider/AuthProvider'
import { Button, Flex, Spinner, Text, Modal } from '../../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { archiveConversations, conversationsStatus, formatDateHour, deleteConversation } from './Functions'
import { columnProps } from '../../../Services/Functions'
import { Input } from '../../../Components/Common/Input'
import { useCapsule } from '../../../State/Functions'
import { cap } from '../../../State'
import { StartConversaton } from '../StartConversation'
import { Calendar } from 'primereact/calendar'
import { queryClient } from '../../../App'
import { ConfirmModal } from '../GigiForm/Components'
import { getNestedValue } from '../../LineaOccupata/List'
import { TimeView } from '../../StatisticheOperatori/TimeView'
import { Icon } from '../../../Components/Common/Icon'

const getStatusFilterOptions = (role) => [
  { label: 'Da leggere', value: 'unread' },
  { label: 'In corso', value: 'open' },
  { label: 'Completate cliente', value: 'customerCompleted', color: '#64cef5' },
  { label: 'Concluse', value: 'ended', color: '#f19f16' },
  ...(role === 'admin' || role === 'gestoreH24') ? [{ label: 'Archiviate', value: 'archived' }] : [],
  { label: 'Tutte le conversazioni', value: 'all' },
  { label: 'Conversazioni in errore', value: 'error' }
]

const statusAssignedOptions = [
  { label: 'Tutti', value: 'all' },
  { label: 'Assegnate a me', value: 'user' },
  { label: 'Non assegnate', value: 'none' }
]

const statusHidden = [
  { label: 'Tutti', value: { $in: [true, false] } },
  { label: 'Conversazioni nascoste', value: true },
  { label: 'Conversazioni visibili', value: false }
]

export const gattinoniId = 32

export function GigiList ({ allowCreate, allowSendMessage, onlyGattinoni, noGattinoni, noWhatsapp, customAssignedFilter }) {
  const hasMessages = !!(noGattinoni || onlyGattinoni)
  const firstRender = useRef(true)
  const [range, setRange] = useCapsule(cap.range)
  const [datesSearch, setDatesSearch] = useState(range)
  const [tableState, setTableState] = useCapsule(cap.datatableState)
  const [assignedFilter, setAssignedFilter] = useCapsule(cap.assignedFilter)
  const [selectedFilter, setSelectedFilter] = useCapsule(cap.selectedFilter)
  const [customAssignedFilterValue, setCustomAssignedFilterValue] = useCapsule(cap.customAssignedFilter)
  const [showHidden, setShowHidden] = useState({ $in: [true, false] })
  const { data: whatsappPhoneNumbers = [], isSuccess: isSuccessWhatsappPhoneNumbers } = useCQuery('whatsappPhoneNumbers')
  const queryArgs = [customAssignedFilter ? customAssignedFilterValue : assignedFilter, selectedFilter, showHidden, [range[0].toLocaleDateString('it-IT'), range[1].toLocaleDateString('it-IT')], null, hasMessages]
  const { data: conversations = [], isSuccess, isStale } = useCInfiniteQuery(['conversations', ...queryArgs])
  const [conversationsTable, setConversationsTable] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [datesSearchArchived, setDatesSearchArchived] = useState(null)
  const [conversationIdToDelete, setConversationIdToDelete] = useState(null)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [conversationIdTime, setConversationIdTime] = useState(null)
  const [timeModalVisible, setTimeModalVisible] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()
  const { user: { user } } = auth || {}
  const [showNewConversationModal, setShowNewConversationModal] = useState(false)
  const [rowsCount, setRowsCount] = useState(0)

  const rowClass = ({ messagesToRead }) => `${messagesToRead > 0 ? 'unread-row' : 'read-row'}`

  useEffect(() => {
    // invalidate all the queries except the current one when the filters change
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    const queries = queryClient.getQueryCache().findAll()
    queries.forEach((query) => {
      if (query.queryKey[0] === 'conversations' && JSON.stringify(query.queryKey) === JSON.stringify(['conversations', ...queryArgs])) {
        invalidateQuery([query.queryKey])
      }
    })
  }, [customAssignedFilter ? customAssignedFilterValue : assignedFilter, selectedFilter, showHidden, range])

  useEffect(() => {
    const count = conversationsTable?.filter((item) =>
      Object.keys(tableState.filters).every((key) => {
        const filterValue = tableState?.filters?.[key]?.value
        if (filterValue) {
          const itemValue = getNestedValue(item, key)
          return itemValue?.toLowerCase()?.includes(filterValue?.toLowerCase())
        }
        return true
      })).length
    setRowsCount(count)
  }, [tableState, conversationsTable.length])

  useEffect(() => {
    if (isSuccess && !isStale) {
      // controllo se ci sono messaggi non letti, se sì setto il filtro su 'unread' altrimenti su 'all'
      // uso isStale per calcolare i messaggi non letti solo quando i dati della query sono aggiornati (a seguito di un invalidateQuery)
      setConversationsTable(conversations
        ?.filter((r) =>
          noWhatsapp
            ? !r.whatsappPhoneNumberId
            : ((!onlyGattinoni || whatsappPhoneNumbers?.filter((w) => w.environmentId === gattinoniId)?.map(({ id }) => id).includes(r.whatsappPhoneNumberId)) &&
              (!noGattinoni || (!whatsappPhoneNumbers?.filter((w) => w.environmentId === gattinoniId)?.map(({ id }) => id).includes(r.whatsappPhoneNumberId) && r.whatsappPhoneNumberId))))
        .map((r) => {
          const createdAt = new Date(r.createdAt)
          return {
            ...r,
            traveller: {
              ...r.traveller,
              client: r.traveller?.client || { ragSociale: 'Non definito', environment: { agencyName: 'Non definito' } }
            },
            user: r.user || { username: 'Non assegnato' },
            createdAt: formatDateHour(createdAt),
            lastMessageAt: r.lastMessageAt ? formatDateHour(new Date(r.lastMessageAt)) : 'Non definito',
            statusLabel: conversationsStatus?.[r.status],
            title: r.title || 'Non definito'
          }
        })
      )
    }
  }, [JSON.stringify(conversations), location.pathname, selectedFilter, isSuccess, isStale])

  const BtnActionProc = ({ messagesToRead, statusLabel, id }) => (
    <Flex jb row>
      {(statusLabel === 'Errore') && <Text value='⚠️' />}
      {(user?.role === 'admin' || user?.role === 'gestoreH24' || user?.role === 'gestoreOperatori') &&
        <Button round icon='fa-duotone fa-clock' style={{ marginRight: 10 }} onClick={() => { setConversationIdTime(id); setTimeModalVisible(true) }} />}
      {(user?.role === 'admin' || user?.role === 'gestoreH24') &&
        <Button round icon='fa-duotone fa-trash' style={{ marginRight: 10 }} onClick={() => { setConversationIdToDelete(id); setDeleteModalVisible(true) }} />}
      <Button round icon='fa-duotone fa-paper-plane' style={{ marginRight: 10 }} onClick={() => navigate('/operatore/' + id)} onHover={() => prefetchQuery(['conversation', id?.toString()])} />
      {(messagesToRead > 0) &&
        <Flex style={{ marginRight: 10, color: 'white', backgroundColor: 'rgb(5, 202, 15)', borderRadius: '50%', width: 30, height: 30 }}>
          <Text bold center value={messagesToRead} style={{ flexGrow: 0, textAlign: 'center', width: '100%' }} />
        </Flex>}
    </Flex>
  )

  /**
 * Restituisce lo stile dell'etichetta di stato per una riga di dati.
 *
 * Questa funzione prende i dati di una riga e determina lo stile appropriato
 * per l'etichetta di stato in base al ruolo dell'utente e alle opzioni di filtro dello stato.
 *
 * @param {Object} rowData - I dati della riga che contengono lo stato e l'etichetta di stato.
 * @returns {JSX.Element} L'elemento Text con lo stile applicato all'etichetta di stato.
 *
 * @author @mranocchia
 */
  const getStatusLabelStyle = (rowData) => {
    const status = rowData.status
    const listStatus = getStatusFilterOptions(user?.role)
    const statusObj = listStatus.find((s) => s.value === status)
    const defaultStyle = { textAlign: 'center', border: 'none' }
    const style = statusObj?.color ? { ...defaultStyle, color: statusObj?.color } : defaultStyle
    return (<Text value={rowData.statusLabel} style={style} />)
  }

  if (!isSuccess || !isSuccessWhatsappPhoneNumbers) return <Spinner />
  return (
    <Flex fw>
      <Modal
        style={{ width: '80vw', height: '95vh', borderRadius: 10 }}
        contentStyle={{ padding: 0, borderRadius: 10, overflow: 'hidden' }}
        visible={showNewConversationModal} onHide={() => setShowNewConversationModal(false)}
      >
        <StartConversaton close={() => setShowNewConversationModal(false)} />
      </Modal>
      <ConfirmModal
        header='Cancellazione attività' label="Vuoi cancellare l'attività selezionata?"
        onConfirm={() => deleteConversation(conversationIdToDelete)}
        visible={[deleteModalVisible, setDeleteModalVisible]}
      />
      <Modal
        style={{ width: '80vw', height: '95vh', borderRadius: 10 }}
        contentStyle={{ padding: 0, borderRadius: 10, overflow: 'scroll' }}
        visible={timeModalVisible}
        onHide={() => setTimeModalVisible(false)}
      >
        <Flex fw ae>
          <Icon name='fa-duotone fa-xmark' onClick={() => setTimeModalVisible(false)} />
        </Flex>
        <Flex as js style={{ padding: 20 }}>
          <TimeView conversationId={conversationIdTime} />
        </Flex>
      </Modal>
      <Flex fw jb row wrap style={{ gap: 30 }}>
        <Flex row js style={{ marginTop: 10 }}>
          {allowCreate &&
            <Button icon='fa-duotone fa-plus' label='Crea attività' onClick={() => navigate('/operatore/new')} style={{ maxWidth: 200, marginRight: 20 }} />}
          {allowSendMessage &&
            <Button icon='fa-duotone fa-plus' label='Invia messaggio' onClick={() => setShowNewConversationModal(true)} style={{ maxWidth: 300, marginRight: 20 }} />}
          {(user?.role === 'admin' || user?.role === 'gestoreH24') && (
            <>
              <Modal style={{ width: '40vw' }} visible={modalVisible} header='Archiviazione Conversazioni' onHide={() => setModalVisible(false)}>
                <Flex fw as>
                  <Text value='Seleziona un range di date di conversazioni da archiviare' />
                  <Calendar value={datesSearchArchived} selectionMode='range' onChange={(e) => setDatesSearchArchived(e.value)} locale='it' dateFormat='dd/mm/yy' style={{ marginTop: 20, width: '100%' }} placeholder='Data' />
                  <Flex fw row jb style={{ marginTop: 20 }}>
                    <Button
                      label='Annulla' icon='fa-duotone fa-trash' onClick={() => {
                        setDatesSearchArchived(null)
                        setModalVisible(false)
                      }}
                    />
                    <Button label='Conferma' icon='fa-duotone fa-check' onClick={() => archiveConversations(datesSearchArchived, setModalVisible, setDatesSearchArchived)} />
                  </Flex>
                </Flex>
              </Modal>
              <Button icon='fa-duotone fa-xmark' label='Archivia attività' onClick={() => setModalVisible(true)} style={{ maxWidth: 200 }} />
            </>)}
          <Text value={'Totale attività: ' + rowsCount} style={{ marginLeft: 20 }} />
        </Flex>
        <Flex row je style={{ marginTop: 10 }}>
          <span className='p-float-label'>
            <Calendar
              value={datesSearch} selectionMode='range' onChange={(e) => {
                setDatesSearch(e.value)
                e.value?.filter(Boolean).length === 2 && setRange(e.value)
              }}
              locale='it' dateFormat='dd/mm/yy' style={{ width: 250 }}
              inputStyle={{ borderRadius: 20, height: 44 }}
            />
            <label style={{ fontFamily: 'Lexend' }}>Filtra per data</label>
          </span>
          {user?.role === 'admin' && !noWhatsapp &&
            <span className='p-float-label'>
              <Input
                id='hiddenFilter'
                dropdown placeholder='Filtra per visibilità'
                options={statusHidden}
                value={showHidden}
                onChange={({ hiddenFilter }) => setShowHidden(hiddenFilter)}
                style={{ width: 250, marginLeft: 5 }}
              />
              <label style={{ fontFamily: 'Lexend' }}>Stato visibilità</label>
            </span>}
          <span className='p-float-label'>
            <Input
              id='assignedFilter'
              dropdown placeholder='Filtra per assegnatario'
              options={statusAssignedOptions}
              value={customAssignedFilter ? customAssignedFilterValue : assignedFilter}
              onChange={({ assignedFilter }) => customAssignedFilter ? setCustomAssignedFilterValue(assignedFilter) : setAssignedFilter(assignedFilter)}
              style={{ width: 250, marginLeft: 5 }}
            />
            <label style={{ fontFamily: 'Lexend' }}>Stato assegnazione</label>
          </span>
          <span className='p-float-label'>
            <Input
              id='statusFilter' dropdown placeholder='Filtra per stato'
              options={getStatusFilterOptions(user?.role)}
              value={selectedFilter}
              onChange={({ statusFilter }) => setSelectedFilter(statusFilter)}
              style={{ width: 280, marginLeft: 5 }}
            />
            <label style={{ fontFamily: 'Lexend' }}>Stato conversazione</label>
          </span>
        </Flex>
      </Flex>
      <DataTable
        style={{ width: '100%', marginTop: 10 }} filterDisplay='row' className='TableSignatures'
        value={conversationsTable} emptyMessage='Nessuna attività trovata' responsiveLayout='scroll' paginator rows={10}
        rowClassName={rowClass}
        first={tableState.first} onPage={setTableState}
        onFilter={(event) => { event.first = 0; setTableState(event) }}
        filters={tableState.filters} totalRecords={conversationsTable.length}
      >
        {!noWhatsapp && <Column field='traveller.phone' {...columnProps('Telefono')} />}
        <Column field='traveller.client.ragSociale' {...columnProps('Cliente')} />
        <Column field='traveller.client.environment.agencyName' {...columnProps('Agenzia')} />
        <Column field='title' {...columnProps('Titolo')} />
        <Column field='user.username' {...columnProps('Operatore')} />
        <Column field='statusLabel' body={getStatusLabelStyle} {...columnProps('Stato')} />
        {noWhatsapp
          ? <Column field='createdAt' {...columnProps('Data inizio attività')} />
          : <Column field='lastMessageAt' {...columnProps('Data ultimo messaggio')} />}
        <Column style={{ border: 'none', width: 40, height: 15, textAlign: 'center' }} body={(props) => <BtnActionProc {...props} />} />
      </DataTable>
    </Flex>
  )
}
