import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import fcAuth from '@feathersjs/authentication-client'
import io from 'socket.io-client'
import { queryClient } from '../App'
import { getConversationsQueryArguments, handleMessageGrowl } from './Functions'
import { invalidateQuery } from './QueryCache'

// const isDev = !(window._env_ && window._env_.NODE_ENV && window._env_.NODE_ENV === 'production')
const API_BASE_URL = window._env_ && window._env_.API_BASE_URL ? window._env_.API_BASE_URL : 'http://localhost:3000'

const FC = {
  socketConfig: {
    timeout: 60000,
    pingInterval: 5000,
    pingTimeout: 20000
  },
  socket: io(API_BASE_URL),
  client: feathers(),
  authenticated: false,
  online: false,
  isDev: true,
  connectionHandler: (event) => () => {
    FC.isDev && console.log(`Socket ${event} to ${API_BASE_URL}`)
    FC.online = event === 'connect'
  }
}

FC.client.configure(socketio(FC.socket, FC.socketConfig))
FC.client.configure(fcAuth({ storage: new fcAuth.MemoryStorage() }))
FC.socket.on('connect', FC.connectionHandler('connect'))
FC.socket.on('disconnect', FC.connectionHandler('disconnect'))

FC.addListeners = () => {
  FC.client.service('process').on('patched', ({ conversationId }) => {
    invalidateQuery([['conversation', conversationId?.toString()]])
  })
  FC.client.service('messages').on('created', (message) => {
    if (message.sender === 'traveller') {
      const queries = getConversationsQueryArguments()
      queries.forEach(query => {
        const data = queryClient.getQueryData(query)
        if (!data?.pages?.some((page) => page.some((conv) => conv.id === message.conversationId))) return invalidateQuery([query])
        queryClient.setQueryData(query, (oldConvData) => {
          if (!oldConvData) return
          const newOldConvData = oldConvData.pages.map((page) => {
            return page.map((conv) => {
              if (conv.id === message.conversationId) return { ...conv, messagesToRead: conv?.messagesToRead + 1 }
              return conv
            })
          })
          return { ...oldConvData, pages: newOldConvData }
        })
      })
    }
    handleMessageGrowl(message)
    invalidateQuery(['messagesToRead'])
    const { user: { id = '' } = {} } = JSON.parse(window.localStorage.getItem('user')) || {}
    if (message.sender !== 'operator' || message.userId !== id) return null
    window.growl.show({ severity: 'success', summary: 'Messaggio inviato', detail: 'Messaggio inviato con successo' })
  })
  FC.client.service('messages').on('patched', (message) => {
    invalidateQuery(['messagesToRead'])
  })
  FC.client.service('conversations').on('created', (conversation) => {
    invalidateQuery(['conversations'])
  })
  FC.client.service('time').on('created', (time) => {
    invalidateQuery([['time', time.conversationId]])
  })
  FC.client.service('conversations').on('patched', (conversation) => {
    const { id, status, title, lastMessageAt } = conversation
    queryClient.setQueryData(['conversation', id?.toString()], (oldConvData) => {
      if (!oldConvData) return
      return { ...oldConvData, status, title, lastMessageAt }
    })
    const queries = getConversationsQueryArguments(conversation)
    queries.forEach(query => {
      const data = queryClient.getQueryData(query)
      if (!data?.pages?.some((page) => page.some((conv) => conv.id === id))) return
      queryClient.setQueryData(query, (oldConvData) => {
        if (!oldConvData) return
        const newOldConvData = oldConvData.pages.flat().map((conv) => {
          if (conv.id === id) return { ...conv, status, title, lastMessageAt }
          return conv
        })?.filter((conv) => {
          // elimino la conversazione se non rispetta i filtri della query
          // solo nel caso in cui non sia "tutte le conversazioni" o "da leggere"
          return !query?.[2] || query?.[2] === 'all' || query?.[2] === 'unread' || conv.status === query?.[2]
        })?.sort((a, b) => {
          if (a.lastMessageAt && b.lastMessageAt) return new Date(b.lastMessageAt) - new Date(a.lastMessageAt)
          return new Date(b.createdAt) - new Date(a.createdAt)
        })
        return { ...oldConvData, pages: [newOldConvData] }
      })
    })
  })
  FC.isDev && console.log('FC Authenticated & Listening for events')
}

FC.stopListeners = () => {
  FC.client.service('processStep').removeListener('created')
  FC.isDev && console.log('FC Stopped listening for events')
}

FC.login = async (credentials) => {
  try {
    const response = await FC.client.authenticate(credentials)
    FC.authenticated = !!response.accessToken
    FC.authenticated && FC.addListeners()
    return response
  } catch (details) {
    FC.error = details
    return false
  }
}

FC.logout = async () => {
  FC.stopListeners()
  FC.client.logout()
  FC.authenticated = false
}

FC.service = (...params) => FC.client.service(...params)

FC.isReady = () => FC.online && FC.authenticated

export { FC }
