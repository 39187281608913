import { FC } from '../../Services/FeathersClient'

/**
 * Controlla lo stato dell'iscrizione alle notifiche push per l'utente fornito.
 * Se il dispositivo corrente non è ancora registrato per ricevere notifiche push,
 * procede con la registrazione chiamando la funzione `subscribeUserToPush`.
 *
 * @param {Object} user Oggetto utente che contiene le informazioni necessarie,
 *                      incluso se il dispositivo attuale è già registrato per le notifiche push.
 * @returns {Promise<void>} Una promise che si risolve quando il controllo e l'eventuale
 *                          iscrizione sono stati completati.
 */
export const checkSubscription = async (user) => {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    try {
      const registration = await navigator.serviceWorker.ready
      const subscription = await registration.pushManager.getSubscription()
      // Check if the device is already registered for notifications
      const isDeviceRegistered = subscription && user.pushNotificationsDevices.some(item => item.endpoint === subscription.endpoint)

      if (!isDeviceRegistered) {
        // è un nuovo device per stesso utente
        subscribeUserToPush(user)
      }
      // No need to handle the case where the device is already registered ("do nothing" case)
    } catch (error) {
      console.error('Error checking subscription:', error)
      // Handle subscription failure case here if necessary
    }
  }
}

/**
 * Gestisce la sottoscrizione alle notifiche push per un utente.
 * Se l'utente non è ancora iscritto, procede con la sottoscrizione.
 * Se l'utente è già iscritto, verifica lo stato della sottoscrizione.
 *
 * @param {Object} [param0={}] - Un oggetto opzionale che contiene le proprietà dell'utente.
 * @param {Object} param0.user - L'oggetto utente che deve essere gestito.
 */
export const handleSubscribeNotify = async ({ user } = {}) => {
  if (!user.pushNotificationsDevices) {
    subscribeUserToPush(user)
  } else {
    // L'utente è già iscritto; gestisci come necessario.
    checkSubscription(user)
  }
}

/**
 * Sottoscrive l'utente al servizio di notifiche push.
 *
 * Questa funzione asincrona registra un utente per ricevere notifiche push attraverso il Service Worker.
 * Controlla se il dispositivo dell'utente è già registrato; in caso contrario, procede con la registrazione.
 * Utilizza una chiave pubblica codificata in base64 per creare le opzioni di sottoscrizione necessarie.
 * In caso di successo, salva il dispositivo di notifica nell'elenco dei dispositivi associati all'utente.
 * Se si verifica un errore durante il processo di sottoscrizione, viene visualizzato un messaggio di errore nella console.
 *
 * @param {Object} user - L'oggetto utente che deve essere sottoscritto alle notifiche push. Deve contenere un campo `id` e può contenere un campo `pushNotificationsDevices` come array di dispositivi registrati.
 * @returns {Promise<void>} Una promessa che si risolve quando la sottoscrizione è completata o viene rifiutata in caso di errore.
 */
export const subscribeUserToPush = async (user) => {
  console.log('Subscribing the user ....')
  try {
    const registration = await navigator.serviceWorker.ready
    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(
        'BHONb2vjzsp_kMIpuYfhI5X46CsiBr8JlHFQDX2zpIOgbau1NJos4X94FTu5SNMfkX3b1BjCguFVP1YrthhTfFg'
        // Replace with environment variable for the public key
      )
    }
    const userGet = await FC.service('users').get(user.id)

    const pushSubscription = await registration.pushManager.subscribe(subscribeOptions)
    const existingDevice = userGet?.pushNotificationsDevices?.some(item => item.endpoint === pushSubscription.endpoint)

    if (existingDevice) {
      // Device is already registered, do nothing
      return
    }

    const singleNotificationDevice = {
      keyP256dh: btoa(String.fromCharCode.apply(null, new Uint8Array(pushSubscription.getKey('p256dh')))),
      keyAuth: btoa(String.fromCharCode.apply(null, new Uint8Array(pushSubscription.getKey('auth')))),
      endpoint: pushSubscription.endpoint
    }
    const unifiedDevices = userGet.pushNotificationsDevices ? [...userGet.pushNotificationsDevices, singleNotificationDevice] : [singleNotificationDevice]
    FC.service('users').patch(user.id, { pushNotificationsDevices: unifiedDevices })
    console.log('User subscribed successfully!!!')
  } catch (err) {
    console.error('Unable to subscribe user:', err)
  }
}

/**
 * Converte una stringa in base64 in un oggetto Uint8Array.
 * Questa funzione è utile per la manipolazione di chiavi pubbliche VAPID
 * utilizzate nelle notifiche push Web.
 *
 * @param base64String La stringa in base64 da convertire.
 * @return Un Uint8Array rappresentante i dati decodificati dalla stringa in base64.
 */
function urlBase64ToUint8Array (base64String) {
  // Funzione helper per convertire la chiave pubblica VAPID da base64 a Uint8Array
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

/**
 * Disiscrive l'utente dai push notifications e aggiorna i dispositivi associati.
 *
 * Questa funzione asincrona tenta di disiscrivere un utente dai servizi di push notification.
 * Se l'utente ha una sottoscrizione attiva, viene cancellata. Successivamente, rimuove il
 * dispositivo associato alla sottoscrizione corrente dall'elenco dei dispositivi per le notifiche push
 * dell'utente. Se ci sono altri dispositivi rimasti, aggiorna l'utente con la nuova lista,
 * altrimenti imposta il campo a null.
 *
 * @param {Object} user - L'oggetto utente che contiene l'id e l'elenco dei dispositivi per le notifiche push.
 * @throws {Error} Lancia un errore se c'è un problema durante la disiscrizione o l'aggiornamento dell'utente.
 */
export const unsubscribeUserFromPush = async ({ user }) => {
  try {
    console.log('Unsubscribing the user')
    const registration = await navigator.serviceWorker.ready
    const subscription = await registration.pushManager.getSubscription()
    const userGet = await FC.service('users').get(user.id)
    if (subscription) {
      const successful = await subscription.unsubscribe()
      if (!successful) throw new Error('Failed to unsubscribe the user. successfull:' + successful)
      const filteredDevices = userGet.pushNotificationsDevices?.filter(item => item.endpoint !== subscription.endpoint) || []
      const devicesToUpdate = filteredDevices.length > 0 ? filteredDevices : null // se ci sono altri dispositivi, aggiorna il campo, altrimenti null
      FC.service('users').patch(userGet.id, { pushNotificationsDevices: devicesToUpdate })
      console.log('User unsubscribed successfully!!!')
    }
  } catch (e) {
    console.log('Error during unsubscription:', e)
  }
}
