import { AutoComplete } from 'primereact/autocomplete'
/**
 * Crea un componente AutoCompleteCustom.
 *
 * @param props.inputId Identificativo unico per l'elemento di input.
 * @param props.label Etichetta visiva per il campo di autocompletamento.
 * @param props.value Valore attuale del campo di autocompletamento.
 * @param props.field Nome del campo da utilizzare per l'autocompletamento.
 * @param props.suggestions Array di suggerimenti per l'autocompletamento.
 * @param props.completeMethod Metodo da chiamare per ottenere i suggerimenti.
 * @param props.onChange Funzione da eseguire quando cambia il valore nel campo di input.
 * @param props.onSelect Funzione da eseguire quando viene selezionato un suggerimento.
 * @returns {JSX.Element} Un componente React che rappresenta un campo di autocompletamento personalizzato.
 */
export const AutoCompleteCustom = ({ inputId, label, value, field, suggestions, completeMethod, onChange, onSelect, error, flexWidth, forceSelection, readOnly }) => {
  const className = error ? 'p-invalid' : ''
  const labelColor = error ? 'red' : ''

  return (
    <div className='card flex justify-content-center' style={{ marginTop: 25, width: flexWidth || '100%' }}>
      <span className='p-float-label'>
        <AutoComplete
          inputId={inputId} placeholder={label}
          inputStyle={{ width: '100%' }}
          style={{ width: '100%', color: labelColor }} value={value || ''}
          suggestions={suggestions}
          readOnly={readOnly}
          completeMethod={completeMethod}
          onChange={({ value }) => onChange(value)}
          onSelect={({ value }) => {
            onSelect(value)
          }}
          forceSelection={forceSelection}
          field={field}
          className={className}
        />
        <label style={{ fontFamily: 'Lexend', color: labelColor }} className={className}>{label}</label>
      </span>
    </div>
  )
}
