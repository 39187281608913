import React, { useEffect, useState, useRef } from 'react'
import { Button, Flex, Spinner, Text } from '../../../Components'
import { FC, useCQuery } from '../../../Services'
import { checkDuplicateEvents, checkInput, clientInputCheck, createNewClient, createNewProcess, getCorrectState, getLastProcessStep, getStateDefaultData, patchClient, patchTraveller, setAndShowResume, showError, updateAgencyDetails, updateClientDetails, updatePhonePrefix, updateSenderDetails } from './Functions'
import { useParams, useNavigate } from 'react-router-dom'
import { deleteConversation, getStateValues } from '../GigiList/Functions'
import { MainDataSection, SenderSection } from './Sections'
import { ClientSection } from './Sections/Client'
import { FormHeader } from './Sections/FormHeader'
import { useAuth } from '../../../Provider/AuthProvider'
import { ConfirmModal } from '../../../Components/Common/ConfirmModal'
import { FormChat } from './FormChat'
import { Events } from './Sections/Events'
import { getEventsCount, getTotalPassengersCount } from './Sections/Functions'
import { HoursDates } from './Sections/HoursDates'
import { Checkbox } from 'primereact/checkbox'
import { PanelText } from '../../../Components/Common/PanelText'
import { gattinoniId } from '../GigiList/index'
import { formatMilliseconds } from '../../StatisticheOperatori/Functions'

const { RUNNING, READYTORESTART } = getStateValues()

export function Form ({ conversationId: conversationIdProp, viewOnly }) {
  const { conversationId } = conversationIdProp ? { conversationId: conversationIdProp } : useParams()
  const navigate = useNavigate()
  const { data: conversation = {}, isSuccess } = useCQuery(['conversation', conversationId])
  const { data: time = [], isSuccess: isSuccessTime } = useCQuery(['time', conversationId])
  const { isSuccess: isSuccessFormFields } = useCQuery(['formFields'])
  const { user = {} } = useAuth()
  const { user: userObj = {} } = user || {}
  const { role } = userObj
  const stateRef = useRef()
  const [endConversationLoading, setEndConversationLoading] = useState(false)
  const [readOnly, setReadOnly] = useState(false)
  const [render, setRender] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [lastProcessStepId, setLastProcessStepId] = useState()
  const [lastStatus, setLastStatus] = useState()
  const [agency, setAgency] = useState({ id: '', codClienteNerone: '', ragSociale: '' })
  const [client, setClient] = useState({ id: '', codCliente: '', ragSociale: '' })
  const [sender, setSender] = useState({})
  const [cellulare, setCellulare] = useState('')
  const [prefix, setPrefix] = useState('')
  const [userEdit, setUserEdit] = useState(false)
  const [checkError, setCheckError] = useState(false)
  const [timeSum, setTimeSum] = useState(0)
  const [noteModalVisible, setNoteModalVisible] = useState(false)
  const [resume, setResume] = useState('')
  const [title, setTitle] = useState('')
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  const hideChat = !conversation?.whatsappPhoneNumberId

  // funzione che cambia user edit a true se l'utente modifica un campo
  // se userEdit è true non viene sovrascritto lo stato con i dati della conversazione
  const changeUserEdit = (set) => (...data) => {
    setUserEdit(true)
    set(...data)
  }

  const setState = (newState, forceRender) => {
    const mustRender = [
      getEventsCount(newState) !== getEventsCount(stateRef.current),
      getTotalPassengersCount(newState) !== getTotalPassengersCount(stateRef.current),
      forceRender
    ]?.some((v) => v)
    stateRef.current = newState
    mustRender && setRender(!render)
  }

  useEffect(() => {
    if (isSuccess) {
      const lastProcess = conversation?.lastProcess
      const lastStatus = lastProcess?.state
      setLastStatus(lastStatus)
      const lastProcessStep = conversation?.lastProcessStep
      const lastPayload = Object.keys(lastProcessStep?.finalState || {}).length ? lastProcessStep?.finalState : lastProcessStep?.initialState || {}
      // posso editare il form nel caso in cui il processo sia in pausa (quindi senza errori)
      // oppure se il processo è in errore / redirect operator e sono ancora in fase di raccolta dati

      const editable = (lastStatus !== RUNNING && lastStatus !== READYTORESTART)
      const newReadOnly = !editable || viewOnly

      const hasDifferentReadOnly = newReadOnly !== readOnly
      const isStateUninitialized = !stateRef.current
      const hasLastProcessStepIdChanged = lastProcessStepId !== lastProcessStep?.id
      const shouldForceUpdate = lastPayload?.forceUpdate

      const shouldUpdateSettings = hasDifferentReadOnly || isStateUninitialized || hasLastProcessStepIdChanged || shouldForceUpdate

      if (userEdit) return

      if (shouldUpdateSettings) {
        setLastProcessStepId(lastProcessStep?.id)
        setReadOnly(newReadOnly)
        setState(getStateDefaultData(lastPayload), true)
        setTitle(conversation?.title)
        updateAgencyDetails(conversation, setAgency)
        updateClientDetails(conversation, setClient)
        updateSenderDetails(conversation, setSender)
        updatePhonePrefix(conversation, setCellulare, setPrefix)
        setResume(lastPayload?.mainData?.notes)
      }
    }
  }, [isSuccess, conversation])

  useEffect(() => {
    if (isSuccessTime && time?.length) {
      setTimeSum(time.reduce((acc, curr) => acc + curr.time, 0))
    }
  }, [time, isSuccessTime])

  if (!isSuccess || !stateRef.current) return <Spinner />

  /**
   * Aggiorna lo stato asincronamente e gestisce le operazioni correlate al mittente e al viaggiatore.
   *
   * Il metodo prepara il nuovo stato adottando quello corrente e aggiungendo il flag {@code sentFromGigiForm}.
   * Poi, compila il numero di cellulare completo e aggiorna i dati del mittente. Effettua una chiamata di servizio
   * per aggiornare lo stato con l'ID della conversazione corrente. Esegue un controllo sull'input del cliente;
   * se il cliente non ha un ID, mostra un modale. Successivamente, tenta di patchare i dati del viaggiatore
   * e notifica l'utente del successo tramite un messaggio. Se si verifica un errore durante il processo,
   * notifica l'utente dell'errore.
   */
  const updateState = async () => {
    try {
      const correctState = { ...getCorrectState(stateRef.current), sentFromGigiForm: true }
      const cellulareCompleto = `${prefix}${cellulare}`
      correctState.datiMittente = { ...correctState.datiMittente, cellulare: cellulareCompleto }
      correctState.mainData = { ...correctState.mainData, notes: resume }
      sender.phone = cellulareCompleto
      if (!client.id && (client.codCliente || client.ragSociale)) {
        setCheckError(true)
        if (!clientInputCheck(agency, client)) return
        setCheckError(false)
        setModalVisible(true)
        return false
      }
      if (!title) return showError('title')
      if (!checkDuplicateEvents(correctState)) return
      if ((title || '') !== (conversation.title || '')) await FC.service('conversations').patch(conversationId, { title })
      await FC.service('info').create({ action: 'updateState', state: correctState, conversationId })
      await patchTraveller(conversation, sender, client.id)
      await patchClient(client)
      window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Salvataggio effettuato con successo' })
      return true
    } catch (e) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore durante il salvataggio' })
      return false
    }
  }

  /**
 * Gestisce l'arrivo di un nuovo cliente eseguendo in modo asincrono la creazione di un nuovo cliente.
 * Invoca il metodo {@code createNewClient} con i parametri della conversazione, del mittente, del cliente
 * e dell'agenzia forniti.
 */
  const handleNewClient = async () => {
    const newClient = await createNewClient(conversation, sender, client, agency)
    if (newClient) setClient({ id: newClient.id, codCliente: newClient.codCliente, ragSociale: newClient.ragSociale })
  }

  /**
   * Conclude la conversazione attuale.
   * Questa funzione verifica prima l'input dell'utente e poi controlla se l'id del cliente non è settato.
   * Se le condizioni sono soddisfatte, procede a impostare lo stato della conversazione su terminata,
   * crea un nuovo processo e invalida la cache delle conversazioni.
   * In caso di successo, mostra un messaggio positivo all'utente. In caso di errore, viene mostrato un messaggio di errore.
   *
   * @async
   * @function endConversation
   * @returns {Promise<void>} Ritorna una promise che si risolve quando la conversazione è stata conclusa con successo o rifiutata con errore.
   */
  const endConversation = async () => {
    try {
      const newState = { ...getCorrectState(stateRef.current), mainData: { ...stateRef.current?.mainData, notes: resume } }
      await createNewProcess(newState, conversationId, 'endConversationOperatorSingleUnit')
      setNoteModalVisible(false)
      window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Conversazione conclusa con successo' })
    } catch (e) {
      return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore conclusione conversazione' })
    }
  }

  /**
   * Salva lo stato attuale e termina la conversazione.
   * Questo metodo esegue in modo asincrono l'aggiornamento dello stato
   * seguito dalla chiusura della conversazione.
   */
  const finalizeChatSession = async () => {
    await updateState()
    await endConversation()
  }

  /**
   * Salva il riepilogo della conversazione.
   * <p>
   * Questa funzione asincrona aggiorna lo stato e poi imposta la visibilità
   * della modale delle note su falso, nascondendola.
   */
  const saveConversationResume = async () => {
    await updateState()
    setNoteModalVisible(false)
  }

  /**
   * Genera un riassunto per l'intelligenza artificiale basato sulla conversazione corrente.
   * Tenta di creare un nuovo processo e recuperare il passo finale del processo per ottenere le note.
   * Imposta le note per il riassunto e mostra un modale con queste, se già non sono state generate.
   *
   * @async
   * @function generateAIResume
   * @returns {Promise<void>} Restituisce implicitamente undefined se la funzione completa la sua esecuzione senza errori.
   * @throws {Error} Mostra un errore utilizzando `window.growl.show` se si verifica un errore durante la generazione del riassunto.
   */
  const generateAIResume = async () => {
    try {
      // check if one event is present only for noWhatsappPhoneNumberId
      if (!title) return showError('title')
      if (!stateRef.current?.events?.length && !conversation?.whatsappPhoneNumberId) {
        return showError('missingEvents')
      }
      if (!checkInput({ ...sender, prefix, cellulare })) {
        setNoteModalVisible(false)
        setCheckError(true)
        return
      }
      if (!client.id && !client.codCliente && !client.ragSociale) {
        setCheckError(true)
        showError('ragSocialeCliente')
        return
      }
      if (!client.id && (client.codCliente || client.ragSociale)) { // se non ho l'id ma ho inserito un nuovo cliente
        if (!clientInputCheck(agency, client)) {
          setCheckError(true)
          return
        }
        setModalVisible(true)
        return
      }
      if ((title || '') !== (conversation.title || '')) await FC.service('conversations').patch(conversationId, { title })
      const newState = { ...getCorrectState(stateRef.current) }
      const aiGenerated = conversation?.lastProcessStep?.finalState?.mainData?.aiGenerated || false
      const notesFromConversation = conversation?.lastProcessStep?.finalState?.mainData?.notes || ''

      let notesToSet = notesFromConversation

      if (!conversation?.whatsappPhoneNumberId) {
        setEndConversationLoading(true)
        await saveAfterModal()
        setEndConversationLoading(false)
        return
      }

      if (!aiGenerated) {
        setEndConversationLoading(true)
        const processId = await createNewProcess(newState, conversationId, 'createResumeForConversation')
        if (processId) {
          const lastProcessStep = await getLastProcessStep(processId)
          notesToSet = lastProcessStep?.finalState?.mainData?.notes || ''
        }
        setEndConversationLoading(false)
      }

      setAndShowResume(notesToSet, setResume, setNoteModalVisible)
    } catch (e) {
      return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore generazione riassunto conversazione' })
    }
  }

  /**
   * Archivia o rimuove dall'archivio una conversazione specifica.
   * Modifica lo stato della conversazione per contrassegnarla come 'archived' (archiviata)
   * oppure come 'ended' (terminata) se già archiviata. Dopo l'aggiornamento,
   * invalida la query relativa alla conversazione e mostra una notifica di successo o errore.
   *
   * @async
   * @function archiveConversation
   * @returns {Promise<void>} Una promessa che risolve senza restituire un valore
   *                          ma effettua azioni collaterali riguardanti le notifiche all'utente.
   */
  const archiveConversation = async () => {
    try {
      await FC.service('conversations').patch(conversationId, { status: conversation.status === 'archived' ? 'ended' : 'archived' })
      window.growl.show({ severity: 'success', summary: 'Successo', detail: conversation.status === 'archived' ? 'Conversazione rimossa da archiviazione' : 'Conversazione archiviata' })
    } catch (e) {
      return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore archiviazione conversazione' })
    }
  }

  /**
  * Gestisce la conclusione o riapertura di una conversazione per il cliente.
  *
  * Questo metodo aggiorna lo stato della conversazione in base al suo stato attuale.
  * Se la conversazione è contrassegnata come 'customerCompleted', verrà riaperta;
  * altrimenti, sarà contrassegnata come 'customerCompleted'.
  * Inoltre, invalidiamo le query relative alle conversazioni per garantire che i dati siano aggiornati
  * e mostriamo un messaggio di successo o errore a seconda del risultato dell'operazione.
  *
  * @param {Event} e - L'evento che ha scatenato l'azione.
  * @throws {Error} Se si verifica un errore durante l'aggiornamento dello stato della conversazione.
  * @author @mranocchia
  */
  const conclusoClienteConversation = async (e) => {
    try {
      await FC.service('conversations').patch(conversationId, { status: conversation.status === 'customerCompleted' ? 'open' : 'customerCompleted' })
      window.growl.show({ severity: 'success', summary: 'Successo', detail: conversation.status === 'customerCompleted' ? 'Conversazione riaperta' : 'Conversazione conclusa per il cliente' })
    } catch (error) {
      return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore archiviazione conversazione' })
    }
  }

  const saveAfterModal = async () => {
    if (conversation.status === 'ended') {
      await saveConversationResume()
    } else {
      await finalizeChatSession()
    }
  }

  const stateProps = { state: [stateRef.current, setState], client: [client], readOnly }

  if (!stateRef.current || !isSuccessFormFields) return <Spinner />
  return (
    <Flex fw as row>
      <Flex style={{ width: hideChat ? '100%' : '60%' }} id='gigiForm'>
        {!viewOnly &&
          <Flex fw jb style={{ height: 30, marginBottom: 10 }} row>
            <Flex row>
              {lastStatus === RUNNING
                ? (
                  <>
                    <Text value='Elaborazione messaggio in corso' style={{ fontWeight: 'bold', width: 270 }} />
                    <Spinner size={20} style={{ marginLeft: 10, marginTop: 1 }} />
                  </>)
                : (
                  <Flex row jb>
                    <Text value='Tempo Lavorato:' bold style={{ width: 140 }} />
                    <Text value={formatMilliseconds(timeSum)} style={{ width: 100 }} />
                  </Flex>)}
            </Flex>
            {hideChat && <FormHeader conversationId={conversationId} />}
          </Flex>}
        <ClientSection client={[client, setClient]} agency={[agency, setAgency]} checkError={checkError} readOnly={readOnly} viewOnly={viewOnly} />
        <SenderSection
          sender={[sender, changeUserEdit(setSender)]} checkError={checkError}
          prefix={[prefix, setPrefix]} cellulare={[cellulare, setCellulare]} readOnly={readOnly}
        />
        <HoursDates createdAt={conversation.createdAt} />
        <MainDataSection
          {...stateProps}
          title={[title, setTitle]}
          resume={[resume, setResume]}
          noteModalVisible={[noteModalVisible, setNoteModalVisible]}
          onClickModal={saveAfterModal}
        />
        <Events {...stateProps} />
        <ConfirmModal header='Conferma Cliente' label='Il cliente non è presente, lo vuoi inserire?' onConfirm={handleNewClient} visible={[modalVisible, setModalVisible]} />
        <ConfirmModal
          header='Cancellazione conversazione' label='Vuoi cancellare la conversazione?'
          onConfirm={async () => {
            const { whatsappPhoneNumber } = conversation
            await deleteConversation(conversationId)
            if (!whatsappPhoneNumber) navigate('/H24/list')
            else if (whatsappPhoneNumber?.environmentId === gattinoniId) navigate('/gattinoniWA/list')
            else navigate('/H24WA/list')
          }}
          visible={[deleteModalVisible, setDeleteModalVisible]}
        />
        {conversation?.status !== 'ended' && conversation?.status !== 'archived' && !viewOnly
          ? (
            <Flex fw row wrap style={{ width: 250, marginRight: 20, alignItems: 'center', marginTop: 20 }}>
              <Checkbox checked={conversation.status === 'customerCompleted'} onChange={e => conclusoClienteConversation(e)} />
              <Flex as fh style={{ marginLeft: 10 }}>
                <PanelText value='Completata per il cliente' header />
              </Flex>
            </Flex>
            )
          : null}
        {!viewOnly &&
          <Flex wrap row style={{ marginTop: 20, gap: 20 }}>
            <Button
              icon='fa-duotone fa-arrow-left' label='Torna indietro' onClick={() => {
                if (!conversation?.whatsappPhoneNumber) navigate('/H24/list')
                else if (conversation?.whatsappPhoneNumber?.environmentId === gattinoniId) navigate('/gattinoniWA/list')
                else navigate('/H24WA/list')
              }}
            />
            <Button icon='fa-duotone fa-floppy-disk' label='Salva' onClick={updateState} disabled={readOnly} />
            {conversation?.status !== 'ended' && conversation?.status !== 'archived' &&
              <Button
                icon='fa-duotone fa-xmark' label='Concludi' onClick={generateAIResume}
                disabled={readOnly} spinner={endConversationLoading}
              />}
            {(role === 'admin' || role === 'gestoreH24') &&
              <Button
                icon='fa-duotone fa-xmark' onClick={archiveConversation}
                label={conversation?.status === 'archived' ? 'Rimuovi da archiviazione' : 'Archivia'}
                disabled={conversation?.status !== 'ended' && conversation?.status !== 'archived'}
              />}
            {(role === 'admin' || role === 'gestoreH24') &&
              <Button
                icon='fa-duotone fa-trash' onClick={() => setDeleteModalVisible(true)}
                label='Elimina' disabled={readOnly}
              />}
          </Flex>}
      </Flex>
      {!hideChat &&
        <Flex style={{ width: '39%', marginLeft: '1%', position: 'sticky' }}>
          <FormHeader conversationId={conversationId} />
          <FormChat stateRef={stateRef} readOnly={readOnly} />
        </Flex>}
    </Flex>
  )
}
