import { useEffect, useState } from 'react'
import { FC, invalidateQuery, useCQuery } from '../../../../Services'

export const useClient = (clientId) => {
  const [client, setClient] = useState()
  const { data, isSuccess } = useCQuery(['client', clientId])

  useEffect(() => {
    isSuccess && setClient(data)
  }, [isSuccess, data])

  const setClientProp = (prop, value) =>
    setClient({ ...client, [prop]: value })

  return [client, setClientProp, isSuccess]
}

export const saveClient = async (client) => {
  const requiredFields = ['ragSociale']
  for (const field of requiredFields) {
    if (!client?.[field]) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Inserire campi obbligatori' })
      return false
    }
  }

  if (client.id === 'new') {
    delete client.id
    const res = await FC.service('clients').create(client)
    invalidateQuery(['client', 'clients'])
    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
    return res
  }
  const res = await FC.service('clients').patch(client.id, client)
  invalidateQuery(['client', 'clients'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
  return res
}

export const deleteClient = async (clientId) => {
  const res = await FC.service('clients').remove(clientId)
  invalidateQuery(['client', 'clients'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Eliminazione effettuata' })
  return res
}
