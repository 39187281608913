import { useEffect, useState } from 'react'
import { useTransferField } from './Hook'
import { Flex } from '../../../../Components'
import { FloatInput } from './InputForm'

export const TransferField = ({ state: [state, setState], client, item, count, count2 = null, readOnly, diffStatus, width }) => {
  const { location, type, label, id, options, notRequired = false, minDate, extraField, filter = false } = item
  const [data = '', setData] = useTransferField([state, setState], location, count, count2)
  const [error, setError] = useState(false)

  useEffect(() => {
    const newError = !data && !notRequired
    newError !== error && setError(newError)
  }, [data, notRequired])

  if (extraField && (!client?.id || !client?.extraFields?.[id])) {
    if (data) setData(undefined)
    return null
  }

  return (
    <Flex key={item.id} as js style={{ width: width || '23%', margin: '0px 1%' }}>
      <FloatInput
        value={[data, setData]} id={count + id} label={label.it} type={type} options={options}
        error={error} readOnly={readOnly} minDate={minDate} diffStatus={diffStatus} filter={filter}
      />
    </Flex>
  )
}
