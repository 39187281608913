import React, { useState } from 'react'
import { AutoCompleteCustom } from '../../../Common/CommonComponents'
import { Card, Flex } from '../../../../Components'
import { FC } from '../../../../Services'
import { FloatInput } from '../TransferField/InputForm'
import _ from 'lodash'
import { Text } from '../../../../Components/Common/Text'
import { Button } from '../../../../Components/Common/Button'
import { Modal } from '../../../../Components/Common/Modal'
import { ClientActivities } from '../ClientActivities'

const officeIDs = [
  { key: 'amadeusOfficeId', label: 'Amadeus Office Id' },
  { key: 'sabreOfficeId', label: 'Sabre Office Id' },
  { key: 'galileoOfficeId', label: 'Galileo Office Id' }
]

export const ClientSection = ({ client: [client, setClient], agency: [agency, setAgency], checkError, readOnly, viewOnly }) => {
  const [suggestionsCod, setSuggestionsCod] = useState([])
  const [suggestionsDesc, setSuggestionsDesc] = useState([])
  const [suggestionsAgencyCod, setSuggestionsAgencyCod] = useState([])
  const [suggestionsAgencyDesc, setSuggestionsAgencyDesc] = useState([])
  const [modalVisible, setModalVisible] = useState(false)

  const debounceSearchAgencyCod = _.debounce(async (event) => {
    const value = event.query.trim()
    if (!value.length) return setSuggestionsAgencyCod([])
    const sugg = await FC.service('environments').find({ query: { codClienteNerone: { $like: '%' + value + '%' } } })
    setSuggestionsAgencyCod(sugg.map(s => ({ ...s, codiceNome: s.codClienteNerone + ' - ' + s.agencyName }))
    )
  }, 500)

  const debounceSearchAgencyDesc = _.debounce(async (event) => {
    const value = event.query.trim()
    if (value.length < 2) return setSuggestionsAgencyDesc([])
    const sugg = await FC.service('environments').find({ query: { agencyName: { $like: '%' + value + '%' } } })
    setSuggestionsAgencyDesc(sugg)
  }, 500)

  const debounceSearchCod = _.debounce(async (event) => {
    const value = event.query.trim()
    if (value.length < 2) return setSuggestionsCod([])
    const sugg = await FC.service('clients').find({ query: { codCliente: { $like: '%' + value + '%' }, includeEnvironments: true } })
    setSuggestionsCod(sugg?.map(s => ({ ...s, codiceNomeLabel: s.codCliente + ' - ' + s.ragSociale + ' (' + s?.environment?.agencyName + ')' })) || [])
  }, 500)

  const debounceSearchDescrizione = _.debounce(async (event) => {
    const value = event.query.trim()
    if (value.length < 2) return setSuggestionsDesc([])
    const suggStartWith = await FC.service('clients').find({ query: { ragSociale: { $like: value + '%' }, includeEnvironments: true } })
    const suggContains = await FC.service('clients').find({ query: { ragSociale: { $like: '%' + value + '%' }, includeEnvironments: true } })
    const sugg = _.uniqBy([...suggStartWith, ...suggContains], 'id')
    const nameAgency = (s) => s?.environment?.agencyName || 'Non Definito ⚠️'
    setSuggestionsDesc(sugg?.map(s => ({ ...s, ragSocialeLabel: s.ragSociale + ' (' + nameAgency(s) + ')' })) || [])
  }, 500)

  const onSelectClient = (value) => {
    setClient(value)
    const { environment } = value
    setAgency(environment)
  }

  const onSelectAgency = (value) => {
    setAgency(value)
    if (value.id !== client?.environment?.id) setClient({ ...client, id: null, environment: null })
  }

  const agencyOnChange = (key, value) => {
    setAgency({ [key]: value })
    setClient({ ...client, id: null, environment: null })
  }

  return (
    <Card
      customHeader={(
        <Flex fw jb row>
          <Text title bold value='DATI AZIENDA' style={{ marginRight: 20, marginBottom: 10 }} size={20} />
          {client.id && !viewOnly &&
            <Button
              label="Cerca attività dell'azienda" icon='fa-duotone fa-search'
              onClick={() => setModalVisible(true)}
            />}
        </Flex>
        )}
      style={{ width: '100%' }}
    >
      <Modal
        style={{ width: '95vw', height: '95vh' }} visible={modalVisible} header={'Attività di ' + client?.ragSociale}
        onHide={() => setModalVisible(false)}
      >
        <ClientActivities clientId={client.id} />
      </Modal>
      <Flex fw wrap row>
        <Flex as js style={{ width: '48%', margin: '0px 1%' }}>
          <AutoCompleteCustom
            inputId='ragSociale' label='Ragione Sociale' value={client.ragSociale}
            field='ragSocialeLabel' suggestions={suggestionsDesc} completeMethod={debounceSearchDescrizione}
            onChange={(value) => setClient({ ...client, id: null, ragSociale: value })}
            onSelect={onSelectClient} error={checkError && !client.ragSociale} readOnly={readOnly}
          />
        </Flex>
        <Flex as js style={{ width: '48%', margin: '0px 1%' }}>
          <AutoCompleteCustom
            inputId='codCliente' label='Codice Cliente' value={client.codCliente}
            field='codiceNomeLabel' suggestions={suggestionsCod} completeMethod={debounceSearchCod}
            onChange={(value) => setClient({ ...client, id: null, codCliente: value })} readOnly={readOnly}
            onSelect={onSelectClient}
          />
        </Flex>
        <Flex as js style={{ width: '98%', margin: '0px 1%' }}>
          <FloatInput
            value={[client?.pagamento || '', (data) => setClient({ ...client, pagamento: data })]}
            id='pagamento' label='Pagamento' readOnly={!!client.id}
          />
        </Flex>
        <Flex as js style={{ width: '98%', margin: '0px 1%' }}>
          <FloatInput
            value={[client?.info || '', (data) => setClient({ ...client, info: data })]}
            id='info' label='Info' readOnly={!!client.id} alert
          />
        </Flex>
        <Flex as js style={{ width: '48%', margin: '0px 1%' }}>
          <AutoCompleteCustom
            inputId='agencyName' label='Ragione Sociale Agenzia' value={agency.agencyName} forceSelection
            field='agencyName' suggestions={suggestionsAgencyDesc} completeMethod={debounceSearchAgencyDesc}
            onChange={(value) => agencyOnChange('agencyName', value)} readOnly={client.id}
            onSelect={onSelectAgency} error={checkError && !agency.agencyName}
          />
        </Flex>
        <Flex as js style={{ width: '48%', margin: '0px 1%' }}>
          <AutoCompleteCustom
            inputId='codClienteNerone' label='Codice Nerone Agenzia' value={agency.codClienteNerone} forceSelection
            field='codiceNome' suggestions={suggestionsAgencyCod} completeMethod={debounceSearchAgencyCod}
            onChange={(value) => agencyOnChange('codClienteNerone', value)} readOnly={client.id}
            onSelect={onSelectAgency}
          />
        </Flex>
        {officeIDs
          ?.filter(({ key }) => agency?.[key])
          ?.map(({ key, label }) => (
            <Flex
              as js style={{
                width: ((100 / officeIDs.filter(({ key }) => agency?.[key]).length) - 2).toFixed(1) + '%',
                margin: '0px 1%'
              }} key={key}
            >
              <FloatInput value={[agency?.[key] || '', () => {}]} id={key} label={label} readOnly />
            </Flex>
          ))}
      </Flex>
    </Card>
  )
}
