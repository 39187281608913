import React from 'react'
import { cap } from '../../State'
import ReactJson from 'react-json-view'

export const JsonViewer = ({ style, ...props }) => {
  const [darkMode] = cap.darkMode.useState()
  const { DEFAULT_THEME, SINGLE_THEME } = window._env_

  let theme
  if (SINGLE_THEME === 'true') {
    theme = DEFAULT_THEME === 'light' ? 'rjv-default' : 'monokai'
  } else {
    theme = darkMode ? 'monokai' : 'rjv-default'
  }

  return (
    <ReactJson
      onAdd={false} displayObjectSize={false} displayDataTypes={false}
      onEdit={false} onDelete={false} collapsed
      style={{ ...style, overflow: 'auto', backgroundColor: 'transparent' }}
      theme={theme}
      {...props}
    />
  )
}
