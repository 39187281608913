import React, { useState, useEffect } from 'react'
import { Flex } from '../../Components/Common/Flex'
import { Text } from '../../Components/Common/Text'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useCInfiniteQuery } from '../../Services/QueryCache'
import { useNavigate } from 'react-router-dom'
import { formatDate, formatHour, formatMilliseconds } from '../StatisticheOperatori/Functions'
import { Calendar } from 'primereact/calendar'
import { cap } from '../../State'
import { columnProps } from '../../Services/Functions'
import { Button } from '../../Components/Common/Button'
import { Card } from '../../Components/Common/Card'
import { Input } from '../../Components/Common/Input'
import { Checkbox } from 'primereact/checkbox'
import { FC } from '../../Services/FeathersClient'

const defaultDataRange = [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()]

export const StatisticheAziendeH24 = () => {
  const [conversationsTable, setConversationsTable] = useState([])
  const [datesSearch, setDatesSearch] = cap.datesSearchStats.useState()
  const [dateRange, setDateRange] = useState(defaultDataRange)
  const { data: conversations = [], isSuccess, isStale } = useCInfiniteQuery(['conversationsStats', dateRange])
  const [environmentsOptions, setEnvironmentsOptions] = useState([{ label: 'Tutte', value: true }])
  const navigate = useNavigate()
  const [selectedAgency, setSelectedAgency] = useState(true)
  const [groupByAgency, setGroupByAgency] = useState(false)
  const [emailXls, setEmailXls] = useState(process.env.NODE_ENV === 'development' ? 'atomatis@siapcn.it' : 'mbolla@siapcn.it')

  useEffect(() => {
    if (datesSearch?.every((d) => d instanceof Date)) setDateRange([datesSearch[0], datesSearch[1]])
  }, [datesSearch])

  useEffect(() => {
    if (isSuccess && !isStale) {
      const formattedConversations = conversations.map((r) => {
        const createdAt = new Date(r.createdAt)
        return {
          ...r,
          ragSociale: r?.traveller?.client?.ragSociale || 'Non definito',
          dateHour: formatDate(createdAt) + ' ' + formatHour(createdAt),
          traveller: (r?.travellerSurname || '') + ' ' + (r?.travellerName || '').trim() || 'Non definito'
        }
      })
      setConversationsTable(formattedConversations)
      const uniqueEnvironments = [
        { label: 'Tutte', value: true },
        ...Array.from(new Set(conversations.map((r) => JSON.stringify({ label: r.ragSocialeAgency, value: r.environmentId }))))
          .map(JSON.parse)
      ]
      setEnvironmentsOptions(uniqueEnvironments)
    }
  }, [JSON.stringify(conversations), isSuccess])

  useEffect(() => {
    if (selectedAgency !== true) setGroupByAgency(false)
  }, [selectedAgency])

  const downloadStats = async () => {
    window.growl.show({ severity: 'info', summary: 'Invio in corso', detail: 'Invio in corso' })
    const res = await FC.service('info').create({ action: 'sendAgencyReport', email: emailXls, groupByAgency, selectedAgency, dateRange })
    if (res.ok) window.growl.show({ severity: 'success', summary: 'Email inviata', detail: 'Email inviata' })
  }

  const noConversationsFound = !conversationsTable.length && !isStale

  return (
    <Flex fw>
      <Text value='Statistiche Conversazioni' bold title size={20} style={{ marginBottom: 20 }} />
      <Flex style={{ width: '60%' }}>
        <Flex style={{ gap: 10, marginBottom: 20 }}>
          <Calendar
            value={datesSearch} selectionMode='range' onChange={(e) => setDatesSearch(e.value)}
            locale='it' dateFormat='dd/mm/yy' style={{ width: 250 }}
          />
        </Flex>
      </Flex>
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' className='TableSignatures'
        value={conversationsTable} emptyMessage='Nessuna attività trovata' responsiveLayout='scroll' paginator rows={10}
      >
        <Column field='ragSocialeAgency' {...columnProps('Agenzia')} />
        <Column field='ragSocialeClient' {...columnProps('Cliente')} />
        <Column field='dateHour' {...columnProps('Data e Ora Chiamata')} />
        <Column field='traveller' {...columnProps('Viaggiatore')} />
        <Column field='totalTime' {...columnProps('Tempo di lavoro')} body={(rowData) => formatMilliseconds(rowData?.totalTime || 0)} />
        <Column body={(rowData) => <Button icon='fa-duotone fa-pen' onClick={() => navigate(`/admin/operatore/${rowData.id}`)} label='Apri' style={{ width: 80 }} />} />
      </DataTable>
      <Flex style={{ marginTop: 20 }} fw>
        <Text value='Download Xls' bold title size={20} />
        <Card style={{ width: '100%', marginTop: 20 }}>
          <Text value="Seleziona l'agenzia oppure 'Tutte' per scaricare le statistiche di tutte le agenzie." style={{ marginLeft: 12 }} />
          <Text value='Seleziona "Raggruppa per Agenzia" per scaricare un file xls per ogni agenzia.' style={{ marginLeft: 12 }} />
          {
            noConversationsFound &&
              <Text value='Nessuna attività trovata per il periodo selezionato.' style={{ color: 'red', marginLeft: 12, marginTop: 10 }} />
          }
          <Flex fw js row style={{ marginTop: 20 }} wrap>
            <Input
              dropdown options={environmentsOptions} label='Agenzia' style={{ width: 300 }}
              value={selectedAgency} id='agency' onChange={(e) => setSelectedAgency(e.agency)}
            />
            <Text value='File diverso per ogni Agenzia' style={{ marginLeft: 20 }} />
            <Checkbox checked={groupByAgency} onChange={(e) => setGroupByAgency(e.checked)} style={{ marginLeft: 10 }} disabled={selectedAgency !== true} />
            <Input label='Email' style={{ marginLeft: 20, width: 350 }} value={emailXls} onChange={(e) => setEmailXls(e.email)} id='email' />
            <Button
              label='Invia Report Email' icon='fa-duotone fa-envelope' style={{ marginLeft: 20 }}
              onClick={downloadStats} disabled={!selectedAgency || !emailXls?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) || noConversationsFound}
            />
          </Flex>
        </Card>
      </Flex>
    </Flex>
  )
}
