import { useEffect, useState } from 'react'
import { FC, invalidateQuery, useCQuery } from '../../../../Services'

export const useAgency = (agencyId) => {
  const [agency, setAgency] = useState()
  const { data, isSuccess } = useCQuery(['environment', agencyId])

  useEffect(() => {
    isSuccess && setAgency(data)
  }, [isSuccess, data])

  const setAgencyProp = (prop, value) =>
    setAgency({ ...agency, [prop]: value })

  return [agency, setAgencyProp, isSuccess]
}

/**
 * Salva le informazioni dell'agenzia dopo aver verificato che tutti i campi obbligatori siano presenti
 * e assicurandosi che non ci siano duplicati. In caso di id 'new', viene creato un nuovo record,
 * altrimenti l'agenzia esistente viene aggiornata.
 *
 * @async
 * @function saveAgency
 * @param {Object} agency - Oggetto contenente i dati dell'agenzia da salvare.
 * @returns {Promise<Object|boolean>} Il risultato del salvataggio se riuscito, `false` in caso contrario.
 */
export const saveAgency = async (agency) => {
  const requiredFields = ['agencyName', 'codClienteNerone']
  for (const field of requiredFields) {
    if (!agency?.[field]) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Inserire campi obbligatori' })
      return false
    }
  }

  if (agency.id === 'new') {
    delete agency.id
    const res = await FC.service('environments').create(agency)
    invalidateQuery(['environment', 'environments'])
    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
    return res
  }
  const res = await FC.service('environments').patch(agency.id, agency)
  invalidateQuery(['environment', 'environments'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
  return res
}

/**
 * Rimuove un'agenzia dato il suo identificativo univoco.
 *
 * @async
 * @function deleteAgency
 * @param {string} agencyId - L'ID dell'agenzia da eliminare.
 * @returns {Promise<Object>} Il risultato della chiamata al servizio di rimozione.
 * @throws {Error} Può lanciare un errore se la chiamata al servizio non riesce.
 * @description La funzione esegue asincronicamente la rimozione dell'agenzia.
 * Dopo la rimozione, invalida la cache della query relativa alle agenzie e mostra
 * un messaggio di conferma dell'operazione completata con successo.
 */
export const deleteAgency = async (agencyId) => {
  const res = await FC.service('environments').remove(agencyId)
  invalidateQuery(['environment', 'environments'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Eliminazione effettuata' })
  return res
}
