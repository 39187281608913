import React from 'react'
import { Flex, Input, PanelText } from '../../../Components'

const listOfFormalita = [
  { label: 'poco formale', value: 30 },
  { label: 'formale (default)', value: 75 },
  { label: 'molto formale', value: 100 }
]

const listOfEmails = [
  { label: 'mranocchia@siapcn.it', value: 'mranocchia@siapcn.it' },
  { label: 'murru@siapcn.it', value: 'murru@siapcn.it' },
  { label: 'atomatis@siapcn.it', value: 'atomatis@siapcn.it' },
  { label: 'mbolla@siapcn.it', value: 'mbolla@siapcn.it' }
]

const processes = ['newMessageAI', 'newMessageGattinoniSingleUnit']

const SettingsParameterTest = ({
  selectedFormalita: [selectedFormalita, setSelectedFormalita],
  selectedEmail: [selectedEmail, setSelectedEmail],
  selectedProcess: [selectedProcess, setSelectedProcess]
}) => (
  <Flex fw row wrap style={{ gap: 30 }}>
    <Flex as>
      <PanelText value='Indicare livello di formalità' />
      <Input
        id='levelFormalita' dropdown options={listOfFormalita} value={selectedFormalita} placeholder='Seleziona livello di formalità'
        onChange={({ levelFormalita }) => setSelectedFormalita(levelFormalita)}
      />
    </Flex>
    <Flex js as>
      <PanelText value='Seleziona Email Redirect To Operator' />
      <Input
        id='unit' dropdown options={listOfEmails} value={selectedEmail} placeholder='Seleziona Email'
        style={{ fontSize: 12, width: 300, textAlign: 'center' }} onChange={({ unit }) => setSelectedEmail(unit)}
      />
    </Flex>
    <Flex js as>
      <PanelText value='Seleziona Processo' />
      <Input
        id='process' dropdown options={processes} value={selectedProcess} placeholder='Seleziona Processo'
        style={{ fontSize: 12, width: 300, textAlign: 'center' }} onChange={({ process }) => setSelectedProcess(process)}
      />
    </Flex>
  </Flex>
)

export default SettingsParameterTest
