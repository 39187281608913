/**
 * Determina il colore da associare in base allo stato della differenza e alla presenza di errori.
 *
 * @param {string} diffStatus - Lo stato della differenza, le opzioni sono 'new', 'changed', o altri.
 * @param {boolean} error - Indica se è presente un errore.
 * @returns {string|null} - Restituisce una stringa rappresentante il colore ('green', 'orange', 'red')
 *                          oppure null se lo stato non corrisponde a quelli specificati e non c'è errore.
 */
export const getColorDiff = (diffStatus, error) => {
  if (diffStatus === 'new') return 'green'
  if (diffStatus === 'changed') return 'orange'
  if (error) return 'red'
  return null
}
