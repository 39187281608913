import React from 'react'
import { Flex } from '../../Components/Common/Flex'
import { GigiForm } from '../Gigi/GigiForm'
import { TimeView } from './TimeView'
import { useParams } from 'react-router-dom'

export const ConversationStats = () => {
  const { conversationId } = useParams()
  return (
    <Flex fw>
      <GigiForm />
      <div style={{ height: 50 }} />
      <TimeView conversationId={conversationId} />
    </Flex>
  )
}
