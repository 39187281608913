import { useEffect, useState } from 'react'
import { FC, invalidateQuery, useCQuery } from '../../../Services'

export const useAgency = (agencyId) => {
  const [agency, setAgency] = useState()
  const { data, isSuccess } = useCQuery(['environment', agencyId])

  useEffect(() => {
    isSuccess && setAgency(data)
  }, [isSuccess, data])

  const setAgencyProp = (prop, value) =>
    setAgency({ ...agency, [prop]: value })

  return [agency, setAgencyProp, isSuccess]
}

/**
 * Salva un'agenzia sul server.
 * Se l'ID dell'agenzia è 'new', l'agenzia viene creata, altrimenti viene aggiornata.
 *
 * @param {Object} agency - L'oggetto agenzia che deve essere salvato o aggiornato.
 * @returns {Promise<Object>} La risposta del server dopo il salvataggio o l'aggiornamento dell'agenzia.
 */
export const saveAgency = async (agency) => {
  if (agency.id === 'new') {
    delete agency.id
    const res = await FC.service('environments').create(agency)
    invalidateQuery(['environment', 'environments'])
    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
    return res
  }
  const res = await FC.service('environments').patch(agency.id, agency)
  invalidateQuery(['environment', 'environments'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
  return res
}

/**
 * Elimina un'agenzia in base all'ID fornito.
 *
 * Chiama il servizio 'environments' per rimuovere l'agenzia specificata. Dopo la
 * rimozione, invalida la query relativa agli ambienti e mostra un messaggio di
 * notifica di successo attraverso `window.growl.show`.
 *
 * @param {string} agencyId - L'ID dell'agenzia da eliminare.
 * @returns La risposta del servizio dopo aver tentato di eliminare l'agenzia.
 */
export const deleteAgency = async (agencyId) => {
  const res = await FC.service('environments').remove(agencyId)
  invalidateQuery(['environment', 'environments'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Eliminazione effettuata' })
  return res
}

export const reexecuteUnit = (unit, initialState) => FC.service('public').create({ ...initialState, type: 'testUnit', unit, isDebug: true })
