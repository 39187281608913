import { prefetchInfiniteQuery } from './QueryCache'
import { cap } from '../State'

export const getUrlParams = (params) => {
  if (!params) return undefined
  const url = new URL(window.location.href)
  const search = Array.isArray(params) ? params : [params]
  const found = search.map((p) => url.searchParams.get(p))
  return found.length === 1 ? found[0] : found
}

export const padZero = (num) => num.toString().padStart(2, '0')

export const getDateObj = (dateStr) => {
  const [day, month, year] = dateStr.split('/')
  return new Date(year, month - 1, day)
}

export const getDateString = (dateObj) => {
  const day = padZero(dateObj.getDate())
  const month = padZero(dateObj.getMonth() + 1)
  const year = dateObj.getFullYear()
  return `${day}/${month}/${year}`
}

export const formatEuro3Decimals = (value) => {
  if (!value) return ''
  const firstDecimal = value?.toString()?.split('.')[1]?.split('')?.findIndex((d) => d !== '0') || 0
  return value?.toFixed(Math.max(firstDecimal + 1, 3))?.replace('.', ',') + ' €'
}

export const truncateText = (str, num) => {
  if (str.length > num) {
    return str.slice(0, num) + '...'
  }
  return str
}

export const columnProps = (filterPlaceholder) => ({
  header: filterPlaceholder,
  headerStyle: { visibility: 'hidden' },
  filter: true,
  filterMatchMode: 'contains',
  filterPlaceholder,
  style: { textAlign: 'center', border: 'none' },
  showFilterMenu: false,
  showClearButton: false
})

export const prefetchConversations = () =>
  prefetchInfiniteQuery(['conversations', cap.assignedFilter.state, cap.selectedFilter.state, { $in: [true, false] }])

// get conversations react query arguments by the conversation
export const getConversationsQueryArguments = (conversation) => {
  let queryToReturn = []
  const selectedFilter = cap.selectedFilter.state
  const range1 = cap.range.state[0].toLocaleDateString('it-IT')
  const range2 = cap.range.state[1].toLocaleDateString('it-IT')
  const assignedFilter = cap.assignedFilter.state
  const customAssignedFilter = cap.customAssignedFilter.state
  if (!conversation) {
    queryToReturn = [
      ['conversations', assignedFilter, selectedFilter, { $in: [true, false] }, [range1, range2]],
      ...(customAssignedFilter !== assignedFilter
        ? [['conversations', customAssignedFilter, selectedFilter, { $in: [true, false] }, [range1, range2]]]
        : [])
    ]
  } else if (!conversation?.whatsappPhoneNumberId) queryToReturn = [['conversations', customAssignedFilter, selectedFilter, { $in: [true, false] }, [range1, range2]]]
  else queryToReturn = [['conversations', assignedFilter, selectedFilter, { $in: [true, false] }, [range1, range2]]]
  // foreach query return the option with hasMessages = true and hasMessages = false
  return queryToReturn.flatMap(query => [
    [...query, null, true],
    [...query, null, false]
  ])
}

export const handleMessageGrowl = (message) => {
  const { user: { role = '' } = {} } = JSON.parse(window.localStorage.getItem('user')) || {}
  if (message?.sender === 'traveller' && !window.location.pathname.includes('operatore/' + message?.conversationId) && role !== 'operatoreServizi') {
    window.growl.show({ severity: 'info', summary: 'Nuovo messaggio', detail: 'Nuovo messaggio WhatsApp!' })
  }
}
