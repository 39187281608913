import { useEffect, useState } from 'react'
import { FC, invalidateQuery, useCQuery } from '../../Services'

export const useAttendance = (attendanceId) => {
  const [attendance, setAttendance] = useState()
  const { data, isSuccess } = useCQuery(['attendance', attendanceId])

  useEffect(() => {
    isSuccess && setAttendance(data)
  }, [isSuccess, data])

  const setAttendanceProp = (prop, value) =>
    setAttendance({ ...attendance, [prop]: value })

  return [{
    ...attendance,
    startDate: attendance?.startDate || new Date().setSeconds(0, 0),
    endDate: attendance?.endDate || new Date().setSeconds(0, 0),
    time: attendance?.time === undefined ? new Date().getHours() + ':' + new Date().getMinutes().toString().padStart(2, '0') : attendance?.time
  }, setAttendanceProp, isSuccess]
}

export const saveAttendance = async (attendance) => {
  if (!attendance.startDate || !attendance.endDate) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Inserire campi obbligatori' })
    return false
  }
  if (attendance.id === 'new') {
    delete attendance.id
    try {
      const res = await FC.service('attendances').create({ ...attendance })
      invalidateQuery(['attendance', 'attendances'])
      window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
      return res
    } catch (e) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore Salvataggio' })
      return false
    }
  }
  try {
    const res = await FC.service('attendances').patch(attendance.id, attendance)
    invalidateQuery(['attendance', 'attendances'])
    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
    return res
  } catch (e) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore Salvataggio' })
    return false
  }
}

export const deleteAttendance = async (attendanceId) => {
  const res = await FC.service('attendances').remove(attendanceId)
  invalidateQuery(['attendance', 'attendances'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Eliminazione effettuata' })
  return res
}
