import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { AutoComplete } from 'primereact/autocomplete'
import { useState } from 'react'
import { getColorDiff } from './Functions'
import { InputTextarea } from 'primereact/inputtextarea'
import { formattingTime } from '../../../../Components/Common/Functions'

const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
const fontStyle = { fontFamily: 'Lexend', fontSize: 14 }

export const FloatInput = ({ value: [value, setValue], noMarginTop, boldTitle, alert, label, id, type, options, error, dropdown, forceSelection = false, autocompleteField = 'label', onSelect, readOnly, minDate, diffStatus, filter }) => {
  const [suggestions, setSuggestions] = useState([])

  const search = (event) => {
    if (!event.query.trim().length) setSuggestions([...options])
    else setSuggestions(options.filter(({ [autocompleteField]: label }) => label.toLowerCase().includes(event.query.toLowerCase())))
  }

  const className = error ? 'p-invalid' : ''

  const renderCorrectInput = () => {
    switch (type) {
      case 'dateObj':
        return (
          <Calendar
            disabled={readOnly} value={value}
            onChange={(e) => setValue(e.value)} dateFormat='dd/mm/yy'
            id={id} className={className} inputStyle={{ ...fontStyle, color: getColorDiff(diffStatus, error) }}
            style={{ width: '100%' }} {...minDate && { minDate }}
          />
        )
      case 'date':
        return (
          <Calendar
            disabled={readOnly} value={value && new Date(value.split('/')[2], value.split('/')[1] - 1, value.split('/')[0])}
            onChange={(e) => setValue(e.value?.toLocaleDateString('it-IT', dateOptions))} dateFormat='dd/mm/yy'
            id={id} className={className} inputStyle={{ ...fontStyle, color: getColorDiff(diffStatus, error) }}
            style={{ width: '100%' }} {...minDate && { minDate }}
          />
        )
      case 'dropdown':
        return (
          <Dropdown
            style={{ width: '100%' }} id={id} filter={filter} className={[className, getColorDiff(diffStatus, error)].join(' ')}
            disabled={readOnly} value={value} onChange={(e) => !readOnly && setValue(e.value)} options={options}
          />
        )
      case 'time':
        return (
          <InputText
            style={{ ...fontStyle, width: '100%', color: getColorDiff(diffStatus, error) }}
            id={id} onChange={(e) => setValue(e.target.value)} onBlur={(e) => setValue(formattingTime(e.target.value))}
            value={value} className={error ? 'p-invalid' : ''} disabled={readOnly}
          />
        )
      case 'autocomplete':
        return (
          <AutoComplete
            {...dropdown && { dropdown: true }} forceSelection={forceSelection}
            disabled={readOnly} field={autocompleteField} className={className} id={id} onSelect={(e) => onSelect(e.value)} style={{ width: '100%' }}
            value={value} suggestions={suggestions} completeMethod={search} onChange={(e) => setValue(e.value)} inputStyle={{ ...fontStyle, width: '100%', color: getColorDiff(diffStatus, error) }}
          />
        )
      case 'numeric': {
        return (
          <InputText
            style={{ ...fontStyle, width: '100%', color: getColorDiff(diffStatus, error) }} id={id} onChange={(e) => setValue(e.target.value)}
            value={value} className={className} disabled={readOnly} keyfilter='int'
          />
        )
      }
      case 'multiline':
        return (
          <InputTextarea
            style={{ ...fontStyle, width: '100%', color: getColorDiff(diffStatus, error), marginBottom: -5 }} id={id} onChange={(e) => setValue(e.target.value)}
            value={value} className={className} disabled={readOnly} autoResize
          />
        )
      default:
        return (
          <InputText
            style={{ ...fontStyle, width: '100%', ...(alert ? { backgroundColor: '#EE0000' } : {}) }}
            id={id} onChange={(e) => setValue(e.target.value)}
            value={value} className={className} disabled={readOnly}
          />
        )
    }
  }

  return (
    <div className='card flex justify-content-center' style={{ marginTop: noMarginTop ? 0 : 25, width: '100%' }}>
      <span className='p-float-label'>
        {renderCorrectInput()}
        <label style={{ fontFamily: 'Lexend', color: getColorDiff(diffStatus, error), ...(boldTitle && { fontWeight: 'bold', fontSize: 18 }) }}>{label}
        </label>
      </span>
    </div>
  )
}
