import React, { useEffect } from 'react'
import { Flex, Spinner } from '../../../Components'
import { useCQuery } from '../../../Services'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from './Form'
import { useAuth } from '../../../Provider/AuthProvider'
import { FC } from '../../../Services/FeathersClient'

export const minimumSecondsForSave = 10

export const GigiForm = () => {
  const { conversationId } = useParams()
  const { isSuccess, isError } = useCQuery(['conversation', conversationId])
  const navigate = useNavigate()

  useEffect(() => {
    if (isError) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Conversazione non trovata' })
      navigate('/H24/list')
    }
  }, [isError])

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw as row wrap>
      <Form />
      <TimeCounter />
    </Flex>
  )
}

const TimeCounter = () => {
  const { conversationId } = useParams()
  const { user = {} } = useAuth()
  const { user: userObj = {} } = user || {}
  const { id } = userObj

  useEffect(() => {
    const startDate = new Date()

    const saveTime = () => {
      if (userObj?.role === 'admin' && process.env.NODE_ENV !== 'development') return
      if ((new Date() - startDate) / 1000 < minimumSecondsForSave) return
      FC.service('time').create({ userId: id, conversationId, createdAt: startDate, endAt: new Date(), time: new Date() - startDate })
    }

    window.onbeforeunload = saveTime
    return () => {
      saveTime()
      window.onbeforeunload = null
    }
  }, [])

  return null
}
