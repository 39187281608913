import { Button, Flex, Input, Text } from '../../../../Components'

export const ImportTutorial = () => (
  <Flex>
    <Text value="Effettua l'upload di un file CSV contentente i viaggiatori da aggiungere." size={16} style={{ marginTop: 20 }} />
    <Text value='Il file CSV deve essere così strutturato: ' size={16} />
    <Text value='Nella prima riga di intestazione devono essere inseriti i campi pid, email, cellulare, codice cliente.' size={16} />
    <Text value='Dalla seconda riga devono essere inseriti i dati dei vari clienti.' size={16} />
    <Flex row>
      <Text value='Ecco un file di esempio da editare e caricare.' size={16} />
      <Button style={{ marginLeft: 10 }} label='Scarica' icon='fa-duotone fa-cloud-arrow-down' onClick={() => window.location.assign('https://siap.cloud/Esempio%20Viaggiatori.csv')} />
    </Flex>
  </Flex>
)

export const TextEditor = ({ value, editorCallback }, isNumber) => (
  <Input
    id='txt' value={value}
    onChange={({ txt }) => {
      if (isNumber && /[a-zA-Z]/.test(txt)) { return }
      editorCallback(txt)
    }}
  />
)
