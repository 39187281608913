import React, { useEffect, useState } from 'react'
import { Flex } from '../../Components'
import { cap } from '../../State'
import { Calendar } from 'primereact/calendar'
import { Text } from '../../Components/Common/Text'
import { Input } from '../../Components/Common/Input'
import { Button } from '../../Components/Common/Button'
import { FC } from '../../Services/FeathersClient'
import { useAuth } from '../../Provider/AuthProvider'

const defaultDataRange = [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()]

export const StatisticheOperatori = () => {
  const [datesSearch, setDatesSearch] = cap.datesSearchStats.useState()
  const [dateRange, setDateRange] = useState(defaultDataRange)
  const { user: userObj = {} } = useAuth()
  const { user = {} } = userObj || {}
  const { email } = user || {}
  const [emailXls, setEmailXls] = useState(email || '')

  useEffect(() => {
    if (datesSearch?.every((d) => d instanceof Date)) setDateRange([datesSearch[0], datesSearch[1]])
  }, [datesSearch])

  const downloadFullReport = async () => {
    window.growl.show({ severity: 'info', summary: 'Invio in corso', detail: 'Invio in corso' })
    const res = await FC.service('info').create({ action: 'sendFullOperatorReport', email: emailXls, dateRange })
    if (res.ok) window.growl.show({ severity: 'success', summary: 'Email inviata', detail: 'Email inviata' })
  }

  return (
    <Flex fw wrap>
      <Text value='Statistiche Operatori' bold title size={20} style={{ marginBottom: 20 }} />
      <Flex fw row wrap>
        <Flex style={{ gap: 10 }}>
          <Calendar
            value={datesSearch} selectionMode='range' onChange={(e) => setDatesSearch(e.value)}
            locale='it' dateFormat='dd/mm/yy' style={{ width: 250 }}
          />
        </Flex>
        <Input label='Email' style={{ marginLeft: 20, width: 350 }} value={emailXls} onChange={(e) => setEmailXls(e.email)} id='email' />
        <Flex fw>
          <Button
            label='Invia Report con Presenze / Linea occupata / H24'
            onClick={downloadFullReport} disabled={!emailXls?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)}
            style={{ marginTop: 20 }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
