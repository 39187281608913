import React, { useState, useEffect } from 'react'
import { Flex } from '../../Components/Common/Flex'
import { Text } from '../../Components/Common/Text'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { JavascriptDateToYYYYMMDD, useCQuery } from '../../Services/QueryCache'
import { Calendar } from 'primereact/calendar'
import { cap } from '../../State'
import { Spinner } from '../../Components/Common/Spinner'
import { Input } from '../../Components/Common/Input'
import { Button } from '../../Components/Common/Button'
import { useAuth } from '../../Provider/AuthProvider'
import { FC } from '../../Services/FeathersClient'

const columns = [
  'Operatore',
  'Utente',
  'Protocollazione ft acquisto noleggio estere',
  'Segnalazione anomalia noleggio estero',
  'Tempo noleggio estero',
  'Media minuti per fattura noleggio estero',
  'Protocollazione ft acquisto noleggio italia',
  'Segnalazione anomalia noleggio italia',
  'Tempo noleggio italia',
  'Media minuti per fattura noleggio italia',
  'Protocollazione ft acquisto hotel italia',
  'Segnalazione anomalia hotel italia',
  'Impostazione ft come registrata',
  'Tempo hotel italia',
  'Tempo x ft senza anagrafica',
  'Media minuti per fattura hotel italia',
  'Protocollazione ft acquisto hotel estero',
  'Segnalazione anomalia hotel estero',
  'Tempo hotel estero',
  'Media minuti per fattura hotel estero',
  'Variazioni su voucher hotel x registrare ft',
  'Media segnalazione anomalia hotel',
  'Media segnalazione anomalia noleggio'
]

const columnsFattureHotel = [
  'Totale fatture hotel xml',
  'Totale fatture hotel passate a Nora',
  'Totale fattura hotel scartate prima di Nora',
  'Totale fatture hotel Nora trovato voucher',
  'Totale fatture hotel Nora non trovato voucher',
  '% ft hotel che nora ha trovato info sul totale ft che dobbiamo gestire',
  'Tot ft hotel reg automat',
  '% di ft hotel reg autom sul totale ricevuto da Nora con vch'
]

const columnsFattureNoleggi = [
  'Totale fatture xml noleggi',
  'Totale fatture noleggi passate a Nora',
  'Totale fatture noleggi scartate prima di Nora',
  'Totale fatture noleggi Nora trovato voucher',
  '% ft noleggi che nora ha trovato info sul totale ft che dobbiamo gestire',
  'Tot ft noleggi reg automat',
  '% di ft noleggi reg autom sul totale ricevuto da Nora con vch'
]

const defaultDataRange = [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()]

export const StatisticheBlueteam = () => {
  const [operatorStatsTable, setOperatorStatsTable] = useState([])
  const [fattureStatsTable, setFattureStatsTable] = useState([])
  const { user: userObj = {} } = useAuth()
  const { user = {} } = userObj || {}
  const { email } = user || {}
  const [emailXls, setEmailXls] = useState(email || '')
  const [datesSearch, setDatesSearch] = cap.datesSearchStats.useState()
  const [dateRange, setDateRange] = useState(defaultDataRange)
  const { data: { operatorStats, fattureStats } = [], isSuccess, isStale } = useCQuery(['getStatsBlueteam', dateRange])

  const downloadFullReport = async () => {
    window.growl.show({ severity: 'info', summary: 'Invio in corso', detail: 'Invio in corso' })
    const res = await FC.service('stats').get('getStatsBlueteamXlsx', { query: { email: emailXls, startDate: JavascriptDateToYYYYMMDD(dateRange[0]), endDate: JavascriptDateToYYYYMMDD(dateRange[1]) } })
    if (res.ok) window.growl.show({ severity: 'success', summary: 'Email inviata', detail: 'Email inviata' })
  }

  useEffect(() => {
    if (datesSearch?.every((d) => d instanceof Date)) setDateRange([datesSearch[0], datesSearch[1]])
  }, [datesSearch])

  useEffect(() => {
    if (isSuccess && !isStale) {
      setOperatorStatsTable(operatorStats.map((r) => ({
        ...r,
        'Media minuti per fattura noleggio estero': parseFloat(r['Media minuti per fattura noleggio estero']).toFixed(2),
        'Media minuti per fattura noleggio italia': parseFloat(r['Media minuti per fattura noleggio italia']).toFixed(2),
        'Media minuti per fattura hotel italia': parseFloat(r['Media minuti per fattura hotel italia']).toFixed(2),
        'Media minuti per fattura hotel estero': parseFloat(r['Media minuti per fattura hotel estero']).toFixed(2),
        'Media segnalazione anomalia hotel': r['Media segnalazione anomalia hotel'] ? parseFloat(r['Media segnalazione anomalia hotel']).toFixed(2) + '%' : '',
        'Media segnalazione anomalia noleggio': r['Media segnalazione anomalia noleggio'] ? parseFloat(r['Media segnalazione anomalia noleggio']).toFixed(2) + '%' : ''
      })))
      setFattureStatsTable(fattureStats?.map((r) => ({
        ...r,
        '% ft hotel che nora ha trovato info sul totale ft che dobbiamo gestire': r['% ft hotel che nora ha trovato info sul totale ft che dobbiamo gestire'] ? parseFloat(r['% ft hotel che nora ha trovato info sul totale ft che dobbiamo gestire'] * 100).toFixed(2) + '%' : '',
        '% di ft hotel reg autom sul totale ricevuto da Nora con vch': r['% di ft hotel reg autom sul totale ricevuto da Nora con vch'] ? parseFloat(r['% di ft hotel reg autom sul totale ricevuto da Nora con vch'] * 100).toFixed(2) + '%' : '',
        '% ft noleggi che nora ha trovato info sul totale ft che dobbiamo gestire': r['% ft noleggi che nora ha trovato info sul totale ft che dobbiamo gestire'] ? parseFloat(r['% ft noleggi che nora ha trovato info sul totale ft che dobbiamo gestire'] * 100).toFixed(2) + '%' : '',
        '% di ft noleggi reg autom sul totale ricevuto da Nora con vch': r['% di ft noleggi reg autom sul totale ricevuto da Nora con vch'] ? parseFloat(r['% di ft noleggi reg autom sul totale ricevuto da Nora con vch'] * 100).toFixed(2) + '%' : ''
      }))
      )
    }
  }, [JSON.stringify(operatorStats), JSON.stringify(fattureStats), isSuccess])

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw>
      <Text value='Statistiche Blueteam' bold title size={20} style={{ marginBottom: 20 }} />
      <Flex style={{ width: '60%' }}>
        <Flex style={{ gap: 10, marginBottom: 20 }}>
          <Calendar
            value={datesSearch} selectionMode='range' onChange={(e) => setDatesSearch(e.value)}
            locale='it' dateFormat='dd/mm/yy' style={{ width: 250 }}
          />
        </Flex>
      </Flex>
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' className='TableSignatures'
        value={operatorStatsTable} emptyMessage='Nessuna attività trovata' responsiveLayout='scroll' paginator rows={10}
      >
        {columns.map((column) => (
          <Column key={column} field={column} header={column} />
        ))}
      </DataTable>
      <Text value='Fatture Hotel' bold title size={20} style={{ marginBottom: 20, marginTop: 20 }} />
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' className='TableSignatures'
        value={fattureStatsTable} emptyMessage='Nessuna attività trovata' responsiveLayout='scroll' paginator rows={10}
      >
        {columnsFattureHotel.map((column) => (
          <Column key={column} field={column} header={column} />
        ))}
      </DataTable>
      <Text value='Fatture Noleggi' bold title size={20} style={{ marginBottom: 20, marginTop: 20 }} />
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' className='TableSignatures'
        value={fattureStatsTable} emptyMessage='Nessuna attività trovata' responsiveLayout='scroll' paginator rows={10}
      >
        {columnsFattureNoleggi.map((column) => (
          <Column key={column} field={column} header={column} />
        ))}
      </DataTable>
      <Input label='Email' style={{ marginTop: 20, width: 350 }} value={emailXls} onChange={(e) => setEmailXls(e.email)} id='email' />
      <Flex fw>
        <Button
          label='Invia Report'
          onClick={downloadFullReport}
          disabled={!emailXls?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)}
          style={{ marginTop: 20 }}
        />
      </Flex>
    </Flex>
  )
}
