import { FC } from '../../../../Services/FeathersClient'

/**
 * Restituisce lo stato della differenza per un dato elemento basato su un identificativo e una condizione di conteggio.
 *
 * @param {Object} param0 - Oggetto con la proprietà id dell'elemento da cercare.
 * @param {number} param0.id - L'ID dell'elemento per il quale si vuole ottenere lo stato della differenza.
 * @param {Array} diff - Array di elementi che contengono le proprietà delle differenze.
 * @param {boolean} [count=false] - Opzionale, se impostato a true, verifica anche la corrispondenza del conteggio.
 * @returns {string} Lo stato della differenza dell'elemento corrispondente o una stringa vuota se non trovato.
 */
export const getDiffStatus = ({ id }, diff, count = false) =>
  diff?.find((el) => el?.id === id && (count === false || count === el.count))?.diffStatus || ''

export const getEventsCount = (json) => json?.events?.length || 0

export const getPassengersCount = (json, i) => json?.events?.[i]?.passengers?.length || 0

export const getTotalPassengersCount = (json) => {
  let total = 0
  for (const event of json?.events || []) total += event?.passengers?.length || 0
  return total
}

export const removeEvent = (json, index) => {
  const newJson = JSON.parse(JSON.stringify(json))
  newJson.events.splice(index, 1)
  return newJson
}

export const removePassenger = (json, i, index) => {
  const newJson = JSON.parse(JSON.stringify(json))
  newJson.events[i].passengers.splice(index, 1)
  return newJson
}

export const addEvent = async (json) => {
  const newJson = JSON.parse(JSON.stringify(json))
  const jsonToPush = {}
  const formFields = await FC.service('info').get('formFields')
  for (const field of formFields.filter((field) => field.section === 'events')) jsonToPush[field.id] = ''
  jsonToPush.passengers = [{ nameSurname: '' }]
  if (!newJson?.events?.length) newJson.events = []
  newJson.events.push(jsonToPush)
  return newJson
}

export const addPassenger = async (json, i) => {
  const newJson = JSON.parse(JSON.stringify(json))
  const jsonToPush = {}
  const formFields = await FC.service('info').get('formFields')
  for (const field of formFields.filter((field) => field.section === 'passengers')) jsonToPush[field.id] = ''
  if (!newJson?.events?.[i]?.passengers?.length) newJson.events[i].passengers = []
  newJson.events[i].passengers.push(jsonToPush)
  return newJson
}
