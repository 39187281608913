import { Flex, Input, Text } from '../../../../Components'

export const ImportTutorial = () => (
  <Flex>
    <Text value="Effettua l'upload di un file CSV contentente le agenzie da aggiungere." size={16} style={{ marginTop: 20 }} />
    <Text value='Il file CSV deve essere così strutturato: ' size={16} />
    <Text value='Nella prima riga di intestazione devono essere inseriti i campi Codice Cliente Nerone e Nome Agenzia' size={16} />
    <Text value='Dalla seconda riga devono essere inseriti i dati delle varie agenzie.' size={16} />
  </Flex>
)

export const TextEditor = ({ value, editorCallback }, isNumber) => (
  <Input
    id='txt' value={value}
    onChange={({ txt }) => {
      if (isNumber && /[a-zA-Z]/.test(txt)) { return }
      editorCallback(txt)
    }}
  />
)
