import { useEffect, useState } from 'react'
import { readKey, writeKey } from '../Functions'

export const useTransferField = ([json, setJson], location, count, count2) => {
  const [value, setValue] = useState(readKey(json, location, count, count2)) // lo inizializzo subito senza aspettare il primo render perché i campi di input sono rossi per un attimo

  const arrDependencies = [
    location?.includes('passeggeri.') ? json?.events?.[count]?.passengers?.length : null,
    location?.includes('events.') ? json?.events?.length : null,
    location?.includes('events.') ? json?.events : null,
    location?.includes('endTimeDate') ? json?.endTimeDate : null,
    location?.includes('endTimeHour') ? json?.endTimeHour : null
  ].filter(i => i !== null)

  const customSet = (value = '') => {
    setValue(value)
    const newJson = writeKey(json, location, count, count2, value)
    Object.keys(newJson)?.length && setJson(newJson)
  }

  useEffect(() => {
    const newValue = readKey(json, location, count, count2)
    customSet(newValue)
  }, [...arrDependencies])

  return [value, customSet]
}
