import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, Flex, Input, Spinner, Text, Modal } from '../../../Components'
import { useCQuery } from '../../../Services'
import React, { Fragment, useEffect, useState } from 'react'
import { STATE_ENUM } from '../../Gigi/GigiList/Functions'
import { JsonViewer } from '../../../Components/Common/JsonViewer'
import { reexecuteUnit } from './Functions'

export function ProcessStepsVisualizer () {
  const { processId } = useParams()
  const { data: processSteps = [], isSuccess } = useCQuery(['processSteps', processId])
  const [selectedState, setSelectedState] = useState('Tutti')
  const [reexecuteResult, setReexecuteResult] = useState(null)
  const [reexecutionInProgress, setReexecutionInProgress] = useState(false)
  const [filteredProcessSteps, setFilteredProcessSteps] = useState([])
  const [conversationId, setConversationId] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    if (!processSteps?.length) return
    setFilteredProcessSteps(
      processSteps
        .filter((item) => selectedState === 'Tutti' || (item.stepState === STATE_ENUM?.find(({ label }) => label === selectedState)?.value))
        .sort((a, b) => b.id - a.id))
    setConversationId(processSteps[0]?.initialState?.conversationData?.conversationId)
  }, [selectedState, processSteps])

  if (!isSuccess) return <Spinner />
  return (
    <>
      <Flex style={{ marginTop: 30 }}>
        <Flex fw row>
          <Button label='Torna indietro' icon='fa-duotone fa-arrow-left' onClick={() => navigate('/conversations/' + conversationId)} style={{ marginRight: 20 }} />
          <Button label='Statistiche' icon='fa-duotone fa-chart-simple' onClick={() => navigate('/process/stats/' + processId)} style={{ marginRight: 20 }} />
          <Button label='Differenze Stati' icon='fa-duotone fa-flask-gear' onClick={() => navigate('/process/compare/' + processId)} />
        </Flex>
        <Input
          id='selectedState' label='Stato'
          dropdown
          options={['Tutti', ...STATE_ENUM?.map(({ label }) => label)]}
          value={selectedState}
          placeholder='Seleziona il tipo di operazione'
          style={{ width: 700, marginTop: 30 }}
          onChange={({ selectedState }) => setSelectedState(selectedState)}
        />
      </Flex>
      <Flex fw fh as js row style={{ marginTop: 30 }}>
        <Flex js style={{ width: '50%', height: '100%', overflowY: 'auto' }}>
          {filteredProcessSteps.map((process, id) =>
            <ProcessView key={id} process={process} onReexecuteResult={setReexecuteResult} setReexecutionInProgress={setReexecutionInProgress} />)}
        </Flex>
        <Flex js ae fh style={{ width: '50%', height: '100%', marginTop: 10, position: 'sticky', top: 0 }}>
          <Card title='Riesecuzione unit' style={{ width: '98%' }}>
            <Flex js as style={{ marginTop: 10 }}>
              {!reexecutionInProgress
                ? reexecuteResult
                  ? (
                    <>
                      <Text bold value={reexecuteResult.unit} style={{ marginBottom: 10 }} />
                      <JsonViewer name='Final State' src={reexecuteResult} />
                    </>
                    )
                  : <Text value='Riesegui una unit' />
                : <Spinner />}
            </Flex>
          </Card>
        </Flex>
      </Flex>
    </>
  )
}

const ProcessView = ({ process: { unit, id, startTime, endTime, stepState, executionTime, finalState, logStep, initialState, initialStack, finalStack }, onReexecuteResult, setReexecutionInProgress }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const handleReexecuteClick = async () => {
    setReexecutionInProgress(true) // Attiva lo spinner

    try {
      const result = await reexecuteUnit(unit, initialState)
      onReexecuteResult(result)
    } catch (error) {
      // Gestisci eventuali errori
      console.log(error.message + ' ' + error.stack)
    } finally {
      setReexecutionInProgress(false) // Disattiva lo spinner dopo la riesecuzione
    }
  }

  const convertExecutionTime = () => {
    if (executionTime < 60000) {
      const avgExecutionTimeSec = Math.floor(executionTime / 1000)
      return `${avgExecutionTimeSec} secondi`
    } else {
      const avgExecutionTimeMin = Math.floor(executionTime / 60000)
      const remainingSeconds = ((executionTime % 60000) / 1000).toFixed(0)
      return `${avgExecutionTimeMin} minuti e ${remainingSeconds} secondi`
    }
  }

  return (
    <Flex fw js as key={id} style={{ margin: 10 }}>
      <Card title={unit} style={{ width: '100%' }} collapsable>
        <Button label='Riesegui' icon='fa-duotone fa-play' onClick={handleReexecuteClick} width='20%' />
        <Flex row js style={{ marginTop: 10, marginBottom: 10 }}>
          <Text value={`${STATE_ENUM[stepState - 1]?.label}`} style={{ marginRight: 20, color: STATE_ENUM?.[stepState - 1]?.color, fontSize: 20 }} />
          {stepState === 5
            ? (
              <>
                <Modal visible={modalVisible} header='Visualizza errore' style={{ width: '90%' }} onHide={() => setModalVisible(false)}>
                  {logStep?.error?.map((error) => (
                    <Flex as js key={error}>
                      <Text key={error} value={'Errore: ' + error.message} />
                      <JsonViewer name='Error' src={error} />
                    </Flex>
                  ))}
                </Modal>
                <Button round icon='fa-duotone fa-eye' onClick={() => setModalVisible(true)} />
              </>
              )
            : null}
        </Flex>
        <Text value={`Execution Time: ${executionTime ? `${convertExecutionTime()}` : 'Non terminato'}`} style={{ marginRight: 20 }} />
        <Text value={`Start time: ${startTime ? `${new Date(startTime).toLocaleString('it-IT')}` : ''}`} style={{ marginRight: 20 }} />
        <Text value={`End time: ${endTime ? `${new Date(endTime).toLocaleString('it-IT')}` : startTime ? 'Non terminato' : ''}`} />
        <JsonViewer name='Logs' src={logStep} />
        <JsonViewer name='Initial State' src={initialState} />
        <JsonViewer name='Final State' src={finalState} />
        <JsonViewer name='Initial Stack' src={initialStack} />
        <JsonViewer name='Final Stack' src={finalStack} />
      </Card>
    </Flex>
  )
}
