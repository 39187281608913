import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useMount, useUnmount } from 'react-use'
import { motion } from 'framer-motion'
import { Icon } from './Icon'
import { Flex } from './Flex'
import { Text } from './Text'
import { cap } from '../../State'
import { useCQuery } from '../../Services/QueryCache'

const modalMenu = document.getElementById('modal-menu')
const modalDiv = document.createElement('div')

const MENU = { miniWidth: 90, menuWidth: 400, toggle: null, state: 0 }

const menuColors = {
  dark: { hover: { backgroundColor: 'rgba(255, 255, 255, 0.95)', color: '#3a3b3d' }, normal: { backgroundColor: '#3a3b3d', color: 'rgba(255, 255, 255, 0.95)' } },
  light: { hover: { backgroundColor: '#3a3b3d', color: 'rgba(255, 255, 255, 0.95)' }, normal: { backgroundColor: 'rgba(255, 255, 255, 0.95)', color: '#3a3b3d' } }
}

const isMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  const isTouchDevice = window.matchMedia('(pointer: coarse)').matches
  const isSmallScreen = window.innerWidth <= 1024

  // Check for mobile user agents
  const isMobileUserAgent = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent)

  return isTouchDevice || isSmallScreen || isMobileUserAgent
}

export const HamburgerMenu = () => {
  const {
    data: {
      gattinoniMessages = 0,
      otherMessages = 0
    } = {}
  } = useCQuery(['messagesToRead'])
  const isMobile = isMobileDevice()

  const messagesToRead = gattinoniMessages + otherMessages
  return (
    <div style={{ position: 'relative', width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {messagesToRead > 0 &&
        <div style={{ position: 'absolute', top: 0, right: 0, width: 20, height: 20, borderRadius: 10, backgroundColor: 'red', color: 'white', fontSize: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10, pointerEvents: 'none' }}>
          {messagesToRead}
        </div>}
      <motion.div
        whileHover='hovered' whileTap='click'
        onTap={() => {
          if (MENU.state !== 2) {
            MENU.toggle?.(2)
            MENU.state = 2
          } else {
            const newState = isMobile ? 0 : 1
            MENU.toggle?.(newState)
            MENU.state = newState
          }
        }}
        {
      ...!isMobile
        ? {
            onHoverStart: () => {
              MENU.toggle?.(1)
              MENU.state = 1
            },
            onHoverEnd: () => {
              MENU.toggle?.(0)
              MENU.state = 0
            }
          }
        : {}
      }
        variants={{ click: { rotate: '270deg' }, hovered: { rotate: '90deg' } }}
      >
        <Icon name='fa-duotone fa-bars' size={30} />
      </motion.div>
    </div>
  )
}

const MenuItem = ({ item: { label, icon, path, disabled, img, showGattinoniMessages, showOtherMessages }, action }) => {
  const {
    data: {
      gattinoniMessages = 0,
      otherMessages = 0
    } = {}
  } = useCQuery(['messagesToRead'])
  const [isDark] = cap.darkMode.useState()
  let finalTheme = isDark
  const { DEFAULT_THEME, SINGLE_THEME } = window._env_
  if (SINGLE_THEME === 'true') finalTheme = DEFAULT_THEME !== 'light' // perché il tema è light di default
  // se non è SINGLE_THEME vince il cap.darkMode
  if (disabled) return null
  if (label === 'line') {
    return (
      <Flex>
        <hr style={{ width: '80%', margin: 0 }} />
      </Flex>
    )
  }

  return (
    <motion.div
      onTap={action} style={Styles.menuElement(finalTheme)}
      whileHover='hovered' transition={{ default: { duration: 0.5 } }}
      variants={{ hovered: menuColors[finalTheme ? 'dark' : 'light'].hover }}
    >
      <Flex style={{ minWidth: 70, marginLeft: 10, marginRight: 20 }}>
        <div style={{ position: 'relative', width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {icon && <Icon color='inherit' name={icon} size={35} />}
          {showGattinoniMessages && gattinoniMessages > 0 &&
            <div style={{ position: 'absolute', top: 0, right: 0, width: 20, height: 20, borderRadius: 10, backgroundColor: 'red', color: 'white', fontSize: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
              {gattinoniMessages}
            </div>}
          {showOtherMessages && otherMessages > 0 &&
            <div style={{ position: 'absolute', top: 0, right: 0, width: 20, height: 20, borderRadius: 10, backgroundColor: 'red', color: 'white', fontSize: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
              {otherMessages}
            </div>}
        </div>
        {img && <img src={img} style={{ width: 55, height: 55 }} />}
      </Flex>
      <Text color='inherit' size={20} bold value={label} />
    </motion.div>
  )
}

export const SlidingMenu = ({ menuItems, menuAction }) => {
  const [visibility, setVisibility] = useState(0)
  const [isDark] = cap.darkMode.useState()
  const [renderNull, setRenderNull] = useState(false)
  let finalTheme = isDark
  const { DEFAULT_THEME, SINGLE_THEME } = window._env_
  if (SINGLE_THEME === 'true') finalTheme = DEFAULT_THEME !== 'light' // perché il tema è light di default

  useEffect(() => {
    // hard refresh del menu se cambia il tema, perchè se no ci sono problemi di stile
    setRenderNull(true)
    setTimeout(() => setRenderNull(false), 100)
  }, [isDark])

  useMount(() => { modalMenu.appendChild(modalDiv); MENU.toggle = setVisibility })
  useUnmount(() => { modalMenu.removeChild(modalDiv); MENU.toggle = null })

  const renderMenu = renderNull
    ? null
    : (
      <motion.div
        onHoverStart={() => setVisibility(2)} onHoverEnd={() => setVisibility(0)}
        animate={{
          width: [MENU.miniWidth, MENU.miniWidth, MENU.menuWidth][visibility],
          x: [-MENU.miniWidth, 0, 0][visibility],
          boxShadow: ['2px 0 4px 0px rgba(100, 100, 100, 0)', '2px 0 4px 0px rgba(100, 100, 100, 0.7)', '2px 0 4px 0px rgba(100, 100, 100, 0.7)'][visibility]
        }}
        transition={{ ease: 'easeOut', duration: 0.5 }}
        style={{ ...Styles.menuContainer }}
        className={`menu-list ${finalTheme ? 'theme-dark' : 'theme-light'}`}
      >
        {menuItems.map(({ path, resetTable = false, ...item }, ind) => <MenuItem key={ind} item={item} action={() => { menuAction(path, resetTable); setVisibility(0) }} />)}
      </motion.div>
      )

  return ReactDOM.createPortal(renderMenu, modalMenu)
}

const Styles = {
  menuContainer: {
    position: 'absolute',
    top: 50,
    bottom: 0,
    left: 0,
    width: MENU.miniWidth,
    x: -MENU.miniWidth,
    zIndex: 20,
    overflow: 'auto',
    scrollbarWidth: 'none', /* Per Firefox */
    msOverflowStyle: 'none' /* Per Internet Explorer e Edge */
  },
  menuElement: (isDark) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    height: 60,
    ...menuColors[isDark ? 'dark' : 'light'].normal
  })
}
