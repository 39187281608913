import React, { useState, useEffect } from 'react'
import { Card, Flex } from '../../../../Components'
import { FC } from '../../../../Services'
import { CustomHeader } from '../Components'
import { countryListDropDefault, getCorrectPhone } from '../Functions'
import { FloatInput } from '../TransferField/InputForm'
import _ from 'lodash'
import { Text } from '../../../../Components/Common/Text'

export const SenderSection = ({
  selectedTravellerId: [selectedTravellerId, setSelectedTravellerId = () => {}] = [],
  sender: [sender = { name: '', surname: '', email: '' }, setSender] = [],
  prefix: [prefix, setPrefix],
  cellulare: [cellulare, setCellulare],
  client: [client, setClient] = [],
  agency: [agency, setAgency] = [],
  readOnly, checkError, showSuggestions
}) => {
  const [suggestions, setSuggestions] = useState([])
  const [selected, setSelected] = useState(false)

  const debounceSearchTravellers = _.debounce(async () => {
    if ([sender?.name, sender?.surname, sender?.email, prefix + cellulare].every((el) => el?.length < 3)) return
    if (selected) return
    const res = await FC.service('info').get('searchTravellers', {
      query: {
        name: sender?.name,
        surname: sender?.surname,
        email: sender?.email,
        phone: prefix + cellulare,
        ...(client?.id ? { clientId: client.id } : {})
      }
    })
    setSuggestions(res)
  }, 500)

  const selectSender = (s) => {
    setSelectedTravellerId(s.id)
    setSender({ name: s.name, surname: s.surname, email: s.email })
    if (s.client) {
      setAgency({ id: s.client.environment.id, codClienteNerone: s.client.environment.codClienteNerone, agencyName: s.client.environment.agencyName, amadeusOfficeId: s.client.environment.amadeusOfficeId, sabreOfficeId: s.client.environment.sabreOfficeId, galileoOfficeId: s.client.environment.galileoOfficeId })
      setClient({ id: s.client.id, codCliente: s.client.codCliente, ragSociale: s.client.ragSociale, pagamento: s.client.pagamento, info: s.client.info })
    }
    const telefono = getCorrectPhone(s.phone)
    const [extractedPrefix, extractedCellulare] = telefono.split('.')
    setPrefix(countryListDropDefault.find((elem) => elem.prefix === extractedPrefix)?.value || '')
    setCellulare(extractedCellulare || '')
    setSuggestions([])
    setSelected(true)
  }

  useEffect(() => {
    if (!showSuggestions) return
    debounceSearchTravellers()
  }, [sender.name, sender.surname, sender.email, prefix, cellulare, client?.id])

  return (
    <Card customHeader={<CustomHeader title='DATI MITTENTE' />} style={{ width: '100%', marginTop: 20 }}>
      <Flex fw wrap row>
        <Flex as js style={{ width: '48%', margin: '0px 1%' }}>
          <FloatInput
            value={[sender?.name, (name) => {
              setSender({ ...sender, name })
              setSelectedTravellerId('')
              setSelected(false)
            }]}
            id='name' label='Nome' readOnly={readOnly}
          />
        </Flex>
        <Flex as js style={{ width: '48%', margin: '0px 1%' }}>
          <FloatInput
            value={[sender?.surname, (surname) => {
              setSender({ ...sender, surname })
              setSelectedTravellerId('')
              setSelected(false)
            }]}
            id='surname' label='Cognome' readOnly={readOnly}
          />
        </Flex>
        <Flex as js style={{ width: '48%', margin: '0px 1%' }}>
          <FloatInput
            value={[sender?.email, (email) => {
              setSender({ ...sender, email })
              setSelectedTravellerId('')
              setSelected(false)
            }]}
            id='email' label='Email' readOnly={readOnly}
          />
        </Flex>
        <Flex row as js style={{ width: '48%', margin: '0px 1%' }}>
          <Flex as js style={{ width: '40%', marginRight: 10 }}>
            <FloatInput type='dropdown' value={[prefix, setPrefix]} id='prefix' filter label='Prefisso' options={countryListDropDefault} style={{ marginRight: 10 }} readOnly={!!sender?.phone} error={checkError && !prefix && cellulare} />
          </Flex>
          <Flex as js style={{ width: '58%' }}>
            <FloatInput value={[cellulare, setCellulare]} id='phone' label='Cellulare' readOnly={!!sender?.phone} type='numeric' />
          </Flex>
        </Flex>
      </Flex>
      {showSuggestions && suggestions.length
        ? (
          <Flex fw wrap row style={{ marginTop: 20, maxHeight: 500, overflowY: 'auto' }}>
            {suggestions.map((s, i) => (
              <Flex
                as js style={{ padding: 10, border: '1px solid #e0e0e0', borderRadius: 5, margin: 5, cursor: 'pointer', minWidth: 300 }}
                onClick={() => selectSender(s)} key={i}
              >
                <Text value={s.name + ' ' + s.surname} bold style={{ height: 18 }} />
                <Text value={s.client?.ragSociale} style={{ height: 18 }} />
                <Text value={s.client?.environment?.agencyName} style={{ height: 18 }} />
              </Flex>

            ))}
          </Flex>
          )
        : null}
    </Card>
  )
}
