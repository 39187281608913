import { objectMap } from './Functions'
import { StoredCapsule, Capsule } from './CapsuleClasses'

export const getCurrentDate = () => {
  // Ottieni la data corrente
  const currentDate = new Date()

  // Imposta l'orario a mezzanotte
  currentDate.setHours(0)
  currentDate.setMinutes(0)
  currentDate.setSeconds(0)
  currentDate.setMilliseconds(0)

  return currentDate
}

const sessionState = {
  assignedFilter: 'all',
  customAssignedFilter: 'user',
  selectedFilter: 'open',
  datatableState: {
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      'traveller.phone': { value: '', matchMode: 'contains' },
      'traveller.client.ragSociale': { value: '', matchMode: 'contains' },
      'traveller.client.environment.agencyName': { value: '', matchMode: 'contains' },
      title: { value: '', matchMode: 'contains' },
      'user.username': { value: '', matchMode: 'contains' },
      statusLabel: { value: '', matchMode: 'contains' },
      createdAt: { value: '', matchMode: 'contains' },
      lastMessageAt: { value: '', matchMode: 'contains' }
    }
  },
  lineaOccupataRange: [new Date(), new Date()],
  attendancesRange: [new Date(), new Date()],
  range: [new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1), new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)],
  datesSearchStats: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
  selectedDateGigiList: getCurrentDate().toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }),
  selectedDateConversationList: getCurrentDate().toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }),
  selectedStartDateDashboard: getCurrentDate().toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }),
  selectedEndDateDashboard: getCurrentDate().toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })
}

const storedState = {
  darkMode: window?.matchMedia('(prefers-color-scheme: dark)')?.matches || false
}

export const state = {
  ...objectMap(sessionState, (key, value) => new Capsule(key, value)),
  ...objectMap(storedState, (key, value) => new StoredCapsule(key, value))
}
