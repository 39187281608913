import { Card, Flex, Text } from '../../../../Components'
import { CustomHeader } from '../Components'
import { useElementsByService } from '../Functions'
import { Modal } from '../../../../Components/Common/Modal'
import { Button } from '../../../../Components/Common/Button'
import { TextareaAuto } from '../../../../Components/Common/TextareaAuto'
import { renderElements } from '../RenderFunctions'
import { FloatInput } from '../TransferField/InputForm'

export const MainDataSection = ({
  resume: [resume, setResume] = [],
  noteModalVisible: [noteModalVisible, setNoteModalVisible] = [],
  onClickModal, state: [state, setState] = [], readOnly, diff,
  client: [client] = [],
  title: [title, setTitle] = []
}) => {
  const { mainData = {} } = useElementsByService()
  const stateProp = [state, setState]

  return (
    <Card customHeader={<CustomHeader title='DATI RICHIESTA' />} style={{ width: '100%', marginTop: 20 }}>
      <Flex fw wrap row js>
        {resume && (
          <Flex fw js style={{ marginBottom: 20, marginLeft: 10 }}>
            <Flex fw row js wrap style={{ gap: 20 }}>
              <Text value='Riassunto Conversazione' size={20} style={{ marginTop: 10 }} />
              <Button round icon='fa-duotone fa-pen' onClick={() => setNoteModalVisible(true)} />
            </Flex>
            <Flex fw as js>
              <Text value={resume} size={15} style={{ marginTop: 10 }} />
            </Flex>
          </Flex>
        )}
        <Modal style={{ width: '40%' }} visible={noteModalVisible} header='Riassunto della conversazione' onHide={() => setNoteModalVisible(false)}>
          <Flex fw as>
            <TextareaAuto value={resume} onChange={(e) => setResume(e.target.value)} style={{ width: '100%' }} />
            <Flex fw row jb style={{ marginTop: 20 }}>
              <Button label='Annulla' icon='fa-duotone fa-trash' onClick={() => setNoteModalVisible(false)} />
              <Button label='Conferma' icon='fa-duotone fa-check' onClick={onClickModal} />
            </Flex>
          </Flex>
        </Modal>
        <Flex as js style={{ width: '100%', margin: '0px 1%' }}>
          <FloatInput value={[title || '', setTitle]} label='Titolo' id='title' readOnly={readOnly} boldTitle />
        </Flex>
        {renderElements(mainData, client, stateProp, readOnly)}
      </Flex>
    </Card>
  )
}
