import React, { useState } from 'react'
import { Flex, Button, Text, Input } from '../../../Components/'
import { FloatInput } from '../GigiForm/TransferField/InputForm'
import { countryListDropDefault } from '../GigiForm/Functions'
import { useCQuery } from '../../../Services/QueryCache'
import { FC } from '../../../Services/FeathersClient'
import { useNavigate } from 'react-router-dom'

export const StartConversaton = ({ close }) => {
  const [prefix, setPrefix] = useState('')
  const [cellulare, setCellulare] = useState('')
  const [checkError, setCheckError] = useState(false)
  const [selectedMessage, setSelectedMessage] = useState({})
  const { data: templateList = [] } = useCQuery(['templateList'])
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const createConversation = async () => {
    // check if the fields are filled, check if the phone number is valid with a regex
    setLoading(true)
    if (!prefix || !cellulare || !/^\d{9,15}$/.test(cellulare)) {
      setLoading(false)
      return setCheckError(true)
    }
    if (!selectedMessage.name || !selectedMessage.language) {
      setLoading(false)
      return setCheckError(true)
    }
    const res = await FC.service('public').create({
      type: 'testUnit',
      unit: 'createConversationWithTemplate',
      userPhone: prefix + cellulare,
      isDebug: false,
      communicationChannel: 'whatsapp',
      conversationStartedWithTemplate: true,
      operatorInfo: { sendTemplate: { templateName: selectedMessage.name, languageCode: selectedMessage.language } }
    })
    close()
    res?.conversationData?.conversationId && navigate('/operatore/' + res.conversationData.conversationId)
    window.growl.show({ severity: 'success', summary: 'Conversazione creata', detail: 'La conversazione è stata creata con successo' })
  }

  return (
    <Flex fw fh>
      <Flex row wrap style={{ width: '60%' }}>
        <Text style={{ width: '100%', textAlign: 'center' }} value='Crea una nuova conversazione' size={20} bold />
        <div style={{ width: '49%', marginRight: '1%' }}>
          <FloatInput type='dropdown' value={[prefix, setPrefix]} id='prefix' filter label='Prefisso' options={countryListDropDefault} error={checkError && !prefix} />
        </div>
        <div style={{ width: '49%', marginLeft: '1%' }}>
          <FloatInput value={[cellulare, setCellulare]} id='phone' label='Cellulare' error={checkError && (!cellulare || !/^\d{9,15}$/.test(cellulare))} />
        </div>
        <Flex fw>
          <Text value='Seleziona messaggio' style={{ width: '100%', textAlign: 'center', marginTop: 20 }} size={20} />
          <Input
            id='template'
            dropdown placeholder='Messaggio'
            options={templateList.map(({ name, language }) => name + ' - ' + language)}
            onChange={({ template }) => setSelectedMessage(templateList.find(({ name, language }) => name + ' - ' + language === template))}
            value={selectedMessage?.name + ' - ' + selectedMessage?.language}
            style={{ borderRadius: 5, marginTop: 20 }}
            error={checkError && (!selectedMessage.name || !selectedMessage.language)}
          />
          {selectedMessage.name && selectedMessage.language &&
            <>
              <Text value='Messaggio: ' style={{ marginTop: 20 }} bold />
              <Text value={selectedMessage?.message} style={{ width: '100%', textAlign: 'center' }} />
            </>}
        </Flex>
      </Flex>
      <Button label='Invia messaggio' style={{ marginTop: 20 }} onClick={createConversation} icon='fa-duotone fa-paper-plane' spinner={loading} />
    </Flex>
  )
}
