import React, { useEffect, useState } from 'react'
import { Flex } from '../../../../Components'
import { Button } from '../../../../Components/Common/Button'
import { useNavigate } from 'react-router-dom'
import { FC, invalidateQuery, useCQuery } from '../../../../Services'
import { useAuth } from '../../../../Provider/AuthProvider'
import { Input } from '../../../../Components/Common/Input'
import { gattinoniId } from '../../GigiList'
import { TimeView } from '../../../StatisticheOperatori/TimeView'
import { Icon } from '../../../../Components/Common/Icon'
import { Modal } from '../../../../Components/Common/Modal'

export const FormHeader = ({ conversationId }) => {
  const { data: users = [] } = useCQuery(['users'])
  const { user: { user = {} } = {} } = useAuth()
  const { data: conversation = {} } = useCQuery(['conversation', conversationId])
  const [timeModalVisible, setTimeModalVisible] = useState(false)
  const navigate = useNavigate()

  /**
   * Assegna una conversazione a un utente specifico.
   *
   * @async
   * @function assign
   * @param {Object} params - L'oggetto dei parametri.
   * @param {Object} params.assignDropdown - L'oggetto contenente l'id dell'utente a cui assegnare la conversazione.
   * @throws {Error} Lancia un errore se il servizio non risponde come previsto.
   */
  const assign = async ({ assignDropdown }) => {
    const { id } = assignDropdown
    await FC.service('conversations').patch(conversationId, { userId: id })
    invalidateQuery([['conversations'], ['conversation', conversationId?.toString()]])
  }

  useEffect(() => {
    // assegna automatica a me se non è ancora assegnata e c'è un messaggio da leggere
    if (conversation.userId === null && conversation.messages?.some(m => !m.read && m.userId !== user.id)) {
      assign({ assignDropdown: { id: user.id } })
    }
  }, [])

  return (
    <Flex je fw row style={{ height: 30 }}>
      <span className='p-float-label'>
        <Input
          id='assignDropdown'
          dropdown
          style={{ width: 200 }}
          options={[{ id: null, username: 'Nessuno' }, ...users]}
          value={users.find(u => u.id === conversation?.userId) || { id: null, username: 'Nessuno' }}
          onChange={assign}
          optionLabel='username'
        />
        <label style={{ fontFamily: 'Lexend', top: -5 }}>Assegnato a</label>
      </span>
      <Modal
        style={{ width: '80vw', height: '95vh', borderRadius: 10 }}
        contentStyle={{ padding: 0, borderRadius: 10, overflow: 'scroll' }}
        visible={timeModalVisible}
        onHide={() => setTimeModalVisible(false)}
      >
        <Flex fw ae>
          <Icon name='fa-duotone fa-xmark' onClick={() => setTimeModalVisible(false)} />
        </Flex>
        <Flex as js style={{ padding: 20 }}>
          <TimeView conversationId={conversationId} />
        </Flex>
      </Modal>
      {(user?.role === 'admin' || user?.role === 'gestoreH24' || user?.role === 'gestoreOperatori') &&
        <Button label='Tempi' icon='fa-duotone fa-clock' style={{ marginLeft: 10 }} onClick={() => setTimeModalVisible(true)} />}
      <Button
        label='Indietro' icon='fa-duotone fa-arrow-left' onClick={() => {
          if (!conversation?.whatsappPhoneNumber) navigate('/H24/list')
          else if (conversation?.whatsappPhoneNumber?.environmentId === gattinoniId) navigate('/gattinoniWA/list')
          else navigate('/H24WA/list')
        }} style={{ marginLeft: 10 }}
      />
      {user?.role === 'admin' &&
        <Button label='Processi' icon='fa-duotone fa-gear' onClick={() => navigate('/conversations/' + conversationId)} style={{ marginLeft: 10 }} />}
    </Flex>
  )
}
