import { useLocation } from 'react-router-dom'
// import giginoPaneVino from '../Resources/giginoPaneVino.png'
const pathMap = [
  { path: '/login', label: 'Login' },
  { path: '/gattinoniWA/list', label: 'Whatsapp Gattinoni' },
  { path: '/H24WA/list', label: 'Whatsapp H24' },
  { path: '/H24/list', label: 'Attività H24' },
  { path: '/operatore/', label: 'Attività', include: true },
  { path: '/lineaoccupata/list', label: 'Attività' },
  { path: '/attendances/list', label: 'Presenze' },
  { path: '/statistiche', label: 'Statistiche', include: true },
  { path: '/users', label: 'Utenti' }
]

export const usePageTitle = () => {
  useLocation()
  if (window.location.pathname === '/') return 'Home'

  const matchTitle = ({ path, include }) => include ? window.location.pathname.includes(path) : path === window.location.pathname

  return pathMap.find(matchTitle)?.label || 'Home'
}

export const useMenuItems = ({ role }) => ([
  { label: 'Chat', icon: 'fa-duotone fa-paper-plane-top', path: '/chat/', role: ['admin', 'tester'] },
  { label: 'Elenco Agenzie', icon: 'fa-duotone fa-briefcase', path: '/admin/agency/list', role: ['admin', 'gestoreH24', 'gestoreOperatori'] },
  { label: 'Elenco Utenti', icon: 'fa-duotone fa-users', path: '/admin/users/list', role: ['admin', 'gestoreH24'] },
  { label: 'Elenco Viaggiatori', icon: 'fa-duotone fa-suitcase-rolling', path: '/admin/travellers/list', role: ['admin', 'gestoreH24'] },
  { label: 'Elenco Clienti', icon: 'fa-duotone fa-island-tropical', path: '/admin/clients/list', role: ['admin', 'gestoreH24'] },
  { label: 'line', role: ['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24'] },
  { label: 'Attività', icon: 'fa-duotone fa-headset', path: '/lineaoccupata/list', role: ['admin', 'gestoreH24', 'operatoreH24', 'gestoreOperatori', 'operatoreServizi', 'tester'] },
  { label: 'Processi', icon: 'fa-duotone fa-arrow-progress', path: '/process/dashboard', role: ['admin'] },
  { label: 'Presenze', icon: 'fa-duotone fa-clipboard-user', path: '/attendances', role: ['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24'] },
  { label: 'line', role: ['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24', 'operatoreServizi'] },
  { label: 'Whatsapp Gattinoni', icon: 'fa-brands fa-whatsapp', resetTable: true, path: '/gattinoniWA', role: ['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24'], showGattinoniMessages: true },
  { label: 'line', role: ['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24'] },
  { label: 'Whatsapp H24', icon: 'fa-brands fa-whatsapp', path: '/H24WA', resetTable: true, role: ['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24'], showOtherMessages: true },
  { label: 'Attività H24', icon: 'fa-duotone fa-phone-rotary', path: '/H24', resetTable: true, role: ['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24'] },
  { label: 'Turni H24', icon: 'fa-duotone fa-table', path: 'https://docs.google.com/spreadsheets/d/1UIeeuHTmco2Qrw-w0Sc_GHmM1Fa4biFO3ktWv8-K_5Y/edit?gid=0#gid=0', role: ['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24'] },
  { label: 'line', role: ['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24'] },
  { label: 'Statistiche Operatori', icon: 'fa-duotone fa-chart-simple', path: '/dashboard/statistiche/operatori', role: ['admin', 'gestoreH24', 'gestoreOperatori'] },
  { label: 'Statistiche Aziende H24', icon: 'fa-duotone fa-chart-simple', path: '/dashboard/statistiche/aziendeh24', role: ['admin', 'gestoreH24'] },
  { label: 'Statistiche Servizi', icon: 'fa-duotone fa-chart-simple', path: '/dashboard/statistiche/aziende', role: ['admin', 'gestoreH24'] },
  // { label: 'Statistiche Blueteam', icon: 'fa-duotone fa-chart-simple', path: '/dashboard/statistiche/blueteam', role: ['admin', 'gestoreH24'] },
  { label: 'Tempi attività', icon: 'fa-duotone fa-clock', path: '/timeSearch', role: ['admin', 'gestoreH24', 'gestoreOperatori'] },
  { label: 'line', role: ['admin'] },
  { label: 'Logout', icon: 'fa-duotone fa-power-off', path: 'logout' }
]).filter(item => !item.role || item?.role?.includes(role))
