import { useState } from 'react'
import { Button, Flex, Modal, Text } from '../../../Components'
import { TransferField } from './TransferField'
import { FloatInput } from './TransferField/InputForm'
import { Card } from '../../../Components/Common/Card'

export const CustomHeader = ({ title, isError }) => (
  <Flex style={{ width: 'calc(100% - 50px)', marginRight: 50 }} jb row>
    <Text title bold value={title} style={{ marginRight: 20, marginBottom: 10 }} size={20} />
  </Flex>
)

export const SingleServiceContainer = ({ children, onRemove, label, readOnly, darkMode, collapsable, ind }) => {
  const [modalVisible, setModalVisible] = useState(false)
  return (
    <Flex fw style={{ border: `1px solid ${darkMode ? 'white' : 'var(--float-label-color)'}`, borderRadius: 10, padding: 20, margin: '10px 0px' }}>
      <ConfirmModal header={'Cancellazione ' + label} label={'Vuoi cancellare questo ' + label?.toLowerCase() + '?'} onConfirm={onRemove} visible={[modalVisible, setModalVisible]} />
      {collapsable
        ? (
          <Card
            collapsable customHeader={(
              <Flex fw row jb style={{ marginRight: 10 }}>
                <Text value={label + ' ' + (ind + 1)} bold title size={18} />
                {!readOnly && <Button icon='fa-duotone fa-xmark' round onClick={() => setModalVisible(true)} />}
              </Flex>)}
            style={{ width: '100%' }}
          >
            <Flex fw row wrap js as>
              {children}
            </Flex>
          </Card>)
        : (
          <Flex fw row wrap js as>
            <Flex fw ae>
              {!readOnly && <Button icon='fa-duotone fa-xmark' round onClick={() => setModalVisible(true)} />}
            </Flex>
            {children}
          </Flex>)}
    </Flex>
  )
}

export const ConfirmModal = ({ visible, header, label, onConfirm }) => {
  const [modalVisible, setModalVisible] = visible
  return (
    <Modal visible={modalVisible} header={header} onHide={() => setModalVisible(false)}>
      <Flex fw>
        <Text value={label} size={18} />
        <Flex fw row jb style={{ marginTop: 20 }}>
          <Button label='Annulla' onClick={() => setModalVisible(false)} style={{ marginRight: 20 }} />
          <Button label='Conferma' onClick={() => { onConfirm(); setModalVisible(false) }} />
        </Flex>
      </Flex>
    </Modal>
  )
}

/**
 * Crea un componente di input personalizzato con etichetta.
 *
 * @param {string} inputId Identificativo univoco per l'input.
 * @param {string} label Etichetta visibile per l'input.
 * @param {Array} value Array contenente il valore corrente dell'input e la funzione per aggiornarlo.
 * @param {Function} onChange Funzione da chiamare ogni volta che il valore dell'input cambia.
 * @param {string} error Messaggio di errore da visualizzare in caso di valore errato.
 * @param {string|number} flexWidth La larghezza del componente, può essere una stringa (ad es. '50%') o un numero.
 * @param {string} type Tipo di input, ad esempio 'text', 'email', ecc.
 * @param {boolean} readOnly Indica se l'input è in sola lettura.
 *
 * @returns React component Ritorna un componente Flex che contiene un `FloatInput` con le proprietà specificate.
 */
export const InputCustom = ({ inputId, label, value: [value, setValue], onChange, error, flexWidth, type, readOnly }) => {
  return (
    <Flex as js style={{ width: flexWidth || '100%', margin: '0px 1%', marginTop: 10 }}>
      <FloatInput
        value={[value, setValue]} id={inputId} label={label} readOnly={readOnly} type={type}
      />
    </Flex>
  )
}

export { TransferField }
