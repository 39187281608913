import Papa from 'papaparse'
import { FC } from '../../../../Services/FeathersClient'

/**
 * Mostra un messaggio di errore.
 *
 * @param {string} summary - Breve riepilogo dell'errore.
 * @param {string} error - Dettaglio dell'errore. Se non fornito, viene usato il parametro 'alternative'.
 * @param {string} alternative - Messaggio alternativo da mostrare se 'error' non è disponibile.
 */
const showError = (summary, error, alternative) => {
  window.growl.show({ severity: 'error', summary, detail: error || alternative })
}

/**
 * Ottiene l'etichetta corrispondente alla chiave fornita.
 *
 * @param {number} key - Indice numerico della chiave per ottenere l'etichetta corrispondente nell'array.
 * @returns {string|undefined} Restituisce la stringa corrispondente all'etichetta se l'indice è presente nell'array;
 *                             altrimenti, restituisce `undefined`.
 */
const getLabel = (key) => {
  const labels = ['codClienteNerone', 'agencyName']
  return labels[key]
}

/**
 * Legge e analizza i file delle agenzie forniti.
 *
 * @async
 * @param {Event} e - L'evento che innesca la lettura del file.
 * @returns {Promise<Object>} Un oggetto promise che risolve a un oggetto contenente i dati formattati,
 *                            un flagg che indica la presenza di errori, e un elenco di eventuali record con errori.
 * @description Questa funzione asincrona gestisce l'importazione e l'analisi dei file delle agenzie.
 *              In caso di errori durante il parsing, come campi mancanti o duplicati, registra questi errori.
 *              Usa la libreria PapaParse per l'analisi step-by-step dei dati. Se non viene selezionato alcun file,
 *              mostra un errore all'utente. Se ci sono record con campi vuoti o duplicati all'interno dei dati,
 *              questi vengono registrati separatamente e trattati alla fine dell'analisi.
 */
export const readAgencies = async (e) => {
  const files = e.target.files
  if (!files.length) {
    showError('Parsing error', 'No file selected.', 'Errore durante la lettura del file')
    return false
  }

  const formattedData = new Map()
  const errorRecords = []
  let hasErrors = false

  await new Promise((resolve, reject) => {
    Papa.parse(files[0], {
      header: true,
      skipEmptyLines: true,
      step: (results, parser) => {
        const record = Object.entries(results.data).reduce((obj, [key, value], ind) => {
          obj[getLabel(ind)] = value
          return obj
        }, {})

        // Check null
        const emptyFields = ['codClienteNerone', 'agencyName'].filter(field => !record[field])
        if (emptyFields.length > 0) {
          errorRecords.push(record)
          hasErrors = true
        }

        // Check for duplicates
        if (formattedData.has(record.codClienteNerone)) {
          const recordToCopy = formattedData.get(record.codClienteNerone)
          errorRecords.push(recordToCopy)
          errorRecords.push(record)
          formattedData.delete(record.codClienteNerone)
          hasErrors = true
        } else if (emptyFields.length === 0) {
          formattedData.set(record.codClienteNerone, record)
        }
      },
      complete: () => {
        resolve()
      },
      error: reject
    })
  })

  return { data: Array.from(formattedData.values()), hasErrors, ...(hasErrors && { errors: errorRecords }) }
}

/**
 * Verifica nuovamente la presenza di duplicati nell'elenco delle agenzie.
 *
 * @param {Array<Object>} agencies - Un array di oggetti rappresentanti le agenzie.
 * @returns {Object} Un oggetto contenente l'elenco formattato delle agenzie senza duplicati,
 *         un flag booleano che indica la presenza di errori e, in caso di errori, un elenco dei record errati.
 * @description Questa funzione esegue una seconda verifica sulla presenza di campi vuoti o duplicati all'interno dell'array di agenzie.
 *              I record con campi obbligatori mancanti o duplicati vengono raccolti in un array separato 'errorRecords'.
 *              La funzione restituisce un oggetto con i dati delle agenzie privi di duplicati, lo stato degli errori,
 *              e, in caso positivo, gli 'errorRecords'.
 */
export const checkAgainForDuplicates = (agencies) => {
  const formattedData = new Map()
  const errorRecords = []
  let hasErrors = false

  agencies.forEach((record) => {
    // Check null
    const emptyFields = ['codClienteNerone', 'agencyName'].filter(field => !record[field])
    if (emptyFields.length > 0) {
      errorRecords.push(record)
      hasErrors = true
    }

    // Check for duplicates
    if (formattedData.has(record.codClienteNerone)) {
      const recordToCopy = formattedData.get(record.codClienteNerone)
      errorRecords.push(recordToCopy)
      errorRecords.push(record)
      formattedData.delete(record.codClienteNerone)
      hasErrors = true
    } else if (emptyFields.length === 0) {
      formattedData.set(record.codClienteNerone, record)
    }
  })

  return { data: Array.from(formattedData.values()), hasErrors, ...(hasErrors && { errors: errorRecords }) }
}

/**
 * Carica le agenzie in maniera asincrona.
 *
 * Questo metodo si occupa di importare un elenco di agenzie, controllando per duplicati
 * e tenendo traccia delle incongruenze. Per ogni agenzia senza duplicati, questa viene
 * creata nell'ambiente del servizio. Alla fine restituisce un oggetto che contiene il
 * numero di record importati con successo e un array di eventuali incongruenze trovate.
 *
 * @async
 * @function uploadAgencies
 * @param {Object[]} agencies - Elenco di agenzie da caricare.
 * @returns {Promise<Object>} Un oggetto Promise che risolve in un oggetto contenente il numero
 *                            di record importati con successo e un array di incongruenze.
 */
export const uploadAgencies = async (agencies) => {
  window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Importazione agenzie iniziata' })
  const inconsistencies = []
  let successRecord = 0

  for (const agency of agencies) {
    await FC.service('environments').create(agency)
    successRecord++
  }

  return { importedRecord: successRecord, inconsistencies }
}
