import React, { useMemo, createContext, useContext } from 'react'
import { useLocalStorage } from '../Hooks/useLocalStorage'
import { FC } from '../Services/FeathersClient'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Growl } from '../Components'
import { handleSubscribeNotify, unsubscribeUserFromPush } from '../Pages/Login/FunctionsLogin'

const AuthContext = createContext(null)

function AuthProvider () {
  const [user, setUser] = useLocalStorage('user', null)
  const navigate = useNavigate()
  const location = useLocation()

  const onLogin = async (loginData) => { // (userData) => async () => {
    const user = await FC.login({ ...loginData, strategy: 'local' })
    if (!user) return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Credenziali errate' })
    setUser(user)
    await handleSubscribeNotify(user)
    const origin = location?.state?.from?.pathname || (user.user.role === 'gestoreOperatori' ? '/lineaOccupata/list' : '/H24/list')
    await new Promise(() => setTimeout(() => navigate(origin), 100))
  }

  const onLoginJWT = async (jwt) => {
    const user = await FC.login({ strategy: 'jwt', accessToken: jwt })
    setUser(user)
  }

  const onLogout = async () => {
    await unsubscribeUserFromPush(user)
    await FC.logout()
    setUser(null)
    window.location.href = '/login'
  }

  const value = useMemo(() => ({
    user,
    onLogin,
    onLoginJWT,
    onLogout
  }))

  return (
    <AuthContext.Provider value={value}>
      <Growl />
      <Outlet />
    </AuthContext.Provider>
  )
}

export default AuthProvider

export const useAuth = () => useContext(AuthContext)
