import { useNavigate, useParams } from 'react-router-dom'
import { saveClient, useClient } from './Functions'
import { Button, Flex, Input, Spinner, Text } from '../../../../Components'
import { useCQuery } from '../../../../Services/QueryCache'

const defaultStyle = { width: '31%', marginRight: '1%', marginLeft: '1%', marginBottom: 15 }
const fields = {
  codCliente: { label: 'Codice Cliente' },
  ragSociale: { label: 'Nome' },
  info: { label: 'Info' },
  pagamento: { label: 'Pagamento' }
}

export function ClientEditor () {
  const { clientId } = useParams()
  const navigate = useNavigate()
  const [client, setClient, isSuccess] = useClient(clientId)
  const { data: formFields = [] } = useCQuery(['formFields'])
  const { data: environments = [] } = useCQuery(['environments'])
  const { id = '' } = client || {}

  const save = async () => {
    const res = await saveClient({ ...client, extraFields: { ...client?.extraFields || {} } })
    res?.id && navigate('/admin/clients/list')
    return res
  }

  if (!isSuccess || !client) return <Spinner />

  const isNew = id === 'new'

  const extraFields = formFields.filter(({ extraField }) => extraField)

  return (
    <Flex fw>
      <Text value={`${isNew ? 'Crea' : 'Modifica'} Cliente`} title bold size={30} />
      <Flex fw row wrap style={{ marginTop: 20 }}>
        {Object.keys(fields).map((key) =>
          <Input key={key} value={client[key] || ''} onChange={({ [key]: value }) => setClient(key, value)} label={fields[key]?.label} id={key} style={{ ...defaultStyle }} />
        )}
        <Input value={client.environmentId || ''} dropdown options={environments.map(({ id, agencyName }) => ({ value: id, label: agencyName }))} onChange={({ environmentId }) => setClient('environmentId', environmentId)} label='Agenzia' id='environmentId' style={{ ...defaultStyle }} />
        {extraFields?.length && <Text value='Campi Extra' title bold size={20} style={{ width: '100%', marginBottom: 10 }} center />}
        {extraFields?.map(({ id, label }) =>
          <Input
            key={id} value={client.extraFields?.[id] || false}
            dropdown
            options={[{ value: true, label: 'Si' }, { value: false, label: 'No' }]}
            onChange={({ [id]: value }) => setClient('extraFields', { ...(client?.extraFields || {}), [id]: value })}
            label={label.it} id={id} style={{ ...defaultStyle }}
          />
        )}
        <Flex fw row style={{ marginTop: 20 }}>
          <Button label='Salva' icon='fa-duotone fa-floppy-disk' style={{ marginRight: 10 }} onClick={save} />
          <Button label='Torna indietro' icon='fa-duotone fa-arrow-left' onClick={() => navigate('/admin/client/list')} />
        </Flex>
      </Flex>
    </Flex>
  )
}
