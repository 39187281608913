import { Card, Flex } from '../../../../Components'
import { CustomHeader } from '../Components'
import { FloatInput } from '../TransferField/InputForm'

export const HoursDates = ({ createdAt }) => (
  <Card customHeader={<CustomHeader title='DATE' />} style={{ width: '100%', marginTop: 20 }}>
    <Flex fw wrap row js>
      <Flex as js style={{ width: '48%', margin: '0px 1%' }}>
        <FloatInput
          value={[createdAt ? new Date(createdAt).toLocaleDateString('it-IT') : '']}
          readOnly label='Data inizio' id='startDate'
        />
      </Flex>
      <Flex as js style={{ width: '48%', margin: '0px 1%' }}>
        <FloatInput
          value={[createdAt ? new Date(createdAt).toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' }) : '']}
          readOnly label='Ora inizio' id='startHour'
        />
      </Flex>
    </Flex>
  </Card>
)
