import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { useCQuery } from '../../../../Services/QueryCache'
import { useNavigate } from 'react-router-dom'
import { Button, Flex, Modal, Spinner, Text } from '../../../../Components'
import { Column } from 'primereact/column'
import { deleteAgency } from '../Editor/Functions'
import { columnProps } from '../../../../Services/Functions'

export function AgencyList () {
  const { data: agencies = [], isSuccess } = useCQuery(['environments'])
  const [agenciesTable, setAgenciesTable] = useState([])
  const navigate = useNavigate([])

  useEffect(() => {
    isSuccess && setAgenciesTable(agencies.map((r) => r))
  }, [agencies])

  const BtnActions = ({ id }) => {
    const [modalVisible, setModalVisible] = useState(false)
    return (
      <Flex width={160} row>
        <Modal visible={modalVisible} header='Cancellazione Agenzia' onHide={() => setModalVisible(false)}>
          <Flex fw>
            <Text value='Vuoi cancellare questa agenzia?' />
            <Flex fw row jb style={{ marginTop: 20 }}>
              <Button label='Annulla' icon='fa-duotone fa-trash' onClick={() => setModalVisible(false)} />
              <Button label='Conferma' icon='fa-duotone fa-check' onClick={() => deleteAgency(id)} />
            </Flex>
          </Flex>
        </Modal>
        <Button round tooltip='Modifica' icon='fa-duotone fa-pen' onClick={() => navigate('/admin/agency/' + id)} />
        <Button style={{ marginLeft: 20 }} round tooltip='Elimina' icon='fa-duotone fa-trash' onClick={() => setModalVisible(true)} />
      </Flex>
    )
  }

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw fh js>
      <Flex fw row je ae>
        <Button label='Importa agenzie' icon='fa-duotone fa-plus' style={{ marginRight: 10 }} onClick={() => navigate('/admin/agency/import')} />
        <Button label='Crea Nuova' icon='fa-duotone fa-plus' style={{ marginRight: 10 }} onClick={() => navigate('/admin/agency/new')} />
      </Flex>
      <Text value='Lista Agenzie' size={20} bold />
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' sortField='createdAt' sortOrder={-1} className='TableSignatures'
        value={agenciesTable} emptyMessage='Nessuna agenzia trovata' responsiveLayout='scroll' paginator rows={10}
      >
        <Column field='codClienteNerone' {...columnProps('Codice Cliente Nerone')} />
        <Column field='agencyName' {...columnProps('Nome agenzia')} />
        <Column field='createdAt' {...columnProps('Data creazione')} body={({ createdAt }) => new Date(createdAt).toLocaleDateString('it-IT')} />
        <Column style={{ border: 'none', width: 140, height: 15, textAlign: 'center' }} body={(props) => <BtnActions {...props} />} />
      </DataTable>
    </Flex>
  )
}
