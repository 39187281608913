/**
 * Formatta una data nel formato italiano abbreviato.
 *
 * @param {Date} messageDate - La data del messaggio da formattare.
 * @returns {string} La data formattata in formato "Giorno della settimana abbreviato Giorno Mese abbreviato",
 *                   ritorna una stringa vuota se messageDate non è valido.
 */
export const formatDate = (messageDate) => {
  const itDays = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab']
  const itMonths = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']

  const dayName = itDays[messageDate.getDay()]
  const day = messageDate.getDate()
  const monthName = itMonths[messageDate.getMonth()]

  const formattedDate = dayName + ' ' + day + ' ' + monthName
  if (messageDate) return formattedDate
  return ''
}

export const formatMilliseconds = (time) => {
  const date = new Date(time)
  const isoString = date.toISOString()
  const timeString = isoString.slice(11, 19)
  const [hours, minutes, seconds] = timeString.split(':').map(Number)

  return `${hours}h ${minutes}m ${seconds}s`
}

/**
 * Formatta l'ora da una data fornita.
 *
 * @param {Date} messageDate - La data da cui estrarre l'ora.
 * @returns {string} Una stringa che rappresenta l'ora formattata, o una stringa vuota se non viene fornito alcun valore di date.
 */
export const formatHour = (messageDate) => {
  const hour = messageDate.getHours() + ':' + ('0' + messageDate.getMinutes()).slice(-2)

  if (messageDate) return hour
  return ''
}
