import { Card, Flex } from '../../../../Components'
import { CustomHeader, SingleServiceContainer } from '../Components'
import { Text } from '../../../../Components/Common/Text'
import { addEvent, addPassenger, getEventsCount, getPassengersCount, removeEvent, removePassenger } from './Functions'
import { Button } from '../../../../Components/Common/Button'
import { useElementsByService } from '../Functions'
import { renderElements } from '../RenderFunctions'

export const Events = ({ state: [state, setState] = [], readOnly, client: [client] = [] }) => {
  const stateProp = [state, setState]
  const { events = {}, passengers = {} } = useElementsByService()

  return (
    <Card customHeader={<CustomHeader title='EVENTI' />} style={{ width: '100%', marginTop: 20 }}>
      <Flex fw>
        <Flex fw row jb>
          {!getEventsCount(state) ? <Text value='Nessun evento inserito' style={{ fontFamily: 'Lexend', width: 300 }} /> : <div />}
          {!readOnly && <Button icon='fa-duotone fa-plus' label='Aggiungi evento' onClick={async () => setState(await addEvent(state))} />}
        </Flex>
        {Array(getEventsCount(state)).fill().map((_, i) => (
          <SingleServiceContainer collapsable ind={i} key={i} onRemove={() => setState(removeEvent(state, i))} readOnly={readOnly} label='Evento'>
            {renderElements(events, client, stateProp, readOnly, i)}
            <Flex fw row jb style={{ marginTop: 10, padding: 10 }}>
              {!getPassengersCount(state, i) ? <Text value='Nessun passeggero inserito' style={{ fontFamily: 'Lexend', width: 300 }} /> : <Text value='Passeggeri' bold upCase />}
              {!readOnly && <Button icon='fa-duotone fa-plus' label='Aggiungi passeggero' onClick={async () => setState(await addPassenger(state, i))} />}
            </Flex>
            {Array(getPassengersCount(state, i)).fill().map((_, p) => (
              <SingleServiceContainer key={p} onRemove={() => setState(removePassenger(state, i, p))} readOnly={readOnly} label='Passeggero'>
                {renderElements(passengers, client, stateProp, readOnly, i, p)}
              </SingleServiceContainer>
            ))}
          </SingleServiceContainer>
        ))}
      </Flex>
    </Card>
  )
}
