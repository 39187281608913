import React, { useState } from 'react'
import { Button, Card, Flex, Input, Spinner } from '../../Components'
import { useAuth } from '../../Provider/AuthProvider'

import siapLogo from '../../Resources/LogoSiap.png'

function Login () {
  const { onLogin } = useAuth()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [waiting, setWaiting] = useState(false)

  const onClickLogin = async () => {
    setWaiting(true)
    await onLogin({ username, password })
    setWaiting(false)
  }

  return (
    <Flex fw>
      <Card style={{ width: '600px', height: '100vh', padding: 50, borderRadius: 0 }}>
        <Flex fw fh>
          <img src={siapLogo} style={{ width: 500, marginTop: '30%' }} />
          <Input value={username} placeholder='Username' onChange={({ username }) => setUsername(username)} id='username' style={{ margin: '30px 0px', width: 400 }} />
          <Input password placeholder='Password' id='password' value={password} onChange={setPassword} style={{ borderRadius: 20, width: 400 }} onEnter={onClickLogin} />
          {waiting
            ? <Spinner />
            : <Button label='Accedi' icon='fa-duotone fa-right-to-bracket' onClick={onClickLogin} style={{ marginBottom: 10, marginTop: 20 }} />}
        </Flex>
      </Card>
    </Flex>
  )
}

export default Login
