import React, { useEffect, useState } from 'react'
import { useCQuery } from '../../../Services'
import { Flex, Spinner } from '../../../Components'
import { SenderSection } from '../../Gigi/GigiForm/Sections'
import { useParams } from 'react-router-dom'

export const FormView = ({ stateIndex }) => {
  const { processId } = useParams()
  const { data: processSteps = [], isSuccess } = useCQuery(['processSteps', processId])

  const [initialState, setInitialState] = useState({})
  const [finalState, setFinalState] = useState({})

  useEffect(() => {
    setInitialState(processSteps[stateIndex]?.initialState || {})
    setFinalState(processSteps[stateIndex]?.finalState || {})
  }, [stateIndex])

  if (!isSuccess) return <Spinner />
  const initalStateProps = [initialState, () => {}]
  const finalStateProps = [finalState, () => {}]
  return (
    <Flex as fw row style={{ marginTop: 20 }}>
      <Flex style={{ width: '49%', marginRight: '2%' }}>
        <SenderSection state={initalStateProps} readOnly />
      </Flex>
      <Flex style={{ width: '49%' }}>
        <SenderSection state={finalStateProps} readOnly />
      </Flex>
    </Flex>
  )
}
