import React, { useState } from 'react'
import { Flex } from '../../Components/Common/Flex'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { columnProps } from '../../Services/Functions'
import { Button } from '../../Components/Common/Button'
import { useNavigate } from 'react-router-dom'
import { useCInfiniteQuery } from '../../Services/QueryCache'
import { Text } from '../../Components/Common/Text'
import { deleteAttendance } from './Functions'
import { Modal } from '../../Components/Common/Modal'
import elencoCodiciAttivita from '../../Resources/codiciAttivitaPresenze.json'
import { useAuth } from '../../Provider/AuthProvider'
import { Calendar } from 'primereact/calendar'
import { cap } from '../../State'
import { useCapsule } from '../../State/Functions'

export const AttendancesList = () => {
  const [range, setRange] = useCapsule(cap.attendancesRange)
  const [datesSearch, setDatesSearch] = useState(range)
  const { data: attendances = [] } = useCInfiniteQuery(['attendances', range])
  const [modalVisible, setModalVisible] = useState(false)
  const [idToDelete, setIdToDelete] = useState('')
  const { user = {} } = useAuth()
  const { user: userObj = {} } = user || {}
  const { role, id: userId } = userObj

  const navigate = useNavigate()

  /**
 * Elabora la descrizione dell'attività basandosi sui dati della riga fornita.
 * La funzione cerca nel `elencoCodiciAttivita` un elemento il cui campo `key`
 * corrisponde al campo `reason` di `rowData`, ignorando le differenze di maiuscole/minuscole.
 * Se viene trovato un elemento corrispondente, restituisce il valore del campo `descrizioneMot`.
 *
 * @param {Object} rowData - Dati della riga contenenti il motivo dell'attività.
 * @returns {string|undefined} - La descrizione dell'attività trovata o `undefined` se non trovata.
 * @author @mranocchia
 */
  const elaborateDescriptionAttivita = (rowData) => {
    const { reason } = rowData
    const found = elencoCodiciAttivita.find(item => item?.value?.toLowerCase() === reason?.toLowerCase())
    if (found) return found.label
  }

  const BtnActionProc = ({ messagesToRead, statusLabel, id, user }) => (
    <Flex row style={{ width: '100%' }}>
      <Button round tooltip='Visualizza' icon='fa-duotone fa-pen' style={{ marginRight: 10 }} onClick={() => navigate('/attendances/' + id)} />
      <Modal visible={modalVisible} header='Cancellazione attività' onHide={() => setModalVisible(false)}>
        <Flex fw>
          <Text value='Vuoi cancellare questa attività?' />
          <Flex fw row jb style={{ marginTop: 20 }}>
            <Button label='Annulla' icon='fa-duotone fa-trash' onClick={() => setModalVisible(false)} />
            <Button
              label='Conferma' icon='fa-duotone fa-check' onClick={() => {
                deleteAttendance(idToDelete)
                setModalVisible(false)
              }}
            />
          </Flex>
        </Flex>
      </Modal>
      <Button
        round tooltip='Elimina' icon='fa-duotone fa-trash' onClick={() => {
          setIdToDelete(id)
          setModalVisible(true)
        }}
        disabled={user.id !== userId && role !== 'admin'}
      />
    </Flex>
  )

  return (
    <Flex fw>
      <Flex fw jb row>
        <div style={{ width: '20%' }} />
        <Text value='Elenco Presenze' size={22} bold />
        <Flex ae style={{ width: '20%' }}>
          <Button label='Aggiungi' icon='fa-duotone fa-plus' onClick={() => navigate('/attendances/new')} />
        </Flex>
      </Flex>
      <Flex fw style={{ marginTop: 10 }}>
        <Calendar
          value={datesSearch} selectionMode='range' onChange={(e) => {
            setDatesSearch(e.value)
            e.value?.filter(Boolean).length === 2 && setRange(e.value)
          }}
          locale='it' dateFormat='dd/mm/yy' style={{ width: 250 }}
        />
      </Flex>
      <DataTable
        style={{ width: '100%', marginTop: 20 }} filterDisplay='row' className='TableSignatures' sortField='startDate' sortOrder={1}
        value={attendances} emptyMessage='Nessuna presenza trovata' responsiveLayout='scroll' paginator rows={10}
      >
        {role === 'admin' || role === 'gestoreH24' || role === 'gestoreOperatori' ? <Column field='user.username' {...columnProps('Utente')} /> : null}
        <Column field='startDate' {...columnProps('Data Inizio')} body={(rowData) => new Date(rowData?.startDate).toLocaleString('it-IT')} />
        <Column field='endDate' {...columnProps('Data Fine')} body={(rowData) => new Date(rowData?.endDate).toLocaleString('it-IT')} />
        <Column field='time' {...columnProps('Durata')} body={(rowData) => rowData?.time} />
        <Column field='reasonDescription' {...columnProps('Descrizione attività')} body={elaborateDescriptionAttivita} />
        <Column style={{ border: 'none', width: 220, height: 15, textAlign: 'center' }} body={(props) => <BtnActionProc {...props} />} />
      </DataTable>
    </Flex>
  )
}
