import React, { useState, useEffect } from 'react'
import { prefetchQuery, useCInfiniteQuery } from '../../../../Services/QueryCache'
import { formatDateHour, conversationsStatus } from '../../GigiList/Functions'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { columnProps } from '../../../../Services/Functions'
import { Flex } from '../../../../Components/Common/Flex'
import { Button } from '../../../../Components/Common/Button'
import { Text } from '../../../../Components/Common/Text'
import { Form } from '../Form'
import { useParams } from 'react-router-dom'

const range = [new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1), new Date()]

export const ClientActivities = ({ clientId }) => {
  const { conversationId } = useParams()
  const { data: conversations = [], isSuccess, isStale } = useCInfiniteQuery(['conversations', undefined, undefined, undefined, [range[0].toLocaleDateString('it-IT'), range[1].toLocaleDateString('it-IT')], clientId])
  const [conversationsTable, setConversationsTable] = useState([])
  const [conversationIdToNavigate, setConversationIdToNavigate] = useState(null)

  const rowClass = ({ messagesToRead }) => `${messagesToRead > 0 ? 'unread-row' : 'read-row'}`

  const BtnActionProc = ({ messagesToRead, statusLabel, id }) => (
    <Flex jb row>
      <Button
        round icon='fa-duotone fa-paper-plane' style={{ marginRight: 10 }}
        onClick={() => setConversationIdToNavigate(id)}
        onHover={() => prefetchQuery(['conversation', id?.toString()])}
      />
      {(messagesToRead > 0) &&
        <Flex style={{ marginRight: 10, color: 'white', backgroundColor: 'rgb(5, 202, 15)', borderRadius: '50%', width: 30, height: 30 }}>
          <Text bold center value={messagesToRead} style={{ flexGrow: 0, textAlign: 'center', width: '100%' }} />
        </Flex>}
    </Flex>
  )

  useEffect(() => {
    if (isSuccess && !isStale) {
      // controllo se ci sono messaggi non letti, se sì setto il filtro su 'unread' altrimenti su 'all'
      // uso isStale per calcolare i messaggi non letti solo quando i dati della query sono aggiornati (a seguito di un invalidateQuery)
      setConversationsTable(conversations
        ?.filter(({ id }) => id !== parseInt(conversationId))
        .map((r) => {
          const createdAt = new Date(r.createdAt)
          return {
            ...r,
            traveller: {
              ...r.traveller,
              client: r.traveller?.client || { ragSociale: 'Non definito', environment: { agencyName: 'Non definito' } }
            },
            user: r.user || { username: 'Non assegnato' },
            createdAt: formatDateHour(createdAt),
            statusLabel: conversationsStatus?.[r.status],
            title: r.title || 'Non definito'
          }
        })
      )
    }
  }, [JSON.stringify(conversations), isSuccess, isStale])

  if (conversationIdToNavigate) {
    return (
      <Flex fw>
        <Form conversationId={conversationIdToNavigate} viewOnly />
        <Button label='Torna indietro' icon='fa-duotone fa-arrow-left' onClick={() => setConversationIdToNavigate(null)} style={{ marginTop: 10 }} />
      </Flex>
    )
  }
  return (
    <DataTable
      style={{ width: '100%', marginTop: 10 }} filterDisplay='row' className='TableSignatures'
      value={conversationsTable} emptyMessage='Nessuna attività trovata' responsiveLayout='scroll' paginator rows={10}
      rowClassName={rowClass}
    >
      <Column field='traveller.client.ragSociale' {...columnProps('Cliente')} />
      <Column field='traveller.client.environment.agencyName' {...columnProps('Agenzia')} />
      <Column field='title' {...columnProps('Titolo')} />
      <Column field='user.username' {...columnProps('Operatore')} />
      <Column field='statusLabel' {...columnProps('Stato')} />
      <Column field='createdAt' {...columnProps('Data inizio attività')} />
      <Column style={{ border: 'none', width: 40, height: 15, textAlign: 'center' }} body={(props) => <BtnActionProc {...props} />} />
    </DataTable>
  )
}
