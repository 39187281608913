import React, { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { useCQuery } from '../../../../Services/QueryCache'
import { useNavigate } from 'react-router-dom'
import { Button, Flex, Modal, Spinner, Text } from '../../../../Components'
import { Column } from 'primereact/column'
import { columnProps } from '../../../../Services/Functions'
import { deleteClient } from '../Editor/Functions'

export function ClientsList () {
  const { data: clients = [], isSuccess } = useCQuery(['clients'])

  const navigate = useNavigate([])

  const BtnActions = ({ id }) => {
    const [modalVisible, setModalVisible] = useState(false)
    return (
      <Flex width={160} row>
        <Modal visible={modalVisible} header='Cancellazione Cliente' onHide={() => setModalVisible(false)}>
          <Flex fw>
            <Text value='Vuoi cancellare questa cliente?' />
            <Flex fw row jb style={{ marginTop: 20 }}>
              <Button label='Annulla' icon='fa-duotone fa-trash' onClick={() => setModalVisible(false)} />
              <Button label='Conferma' icon='fa-duotone fa-check' onClick={() => deleteClient(id)} />
            </Flex>
          </Flex>
        </Modal>
        <Button round tooltip='Modifica' icon='fa-duotone fa-pen' onClick={() => navigate('/admin/clients/' + id)} />
        <Button style={{ marginLeft: 20 }} round tooltip='Elimina' icon='fa-duotone fa-trash' onClick={() => setModalVisible(true)} />
      </Flex>
    )
  }

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw fh js>
      <Flex fw row je ae>
        <Button label='Crea Nuovo' icon='fa-duotone fa-plus' style={{ marginRight: 10 }} onClick={() => navigate('/admin/clients/new')} />
      </Flex>
      <Text value='Lista Clienti' size={20} bold />
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' sortField='createdAt' sortOrder={-1} className='TableSignatures'
        value={clients} emptyMessage='Nessun cliente trovato' responsiveLayout='scroll' paginator rows={10}
      >
        <Column field='codCliente' {...columnProps('Codice')} />
        <Column field='ragSociale' {...columnProps('Nome')} />
        <Column field='environment.agencyName' {...columnProps('Agenzia')} />
        <Column field='createdAt' {...columnProps('Data creazione')} body={({ createdAt }) => new Date(createdAt).toLocaleDateString('it-IT')} />
        <Column style={{ border: 'none', width: 140, height: 15, textAlign: 'center' }} body={(props) => <BtnActions {...props} />} />
      </DataTable>
    </Flex>
  )
}
