import React, { useState, useEffect } from 'react'
import { Flex } from '../../Components/Common/Flex'
import { Text } from '../../Components/Common/Text'
import { Calendar } from 'primereact/calendar'
import { cap } from '../../State'
import { Button } from '../../Components/Common/Button'
import { Card } from '../../Components/Common/Card'
import { Input } from '../../Components/Common/Input'
import { Checkbox } from 'primereact/checkbox'
import { FC } from '../../Services/FeathersClient'
import { useAuth } from '../../Provider/AuthProvider'

const defaultDataRange = [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()]

const clientsOptions = [
  { label: 'Blueteam', value: 'getStatsBlueteamXlsx' },
  { label: 'BCD', value: 'getStatsBcdXlsx' },
  { label: 'Gattinoni', value: 'getStatsGattinoniXlsx' },
  { label: 'Misto', value: 'getStatsMixedXlsx' }
]

export const StatisticheAziende = () => {
  const [datesSearch, setDatesSearch] = cap.datesSearchStats.useState()
  const [dateRange, setDateRange] = useState(defaultDataRange)
  const [selectedClient, setSelectedClient] = useState()
  const [groupByDay, setGroupByDay] = useState(false)
  const { user: userObj = {} } = useAuth()
  const { user = {} } = userObj || {}
  const { email } = user || {}
  const [emailXls, setEmailXls] = useState(email || '')

  useEffect(() => {
    if (datesSearch?.every((d) => d instanceof Date)) setDateRange([datesSearch[0], datesSearch[1]])
  }, [datesSearch])

  useEffect(() => {
    if (selectedClient === 'getStatsMixedXlsx') setGroupByDay(false)
  }, [selectedClient])

  const downloadStats = async () => {
    window.growl.show({ severity: 'info', summary: 'Invio in corso', detail: 'Invio in corso' })
    const startDate = dateRange[0].getFullYear() + '-' + (dateRange[0].getMonth() + 1).toString().padStart(2, '0') + '-' + dateRange[0].getDate().toString().padStart(2, '0')
    const endDate = dateRange[1].getFullYear() + '-' + (dateRange[1].getMonth() + 1).toString().padStart(2, '0') + '-' + dateRange[1].getDate().toString().padStart(2, '0')
    const res = await FC.service('stats').get(selectedClient, { query: { action: 'sendAgencyReport', email: emailXls, groupByDay, startDate, endDate } })
    if (res.ok) window.growl.show({ severity: 'success', summary: 'Email inviata', detail: 'Email inviata' })
  }

  return (
    <Flex fw>
      <Text value='Statistiche Servizi' bold title size={20} />
      <Card style={{ width: '100%', marginTop: 20 }}>
        <Flex fw js row style={{ marginTop: 20 }} wrap>
          <Input
            dropdown options={clientsOptions} label='Cliente' style={{ width: 300 }}
            value={selectedClient} id='client' onChange={(e) => setSelectedClient(e.client)}
          />
          <Calendar
            value={datesSearch} selectionMode='range' onChange={(e) => setDatesSearch(e.value)}
            locale='it' dateFormat='dd/mm/yy' style={{ width: 250, marginLeft: 10 }}
          />
          <Text value='Raggruppa per giorno' style={{ marginLeft: 10 }} />
          <Checkbox checked={groupByDay} onChange={(e) => setGroupByDay(e.checked)} style={{ marginLeft: 10 }} disabled={selectedClient === 'getStatsMixedXlsx'} />
          <Input label='Email' style={{ marginLeft: 10, width: 350 }} value={emailXls} onChange={(e) => setEmailXls(e.email)} id='email' />
          <Button
            label='Invia Report Email' icon='fa-duotone fa-envelope' style={{ marginLeft: 10 }}
            onClick={downloadStats} disabled={!selectedClient || !emailXls?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)}
          />
        </Flex>
        {(selectedClient === 'getStatsBlueteamXlsx' || selectedClient === 'getStatsMixedXlsx') &&
          <Text value='Per avere i dati sulle anomalie di Blueteam, mandare ad Andrea il file di log di Edok' style={{ marginTop: 20, marginLeft: 12 }} />}
      </Card>
    </Flex>
  )
}
