import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { FC } from '../../Services'
// import { useAuth } from '../../Provider/AuthProvider'

import { TopMenu } from '../../Components/'
import { PageContainer } from '../../Components/Common/PageContainer'

function onBeforeUnload (e) {
  if (window?._env_?.ENVIRONMENT === 'development') return
  e.preventDefault()
  e.returnValue = ''
}

function ProtectedRoute ({ children }) {
  // const { onLogout } = useAuth()

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [])

  const location = useLocation()
  if (!FC.authenticated) return <Navigate to='/login' replace state={{ from: location }} />

  return (
    <>
      <TopMenu />
      <PageContainer>
        <Outlet />
      </PageContainer>
    </>
  )
}

export default ProtectedRoute
