import { getCorrectPhone } from '../GigiForm/Functions'
import { FC, invalidateQuery } from '../../../Services'

export const conversationsStatus = {
  open: 'In corso',
  customerCompleted: 'Conclusa per il cliente',
  ended: 'Concluso',
  error: 'Errore',
  archived: 'Archiviato'
}

export const STATE_ENUM = [
  { label: 'Creato', value: 1, code: 'INITIAL', color: 'blue' },
  { label: 'In esecuzione', value: 2, code: 'RUNNING', color: 'violet' },
  { label: 'Terminato', value: 3, code: 'ENDED', color: 'green' },
  { label: 'In pausa', value: 4, code: 'PAUSE', color: 'azure' },
  { label: 'Errore', value: 5, code: 'ERROR', color: 'red' },
  { label: 'In esecuzione', value: 7, code: 'READYTORESTART', color: 'orange' }
]

export const getStateValues = () => {
  const obj = {}
  STATE_ENUM.forEach((e) => { obj[e.code] = e.value })
  return obj
}

export const renderPhone = (row) => {
  const { traveller: { phone = '' } } = row || {}
  if (phone) {
    const formattedPhone = getCorrectPhone(phone)
    const prefix = formattedPhone.split('.')[0]
    const phoneNumber = formattedPhone.split('.')[1]
    return '+(' + prefix + ') ' + phoneNumber
  } else {
    return 'Non definito'
  }
}

export const formatDateHour = (createdAt) => {
  const isToday = createdAt.toDateString() === new Date().toDateString()
  const hour = createdAt.getHours() + ':' + ('0' + createdAt.getMinutes()).slice(-2)
  const itDays = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab']
  const itMonths = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']

  const dayName = itDays[createdAt.getDay()]
  const day = createdAt.getDate()
  const monthName = itMonths[createdAt.getMonth()]

  const formattedDate = dayName + ' ' + day + ' ' + monthName
  if (createdAt) return isToday ? hour : formattedDate + ' ' + hour
  return ''
}

/**
 * Archivia le conversazioni basate su un intervallo di date fornito.
 *
 * @param {Array} datesSearch - Un array con la data di inizio e fine per filtrare le conversazioni.
 *                              Se non viene fornito, non filtra per date.
 * @param {Function} setModalVisible - Funzione per impostare la visibilità del modale.
 * @param {Function} setDatesSearch - Funzione per impostare il nuovo intervallo di date.
 *
 * @async
 * @returns {Promise<void>} Restituisce una promessa che, quando risolta, indica che l'operazione
 *                          di archiviazione è stata completata (o segnala un errore).
 */
export const archiveConversations = async (datesSearch, setModalVisible, setDatesSearch) => {
  const [startDate, endDate] = datesSearch ?? []
  const query = {
    status: {
      $ne: 'archived'
    }
  }

  // Imposta la query per createdAt basata sulle date fornite.
  if (startDate && endDate) {
    // Range completo se entrambe le date sono fornite.
    query.createdAt = {
      $gte: new Date(startDate).setHours(0, 0, 0, 0),
      $lte: new Date(endDate).setHours(23, 59, 59, 999)
    }
  } else if (startDate) {
    // Solo startDate fornita, prendi conversazioni in quel giorno specifico.
    const startOfDay = new Date(startDate).setHours(0, 0, 0, 0)
    const endOfDay = new Date(startDate).setHours(23, 59, 59, 999)
    query.createdAt = {
      $gte: startOfDay,
      $lt: endOfDay
    }
  } else if (endDate) {
    // Solo endDate fornito, prendi conversazioni fino alla fine di quel giorno.
    query.createdAt = {
      $lte: new Date(endDate).setHours(23, 59, 59, 999)
    }
  } else {
    return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Inserisci il range di date' })
  }

  try {
    const conversationsToArchive = await FC.service('conversations').find({ query })

    if (!conversationsToArchive.length) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Nessuna attività trovata nel range di date' })
      return
    }
    const results = await Promise.all(
      conversationsToArchive.map(convo =>
        FC.service('conversations').patch(convo.id, { status: 'archived' })
      )
    )
    setDatesSearch(null)
    setModalVisible(false)
    invalidateQuery(['conversation', 'conversations'])

    const conversationCount = results.length
    const conversationWord = conversationCount > 1 ? 'conversazioni archiviate' : 'conversazione archiviata'
    const messageDetail = `${conversationCount} ${conversationWord}`
    window.growl.show({ severity: 'success', summary: 'Successo', detail: messageDetail })
  } catch (error) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: "Errore durante l'archiviazione delle conversazioni" })
  }
}

export const deleteConversation = async (conversationId) => {
  await FC.service('conversations').patch(conversationId, { deleted: true })
  window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Attività eliminata' })
  invalidateQuery(['conversations'])
}
