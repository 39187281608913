import { Button } from '../Common/Button'
import { Flex } from '../Common/Flex'
import { Text } from '../Common/Text'
import { Spinner } from '../Common/Spinner'
import { TextareaAuto } from '../Common/TextareaAuto'
import { useRef, useState } from 'react'
/* global FileReader */

const handleKeypress = (send) => (e) => {
  if (e.code === 'Enter' && !e.shiftKey) {
    e.preventDefault()
    send()
  }
}
/**
 * Componente Composer per la gestione dell'input di messaggi e allegati.
 *
 * @param {Object} props Oggetto che contiene le proprietà passate al componente.
 * @param {Function} props.update Funzione per aggiornare lo stato del messaggio corrente.
 * @param {Function} props.send Funzione per inviare il messaggio e gli allegati.
 * @param {string} props.currentMessage Messaggio attualmente digitato dall'utente.
 * @param {boolean} props.sending Indica se un messaggio è in fase di invio.
 * @param {Object} props.composerStyle Stili CSS aggiuntivi per il componente.
 * @returns {React.Component} Ritorna un componente React che rappresenta l'area di composizione dei messaggi.
 */
export const Composer = ({ update, send, currentMessage, sending, composerStyle, disabled }) => {
  const [files, setFiles] = useState([])
  const refBtn = useRef(null)

  const onSend = async () => {
    if (!currentMessage?.length && !files?.length) return // controllo che non sia vuoto il message, al fine di non fare invio inutilmente.
    update('')
    const attachments = await Promise.all(files.map(async (file) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      return new Promise((resolve) => {
        reader.onload = () => resolve(reader.result)
      })
    }))
    setFiles([])
    send(attachments)
  }
  return (
    <Flex fw>
      <Flex
        row fw
        style={{
          paddingRight: 40,
          ...composerStyle
        }}
      >
        <TextareaAuto
          placeholder='Scrivi un messaggio (Enter per inviare, Maiusc + Enter per andare a capo)'
          style={{ paddingTop: 12, minHeight: 40, borderRadius: 10, width: 'calc(100% - 88px)' }}
          value={currentMessage}
          autoFocus
          onChange={(e) => update(e.target.value)}
          onKeyPress={handleKeypress(disabled ? () => {} : onSend)}
        />
        <input type='file' id='file' name='file' onChange={(e) => setFiles([...files, ...e.target.files])} style={{ display: 'none' }} ref={refBtn} multiple />
        <Button icon='fa-duotone fa-paperclip' iconSize={30} onClick={() => refBtn.current.click()} style={{ marginLeft: 10, height: 40, width: 40, borderRadius: 22, paddingLeft: 4 }} />
        {sending
          ? <Flex style={{ marginLeft: 10, height: 40, width: 40 }}> <Spinner /></Flex>
          : <Button icon='fa-duotone fa-paper-plane-top' iconSize={30} onClick={onSend} style={{ marginLeft: 10, height: 40, width: 40, borderRadius: 22, paddingLeft: 4 }} disabled={disabled} />}
      </Flex>
      <Flex fw row wrap js>
        {files.map((file, index) => (
          <Flex key={file.name} row style={{ margin: 5 }}>
            <Text value={file.name} style={{ fontSize: 12, marginRight: 10 }} />
            <Button icon='fa-duotone fa-xmark' round onClick={() => setFiles(files.filter((_, i) => i !== index))} />
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}
