import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCQuery } from '../../../Services/QueryCache'
import { Button, Flex, PieChartComponent, Spinner, Text } from '../../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { formatEuro3Decimals } from '../../../Services/Functions'
import { Provider } from './Provider'

const columnStyle = { textAlign: 'center' }
const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF0000', '#0000FF', '#00FF00', '#FF00FF', '#FFFF00', '#00FFFF']
export const ConversationPricing = () => {
  const { conversationId } = useParams()
  const navigate = useNavigate()
  const { data, isSuccess } = useCQuery(['pricingPerConversation', conversationId])

  const [providers, setProviders] = useState([])
  const [totalCostPerProvider, setTotalCostPerProvider] = useState([])

  useEffect(() => {
    if (isSuccess && !providers?.length) {
      setProviders(data?.[0]?.tokenCount?.map(({ provider, company }) => ({ provider, disabled: false, company })))
      setTotalCostPerProvider(data?.[0]?.tokenCount?.map(tokenCount => ({ name: tokenCount.provider, value: tokenCount.providerTotal })))
    }
  }, [data])

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw js>
      <Text value='Pricing' center size={20} bold style={{ marginBottom: 20 }} />
      <Button icon='fa-duotone fa-arrow-left' label='Torna indietro' onClick={() => navigate('/conversations/' + conversationId)} style={{ marginBottom: 20 }} />
      <Flex as js fw>
        <Text value='Filtra provider' size={20} bold style={{ marginBottom: 10 }} />
        <Flex fw js row wrap>
          <Flex row as wrap style={{ gap: 10 }}>
            {[...new Set(data[0]?.tokenCount.map(({ company }) => company))].map((company, i) => (
              <Provider key={i} providers={[providers, setProviders]} company={company} />
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Flex fw as>
        <DataTable
          value={data} paginator rows={10} className='TableSignatures' style={{ maxWidth: '100%' }}
          responsiveLayout='scroll' resizableColumns columnResizeMode='expand' filterDisplay='row'
        >
          <Column field='unit' header='Unit' {...columnStyle} />
          <Column field='processId' header='Processo' sortable {...columnStyle} />
          <Column sortable field='total' header='Totale' body={(row) => formatEuro3Decimals(row?.total)} {...columnStyle} />
          {providers?.map((provider, i) => (!provider.disabled &&
            <Column
              field={'tokenCount.' + i + '.tokenCount'} header={'Token ' + provider.provider} key={i}
              sortable body={(row) => row?.tokenCount?.[i]?.tokenCount || ''} {...columnStyle}
            />))}
          {providers?.map((provider, i) => (!provider.disabled &&
            <Column
              sortable field={'tokenCount.' + i + '.providerTotal'} header={'Totale ' + provider.provider}
              key={i} body={(row) => formatEuro3Decimals(row?.tokenCount?.[i]?.providerTotal)} {...columnStyle}
            />))}
        </DataTable>
      </Flex>
      <Flex style={{ height: 400, width: 700 }}>
        <PieChartComponent
          legendProps={{ layout: 'vertical', align: 'left', verticalAlign: 'middle' }}
          legend data={totalCostPerProvider} dataKey='value'
          colors={colors} innerRadius='0%' outerRadius='100%' paddingAngle='0'
          tooltipProps={{
            content: ({ payload: [{ payload: { name = '', value } = {} } = {}] = [] }) => (
              <div>
                <Text value={name} />
                <Text value={`Totale: ${formatEuro3Decimals(value)}`} />
              </div>
            )
          }}
        />
      </Flex>
    </Flex>
  )
}
